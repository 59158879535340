import { useRedux } from "../../redux/shared/redux.provider";

function PanelContent(props: any) {
  const { variant, children, ...rest } = props;
  const { Rts } = useRedux();
  return (
    <Rts.Box
      css={{
        ms: "auto",
        me: "auto",
        ps: "15px",
        pe: "15px",
        mt: { sm: "130px", md: "90px" },
      }}
      {...rest}
    >
      {children}
    </Rts.Box>
  );
}

export default PanelContent;
