import { useState, useEffect } from "react";
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import { Provider } from "../components/ui/provider";
import routes from "../rts.routes";
import Sidebar from "../components/Sidebar/Sidebar";
import MainPanel from "../components/MainPanel/mainPanel";
import PanelContent from "../components/MainPanel/panelContent";
import PanelContainer from "../components/MainPanel/panelContainer";
import Navbar from "../components/NavBar/navbar";
import theme from "./theme/theme";
import interfaceObjectRoute from "../interfaces/layouts/interfaceObjectRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import objectRoute from "../interfaces/layouts/interfaceObjectRoute";
import Footer from "../components/Footer/Footer";

import Unauthorized from "../views/login.page";
import { useAppSelector, useRedux } from "../redux/shared/redux.provider";

const MainLayout = (props: any) => {
  const { getUser, setUser, LogOff, ...rest } = props;
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const location = useLocation();
  const user = useAppSelector((state) => state.users);
  const { open, onOpen, onClose } = useDisclosure();
  const [activeRoute, setActiveRoute] = useState("Dashboard");
  const { Rts } = useRedux();
  const getRoute = () => {
    return window.location.pathname !== "/full-screen-maps";
  };

  useEffect(() => {}, [location.pathname]);

  const getActiveRoute = (routes: any) => {
    let activeRoute = process.env.REACT_APP_DEFAULT_PATH;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute: any = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.pathname === routes[i].path) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar: any = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.pathname === routes[i].path) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes: any = (routes: interfaceObjectRoute[]) => {
    return routes?.map((prop: objectRoute, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "navbar") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            errorElement={<Unauthorized />}
            key={key}
          />
        );
      } else {
        return <></>;
      }
    });
  };

  return (
    <Provider>
        {!user.isAuth && <Unauthorized />}
        {user.isAuth && (
          <>
            <Sidebar
              routes={routes}
              logoText={process.env.REACT_APP_NAME}
              display="none"
              sidebarVariant={sidebarVariant}
              fixed={fixed}
              getUser={getUser}
              isAuth={user.isAuth}
              {...rest}
            />
            <MainPanel
              width={{
                base: "100%",
                xl: "calc(100% - 275px)",
              }}
            >
              <Portal>
                <Navbar
                  onOpen={onOpen}
                  logoText={process.env.REACT_APP_NAME}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  fixed={fixed}
                  getUser={getUser}
                  isAuth={user.isAuth}
                  LogOff={LogOff}
                  {...rest}
                />
              </Portal>
              {getRoute() ? (
                <PanelContent>
                  <PanelContainer>
                    <Routes>{getRoutes(routes)}</Routes>
                  </PanelContainer>
                </PanelContent>
              ) : null}
              <Footer />
            </MainPanel>
          </>
        )}
    </Provider>
  );
};

export default MainLayout;
