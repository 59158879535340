import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraReducer,
  IntialReducer,
} from "./logistics_packages.reducers";
import { FetchData } from "./logistics_packages.fetcher";

const initialState = IntialReducer;

const logisticsPackagesSlice = createSlice({
  name: "serviceRequest",
  initialState,
  reducers: {
    
  },
  extraReducers: ExtraReducer,
});

export default logisticsPackagesSlice.reducer;

export const reducers = logisticsPackagesSlice.actions;

export const FetchLogisticsPackages = FetchData;
