import MainLayout from "./layouts/layout.main";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster, toaster } from "./components/ui/toaster";
import { useRedux } from "./redux/shared/redux.provider";
import { IReducers } from "./redux/shared/redux.iterface";
import IframeLayout from "./layouts/layout.iframe";

const Core = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { redux, Rts, isConnected, stateMsg } = useRedux();
  const { isError, message, IReducer, dispatch, store, isIframe } = redux;

  useEffect(() => {
    if (redux.store.shared.countries.length === 0)
      dispatch(
        IReducer.IShared.ISignalR({
          type: IReducer.IShared.ISignalRType.SharedGetCountries,
        })
      );

    if (redux.store.shared.vendors.length === 0)
      dispatch(
        IReducer.IShared.ISignalR({
          type: IReducer.IShared.ISignalRType.SharedGetVendors,
        })
      );
  }, [isConnected]);

  useEffect(() => {
    let data = store.core.messages;
    if (data.length > 0) {
      let item = data[0];
      toaster.create({
        title: item.title,
        description: item.message,
        type: item.serverity,
        duration: item.duration,
      });
      dispatch(IReducer.ICore.IData.consumeMessage(item));
    }
  }, [store.core.messages]);

  useEffect(() => {
    if (location.pathname === "/serviceRequest")
      dispatch(IReducer.ICore.IData.setIframe(true));
    else dispatch(IReducer.ICore.IData.setIframe(false));
  }, [location.pathname]);

  useEffect(() => {
    if (isError) {
      toaster.create({
        title: "RTS chyba",
        description: message,
        type: "error",
        duration: 5000,
      });
      dispatch(IReducer.ICore.IData.setError(""));
    }
  }, [isError]);

  useEffect(() => {
    if (store.users.isAuth)
      if (location.pathname.indexOf("auth2") > -1)
        navigate(localStorage.getItem("lastPath")!);
  }, [store.users.isAuth]);

  useEffect(() => {
    if (
      location.pathname !== "/serviceRequest" &&
      store.users.logged === null &&
      localStorage.getItem("accessToken") !== null &&
      !isIframe
    ) {
      dispatch(
        IReducers.IUser.IFetch({
          type: IReducers.IUser.IFetchTypes.GET_USER_DETAIL,
          data: { accessToken: localStorage.getItem("accessToken")! },
        })
      );
    }
  }, [store.users.isAuth]);

  useEffect(() => {
    dispatch(
      IReducer.ICore.IData.setStateWithText({
        value: true,
        text: "Čekám na odezvu aplikačního serveru",
      }),
      true
    );
  }, []);

  const handleConnecting = () => {
    return (
      <Rts.DialogRoot open={!isConnected} placement={"center"} size={"xl"}>
        <Rts.DialogContent>
          <Rts.DialogBody>
            <Rts.Flex direction={"column"} p={"100px"} gap={4} align={"center"}>
              <Rts.Spinner />
              <Rts.Text>{stateMsg}</Rts.Text>
            </Rts.Flex>
          </Rts.DialogBody>
        </Rts.DialogContent>
      </Rts.DialogRoot>
    );
  };

  return (
    <>
      {isConnected && (
        <>
          {isIframe && <IframeLayout />}
          {!isIframe && (
            <>
              <Toaster />
              <MainLayout />{" "}
            </>
          )}
        </>
      )}
      {!isConnected && handleConnecting()}
    </>
  );
};

export default Core;
