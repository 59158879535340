import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IUser } from "./users.interface";
import { fetchUser } from "./users.slice";
import { responseModel } from "../../shared/redux.types";
import { User } from "./users.types";
import { FetchTypes } from "./users.enums";

const _storageToken = process.env.REACT_APP_STORAGE_TOKEN;


export const initState: IUser = {
  isAuth: false,
  isLoading: false,
  response: [],
  logged: null,
  statusCode: 0,
  message: "",
} as IUser;

export const IntialReducer = () => {
  var stored = localStorage.getItem(_storageToken!);
  if (stored === null) return initState;

  const state = {
    isAuth: false,
    isLoading: false,
    response: JSON.parse(stored),
    logged: null,
    statusCode: 0,
    message: "",
  };
  return state;
};

export const InitReducer = (state: IUser, action: any) => {
  state.statusCode = action.payload.data.statusCode;
  state.message = action.payload.data.message;
  state.response = action.payload.data.response;
  if (state.response.length === 1) {
    state.logged = state.response[0];
    state.isAuth = true;
    localStorage.setItem(
      _storageToken!,
      JSON.stringify(action.payload.data.response)
    );
    localStorage.setItem("accessToken", state.logged.access_token ?? "");
  }
  return state;
};

export const fakeLogin = (state: IUser, action: { payload: User }) => {
  state.logged = action.payload;
  state.isAuth = true;
  state.response = [state.logged];
  localStorage.setItem("accessToken", state.logged.access_token ?? "");
  return state;
};

export const SelectProfile = (
  state: IUser,
  action: { payload: { idx: number } }
) => {
  state.logged = state.response[action.payload.idx];
  state.isAuth = true;
  state.response = [state.logged];
  localStorage.setItem("accessToken", state.logged.access_token ?? "");
  return state;
};

export const LogOut = (state: IUser, action: { payload: any }) => {
  state.isAuth = false;
  state.logged = null;
  state.response = [];
  localStorage.removeItem("accessToken");
  return state;
};

export const ExtraReducer = (builder: ActionReducerMapBuilder<IUser>) => {
  builder.addCase(fetchUser.pending, (state: IUser, action) => {
    state = IntialReducer();
  });
  builder.addCase(fetchUser.fulfilled, (state: IUser, action: any) => {
    var currState: responseModel<User[]> = action.payload;
    state.message = currState.message;
    state.response = currState.response;
    state.statusCode = currState.statusCode;
    if (state.response.length === 1) {
      state.logged = state.response[0];
      state.isAuth = true;

      localStorage.setItem(
        _storageToken!,
        JSON.stringify(action.payload.response)
      );
      localStorage.setItem("accessToken", state.logged.access_token ?? "");
    }
  });
  builder.addCase(fetchUser.rejected, (state: IUser, action) => {
    state = IntialReducer();
    if (action.error.code === "401") {
      localStorage.removeItem(_storageToken!);
      localStorage.removeItem("accessToken");
    }
  });
};
