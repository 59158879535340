import { useRedux } from "../../redux/shared/redux.provider";
function PanelContainer(props: any) {
  const { variant, children, ...rest } = props;
  const { Rts } = useRedux();
  return (
    <Rts.Box
      css={{ p: "30px 15px", minHeight: "calc(100vh - 123px)" }}
      {...rest}
    >
      {children}
    </Rts.Box>
  );
}

export default PanelContainer;
