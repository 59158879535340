import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IState } from "./state.interface";
import { fetchState } from "./state.slice";
import { FetchTypes } from "./state.enums";

export const initState: IState = {} as IState;

export const IntialReducer = () => {
  return {
    status: "n/a",
    totalDuration: "n/a",
    entries: [],
  } as IState;
};

export const InitReducer = (state: any, action: any) => {
  state.logs = action.payload.data.response;
  return state;
};

export const ExtraReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(fetchState.pending, (state: IState, action) => {
    state = IntialReducer();
  });

  builder.addCase (
    fetchState.fulfilled,
    (state: IState, action: any ) => {
      var payload: IState = action.payload;
      if (action.meta.arg.type === FetchTypes.GET_STATE) {
        state.status = action.payload.status;
        state.totalDuration = action.payload.totalDuration;
        state.entries = action.payload.entries;
      }
    }
  );
  builder.addCase(fetchState.rejected, (state: IState, action) => {
    state = IntialReducer();
  });
};
