import { useColorMode } from "../../../components/ui/color-mode";
import IButton, { IButtonProps } from "./button";
import { FaMoon, FaSun } from "react-icons/fa";

export default function SButtonColorMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  let props: IButtonProps = {};
  props.icon = colorMode === "dark" ? <FaSun /> : <FaMoon />;
  props.onClick = toggleColorMode;
  props["aria-label"] = colorMode === "dark" ? "Světlý motiv" : "Tmavý motiv";
  return <IButton {...props} />;
}
