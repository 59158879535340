import { useEffect } from "react";
import { useRedux } from "../../../redux/shared/redux.provider";
import {
  DeliveryAddress,
  DeliveryContact,
  InvoiceAddress,
  InvoiceICO,
  InvoiceName,
  PickupAddress,
  PickupContact,
} from "./form-controll";

export default function Summary() {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer } = redux;
  const data = store.service_request;
  useEffect(() => {}, [data]);
  return (
    <>
      <Rts.SimpleGrid columns={{ base: 1, md: 4 }} gap={6}>
        <Rts.Card.Root>
          <Rts.Card.Header fontSize={"sm"} fontWeight={"bold"}>
            Zákazník
          </Rts.Card.Header>
          <Rts.Card.Body fontSize={"sm"} mt={-6}>
            <Rts.Flex direction={"column"}>
              <Rts.Flex direction={"row"} align={"center"} gap={6}>
                {data.customer.first_name} {data.customer.last_name}
              </Rts.Flex>
              <Rts.Text>
                {data.customer.street}, {data.customer.city},{" "}
                {data.customer.postal_code}, {data.customer.country}
              </Rts.Text>
              <Rts.Text>{data.customer.email}</Rts.Text>
              <Rts.Text>{data.customer.phone}</Rts.Text>
            </Rts.Flex>
          </Rts.Card.Body>
        </Rts.Card.Root>
        <Rts.Card.Root>
          <Rts.Card.Header fontSize={"sm"} fontWeight={"bold"}>
            Fakturace
          </Rts.Card.Header>
          <Rts.Card.Body fontSize={"sm"} mt={-6}>
            <Rts.Flex direction={"column"}>
              <Rts.Flex direction={"row"} align={"center"} gap={6}>
                {InvoiceName()}
              </Rts.Flex>
              <Rts.Text>{InvoiceAddress()}</Rts.Text>
              <Rts.Text>{InvoiceICO()}</Rts.Text>
              <Rts.Text>{data.invoice_address?.email}</Rts.Text>
              <Rts.Text>{data.invoice_address?.phone}</Rts.Text>
            </Rts.Flex>
          </Rts.Card.Body>
        </Rts.Card.Root>
        <Rts.Card.Root>
          <Rts.Card.Header fontSize={"sm"} fontWeight={"bold"}>
            Svozová adresa
          </Rts.Card.Header>
          <Rts.Card.Body fontSize={"sm"} mt={-6}>
            <Rts.Flex direction={"column"}>
              <Rts.Flex direction={"row"} align={"center"} gap={6}>
                {PickupContact()}
              </Rts.Flex>
              <Rts.Text>{PickupAddress()}</Rts.Text>
            </Rts.Flex>
          </Rts.Card.Body>
        </Rts.Card.Root>
        <Rts.Card.Root>
          <Rts.Card.Header fontSize={"sm"} fontWeight={"bold"}>
            Adresa doručení
          </Rts.Card.Header>
          <Rts.Card.Body fontSize={"sm"} mt={-6}>
            <Rts.Flex direction={"column"}>
              <Rts.Flex direction={"row"} align={"center"} gap={6}>
                {DeliveryContact()}
              </Rts.Flex>
              <Rts.Text>{DeliveryAddress()}</Rts.Text>
            </Rts.Flex>
          </Rts.Card.Body>
        </Rts.Card.Root>
        {data.devices.map((device, index) => (
          <Rts.Card.Root>
            <Rts.Card.Header fontSize={"sm"} fontWeight={"bold"}>
              Zařízení č.{index + 1}
            </Rts.Card.Header>
            <Rts.Card.Body fontSize={"sm"} mt={-6}>
              <Rts.Flex direction={"column"}>
                <Rts.Flex direction={"row"} align={"center"} gap={6}>
                  {
                    store.shared.vendors.filter(
                      (x) => x.value === device.vendor
                    )[0].label
                  }{" "}
                  - {device.model}
                </Rts.Flex>
                <Rts.Text>{device.imei}</Rts.Text>
                <Rts.Text>{device.num_at_card}</Rts.Text>
                <Rts.Text>
                  {device.is_warranty
                    ? "Záruční"
                    : `Nezáruční (Limit: ${device.estimated_repair_cost},- s DPH)`}
                </Rts.Text>
                <Rts.Text>{device.is_backup ? "ZÁLOHOVAT!!!" : ""}</Rts.Text>
              </Rts.Flex>
            </Rts.Card.Body>
          </Rts.Card.Root>
        ))}
      </Rts.SimpleGrid>
      <Rts.Flex direction={"row"} gap={6}>
        <Rts.Checkbox
          onCheckedChange={(e) =>
            dispatch(IReducer.IServiceRequest.IData.setAllOk(!!e.checked))
          }
          checked={data.all_is_ok}
        />
        <Rts.Text fontSize={"xs"}>Potvrzuji správnost zadaných údajů</Rts.Text>
      </Rts.Flex>
    </>
  );
}
