import { TextareaProps } from "@chakra-ui/react";
import { useRedux } from "../redux.provider";

interface ITextareaProps extends TextareaProps {
  isCopyBtn?: boolean;
}

export default function ITextarea(props: ITextareaProps) {
  const { Rts, redux } = useRedux();
  const { dispatch, IReducer } = redux;

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(props.children as string);
    dispatch(
      IReducer.ICore.IData.newMessage({
        message: "Text byl zkopírován do schránky",
        serverity: "success",
        duration: 5000,
      })
    );
  };

  return (
    <>
      {props.isCopyBtn && (
        <Rts.Button
          aria-label="Kopírovat do schránky"
          position={"absolute"}
          mt={"-30px"}
          right={"20px"}
          onClick={CopyToClipboard}
          size="xs"
          variant="outline"
          colorScheme="blue"
          ml={2}
        >
          <Rts.Icons.FaClipboard />
        </Rts.Button>
      )}
      <Rts.Textarea {...props} borderRadius={"15px"}>
        {props.children}
      </Rts.Textarea>
    </>
  );
}
