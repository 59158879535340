import { defineSlotRecipe, RecipeVariantProps } from "@chakra-ui/react";
import { cardAnatomy } from "@chakra-ui/react/anatomy";

export const cardSlotRecipe = defineSlotRecipe({
  className: "chakra-card",
  slots: cardAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    },
  },
  variants: {
    variant: {
      hovered: {
        root: {
          _hover: {
            border: "1px solid gray.100",
          },
        },
      },
    },
  },
});

type CardVariantProps = RecipeVariantProps<typeof cardSlotRecipe>;
export interface CardProps
  extends React.PropsWithChildren<CardVariantProps> {}
