import { useRedux } from "../../redux/shared/redux.provider";
import { useState } from "react";

export default function NavbarAlerts(props: any) {
  const { Rts, redux } = useRedux();
  const { useColorModeValue } = Rts;
  const { secondary } = props;
  const [data, setData] = useState([
    {
      aName: "TEST",
      aSrc: null,
      boldInfo: "TEST",
      info: "Testovací zpráva bkjdsgauhdi ab husahd isahjdlihsaldhsalkhd",
      time: new Date().toLocaleDateString(),
    },
  ]);
  const navbarIconItem = useColorModeValue("gray.500", "gray.500");
  const navbarIconItemText = useColorModeValue("gray.400", "gray.400");
  const notificationColor = useColorModeValue("gray.700", "white");
  let navbarIconColor = useColorModeValue("gray.700", "gray.200");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const menuItem = (props: any) => {
    return (
      <>
        <Rts.Avatar
          name={props.aName}
          src={props.aSrc}
          borderRadius="12px"
          me="16px"
        />
        <Rts.Flex flexDirection="column" maxW={{ sm: "250px", md: "250px" }}>
          <Rts.Text fontSize="14px" mb="5px" color={notificationColor}>
            <Rts.Flex direction={"row"} justify={"space-between"}>
              <Rts.Text fontWeight="bold" fontSize="14px" as="span">
                {props.boldInfo}
              </Rts.Text>
              <Rts.Flex alignItems="center" color={navbarIconItem}>
                <Rts.Icons.FaClock
                  style={{ width: "13px", height: "13px", marginRight: "10px" }}
                />
                <Rts.Text fontSize="xs" lineHeight="100%">
                  {props.time}
                </Rts.Text>
              </Rts.Flex>
            </Rts.Flex>
          </Rts.Text>
          <Rts.Text
            fontWeight="regular"
            color={navbarIconItemText}
            fontSize="14px"
            as="span"
          >
            {props.info}
          </Rts.Text>
        </Rts.Flex>
      </>
    );
  };

  return (
    <Rts.MenuRoot>
      <Rts.MenuTrigger>
        <Rts.Button variant="ghost" size="sm">
          <Rts.Icons.FaBell />
          {data.length > 0 && (
            <Rts.Float>
              <Rts.Status value="error" />
            </Rts.Float>
          )}
        </Rts.Button>
      </Rts.MenuTrigger>
      <Rts.MenuContent>
        <Rts.Flex flexDirection="column">
          {data.map((prop, idx) => (
            <Rts.MenuItem value={`NbArt-${idx}`}>{menuItem(prop)}</Rts.MenuItem>
          ))}
        </Rts.Flex>
      </Rts.MenuContent>
    </Rts.MenuRoot>
  );
}
