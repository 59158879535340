import { EndpointMethod } from "../../redux.enums";
import { endpoint } from "../../redux.types";

export const CONFIG = {
  STATE: () => {
    return {
      method: EndpointMethod.GET,
      ep: `/api/state`,
    } as endpoint;
  },
};