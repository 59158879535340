import { defineSlotRecipe, RecipeVariantProps } from "@chakra-ui/react";
import {  alertAnatomy } from "@chakra-ui/react/anatomy";

export const alertSlotRecipe = defineSlotRecipe({
  className: "chakra-alert",
  slots: alertAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    }
  },
});

type AlertVariantProps = RecipeVariantProps<typeof alertSlotRecipe>;
export interface CardProps
  extends React.PropsWithChildren<AlertVariantProps> {}
