import { useRedux } from "../redux.provider";
import { Card } from "@chakra-ui/react";

interface ICardProps extends Card.RootProps {
  isHover?: boolean;
  isIn?: boolean;
  isBorder?: boolean;
  isGrab?: boolean;
  bgState?: string;
  label?: string;
  placement?:
    | "start-start"
    | "start-end"
    | "end-start"
    | "end-end"
    | "start"
    | "end"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "right";
}

export default function SCard(props: ICardProps) {
  const { Rts } = useRedux();
  var { isHover, isIn, isBorder, isGrab, bgState } = props;
  if (isHover === undefined) isHover = false;
  if (isIn === undefined) isIn = false;
  if (isBorder === undefined) isBorder = false;
  if (isGrab === undefined) isGrab = false;
  const borderHover = Rts.useColorModeValue("blue.500", "blue.300");
  let bgCard = Rts.useColorModeValue("rgba(0, 0, 0, 0.01)", "rgba(0, 0, 0, 0.19)");
  let cardBgColorHover = Rts.useColorModeValue("gray.200", "gray.800");
  const cardBgColorSelected = Rts.useColorModeValue("white", "gray.600");
  const cardBorderColorSelected = Rts.useColorModeValue(
    "1px solid var(--chakra-colors-blue-500)",
    "1px solid var(--chakra-colors-blue-200)"
  );
  let cardBorderHover = `1px solid var(--chakra-colors-${borderHover.replace(
    ".",
    "-"
  )})`;
  let cardBorderUnsselect = `1px solid ${bgCard}`;
  let cardBorderUnsselectNotIn = `1px solid ${bgCard}`;
  let cardBorderUnsselectIn = Rts.useColorModeValue(
    "0px solid white",
    "0px solid var(--chakra-colors-gray-700)"
  );
  let cursor = "pointer";
  bgCard = bgState === undefined ? bgCard : bgState;
  if (!isHover) {
    cursor = "default";
    cardBgColorHover = bgCard;
    cardBorderHover = cardBorderUnsselectNotIn;
  }

  if (!isBorder) {
    if (isIn) {
      cardBorderUnsselect = cardBorderUnsselectIn;
    } else {
      cardBorderUnsselect = cardBorderUnsselectNotIn;
    }
  }

  if (isGrab) {
    cursor = "grab";
  }

  if (isIn) {
    bgCard = cardBgColorSelected;
    cardBgColorHover = bgCard;
  }

  return (
    <Rts.Tooltip content={props.label}>
      <Rts.Card.Root
        key={0}
        {...props}
        _hover={{
          cursor: cursor,
          bg: bgCard,
          border: cardBorderHover,
        }}
        border={cardBorderUnsselect}
      />
    </Rts.Tooltip>
  );
}
