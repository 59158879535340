import { useEffect } from "react";
import { useRedux } from "../../redux/shared/redux.provider";
import { CopyBlock, tomorrowNight } from "react-code-blocks";

export default function Config() {
  const { redux } = useRedux();
  const { store, dispatch, IReducer } = redux;
  useEffect(() => {
    dispatch(
      IReducer.IConfig.IFetch({ type: IReducer.IConfig.IFetchTypes.FULLCONFIG })
    );
  }, []);
  return (
    <CopyBlock
      customStyle={{ borderRadius: "15px" }}
      text={JSON.stringify(store.config.config ?? "", null, 2)}
      showLineNumbers={false}
      theme={tomorrowNight}
      wrapLongLines
      language="json"
      codeBlock
    ></CopyBlock>
  );
}
