import { AddressTypes } from "src/redux/features/createservicerequest/servicerequest.enums";
import { CustomerAddress } from "src/redux/features/createservicerequest/servicerequest.types";
import { useRedux } from "src/redux/shared/redux.provider";
export default function PosamSk() {
  const { redux } = useRedux();
  const { dispatch, store, IReducer } = redux;
  if (
    (store.service_request.customer.email ?? "")
      .toLocaleLowerCase()
      .indexOf("@posam.sk") > -1 &&
    store.service_request.invoice_address === null
  ) {
    const new_address = {
      type: AddressTypes.INVOICE,
      first_name: "PosAm, spol. s r.o.",
      last_name: "",
      email: store.service_request.customer.email,
      phone: "+421 249 239 111",
      street: "Pribinova 40",
      city: "Bratislava",
      postal_code: "811 09",
      country: "SK",
      company_id: "31365078",
    } as CustomerAddress;

    dispatch(
      IReducer.IShared.IFetch({
        type: IReducer.IShared.IFetchTypes.GET_SK_RUZ,
        data: { ico: "31365078" },
      }),
      true
    );

    dispatch(
      IReducer.IServiceRequest.IData.saveCustomer({
        ...store.service_request.customer,
        ...new_address,
        last_name: ".",
        type: AddressTypes.CUSTOMER,
      })
    );

    dispatch(IReducer.IServiceRequest.IData.setInvoiceType("2"));

    dispatch(
      IReducer.IServiceRequest.IData.saveAddress({
        ...new_address,
      })
    );
    dispatch(IReducer.IServiceRequest.IData.nextStep());
  }

  return null;
}
