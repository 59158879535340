import { useEffect } from "react";
import { useRedux } from "../../../redux/shared/redux.provider";
import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import { CustomerAddress } from "../../../redux/features/createservicerequest/servicerequest.types";
import {
  Card_type,
  DeliveryAddress,
  InvoiceAddress,
  PickupAddress,
} from "./form-controll";

export default function Transport() {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer, isLoading } = redux;
  const new_address = {
    type: AddressTypes.PICKUP,
    first_name: "",
    last_name: "",
    phone: "",
    street: "",
    city: "",
    postal_code: "",
    country: "",
    company_id: "",
  } as CustomerAddress;

  const data = store.service_request;

  const pickupDates = () => {
    //Načteme si z API první svozový den
    let date = new Date(store.shared.first_pickup_date??new Date());
    let min = date.setDate(date.getDate());
    let max = date.setDate(date.getDate() + 9);
    return { min, max };
  };

  useEffect(() => {
    if (data.pickup_date === "")
      dispatch(
        IReducer.IServiceRequest.IData.setPickupDate(
          new Date(pickupDates().min).toISOString().split("T")[0]
        )
      );
  }, []);

  useEffect(() => {
    if (data.pickup_date === "") return;
    dispatch(
      IReducer.IShared.IFetch({
        type: IReducer.IShared.IFetchTypes.GET_IS_PICKUP_DATE,
        data: {
          date: data.pickup_date,
          countryCode: "CZ", //PickupCountry(),
        },
      })
    );
  }, [data.pickup_date]);

  return (
    <Rts.Flex direction={"column"} gap={4}>
      <Rts.Flex direction={"row"} gap={4}>
        <Rts.Field label="Způsob dopravy do servisu" helperText="Vyberte jednu z možností" required>
          <Rts.RadioCardRoot
            w={"100%"}
            mt={4}
            gap={2}
            value={data.pickup_type}
            onChangeCapture={(e: any) => {
              dispatch(
                IReducer.IServiceRequest.IData.setPickupType(e.target.value)
              );
            }}
          >
            <Rts.Flex
              direction={{ base: "column", md: "row" }}
              w="100%"
              gap={2}
            >
              <Card_type
                label={"Přinesu osobně"}
                descriptionTop="Milady Horákové 125, Kladno, 272 01, CZ"
                value={"1"}
                isPrice
                curr={"CZK"}
                price={0.0}
              />
              <Card_type
                label={"Svoz na adresu"}
                descriptionTop={`${data.customer.street}, ${data.customer.city}, ${data.customer.postal_code}, ${data.customer.country}`}
                value={"2"}
                isPrice
                curr={"CZK"}
                price={0.0}
              />
              <Card_type
                label={"Svoz na fakturační adresu"}
                descriptionTop={`${data.invoice_address?.street}, ${data.invoice_address?.city}, ${data.invoice_address?.postal_code}, ${data.invoice_address?.country}`}
                value={"3"}
                isPrice
                curr={"CZK"}
                price={0.0}
                display={data.invoice_type === "1" ? "none" : "flex"}
              />
              <Card_type
                label={"Svoz na jinou adresu"}
                descriptionTop={
                  data.pickup_address?.first_name ? (
                    `${data.pickup_address?.street}, ${data.pickup_address?.city}, ${data.pickup_address?.postal_code}, ${data.pickup_address?.country}`
                  ) : (
                    <Rts.Flex>
                      <Rts.Alert
                        title={"Adresa není doplněna"}
                        status="warning"
                        icon={<Rts.Icons.LuAlertTriangle />}
                      >
                        {" "}
                        Pro doplnění stiskni Plus v pravém rohu tohoto boxu.
                      </Rts.Alert>
                    </Rts.Flex>
                  )
                }
                descriptionBottom={
                  <Rts.Flex
                    position={"absolute"}
                    right={3}
                    bottom={3}
                    fontSize={"xx-large"}
                  >
                    <Rts.Button
                      variant={"ghost"}
                      disabled={data.pickup_type !== "4"}
                      onClick={() =>
                        dispatch(
                          IReducer.IServiceRequest.IData.setAddressDialog({
                            open: true,
                            isPickup: true,
                          })
                        )
                      }
                    >
                      {!data.pickup_address?.first_name ? (
                        <Rts.Icons.LuPlus />
                      ) : (
                        <Rts.Icons.LuPenLine />
                      )}
                    </Rts.Button>
                  </Rts.Flex>
                }
                value={"4"}
                isPrice
                curr={"CZK"}
                price={0.0}
              />
            </Rts.Flex>
          </Rts.RadioCardRoot>
        </Rts.Field>
      </Rts.Flex>
      {(data.pickup_type??"1") !== "1" && (
        <>
          <Rts.Flex direction={"row"} gap={4} w={{ sm: "100%", md: "30%" }}>
            <Rts.Field
              label="Datum svozu"
              helperText="Pokud zadáte den, který není svozový, bude objednávka automaticky přesunuta na první svozový den."
              errorText={
                "Tento den není svozový vyberte prosím jiný datum svozu"
              }
              invalid={!store.shared.is_valid_pickup_date}
            >
              <Rts.Input
                type="date"
                placeholder="Datum svozu"
                autoFocus
                disabled={isLoading}
                defaultValue={
                  new Date(pickupDates().min).toISOString().split("T")[0]
                }
                min={new Date(pickupDates().min).toISOString().split("T")[0]}
                max={new Date(pickupDates().max).toISOString().split("T")[0]}
                value={data.pickup_date}
                onChange={(e) => {
                  dispatch(
                    IReducer.IServiceRequest.IData.setPickupDate(e.target.value)
                  );
                }}
              />
            </Rts.Field>
          </Rts.Flex>
        </>
      )}
      <Rts.Flex direction={"row"} gap={4}>
        <Rts.Field label="Způsob dopravy ze servisu" helperText="Vyberte jednu z možností" required>
          <Rts.RadioCardRoot
            w={"100%"}
            mt={4}
            gap={2}
            value={data.delivery_type}
            onChangeCapture={(e: any) => {
                dispatch(
                IReducer.IServiceRequest.IData.setDeliveryType(e.target.value)
              );
            }}
          >
            <Rts.Flex
              direction={{ base: "column", md: "row" }}
              w="100%"
              gap={2}
            >
              <Card_type
                label={"Vyzvednu osobně"}
                descriptionTop="Milady Horákové 125, Kladno, 272 01, CZ"
                value={"1"}
                isPrice
                price={0.0}
                curr="CZK"
              />
              <Card_type
                label={"Přepravcem na adresu"}
                descriptionTop={`${data.customer.street}, ${data.customer.city}, ${data.customer.postal_code}, ${data.customer.country}`}
                value={"2"}
                isPrice
                price={0.0}
                curr="CZK"
              />
              <Card_type
                display={data.invoice_type === "1" ? "none" : "flex"}
                label={"Přepravcem na fakturační adresu"}
                descriptionTop={`${InvoiceAddress()}`}
                value={"3"}
                isPrice
                price={0.0}
                curr="CZK"
              />
              <Card_type
                display={data.pickup_type !== "4" ? "none" : "flex"}
                label={"Přepravcem na adresu svozu"}
                descriptionTop={`${PickupAddress()}`}
                value={"4"}
                isPrice
                price={0.0}
                curr="CZK"
              />
              <Card_type
                label={"Přepravcem na jinou adresu"}
                descriptionTop={
                  data.delivery_address?.first_name ? (
                    `${data.delivery_address?.street}, ${data.delivery_address?.city}, ${data.delivery_address?.postal_code}, ${data.delivery_address?.country}`
                  ) : (
                    <Rts.Flex>
                      <Rts.Alert
                        title={"Adresa není doplněna"}
                        status="warning"
                        icon={<Rts.Icons.LuAlertTriangle />}
                      >
                        {" "}
                        Pro doplnění stiskni Plus v pravém rohu tohoto boxu.
                      </Rts.Alert>
                    </Rts.Flex>
                  )
                }
                descriptionBottom={
                  <Rts.Flex
                    position={"absolute"}
                    right={3}
                    bottom={3}
                    fontSize={"xx-large"}
                  >
                    <Rts.Button
                      variant={"ghost"}
                      disabled={data.delivery_type !== "5"}
                      onClick={() =>
                        dispatch(
                          IReducer.IServiceRequest.IData.setAddressDialog({
                            open: true,
                            isPickup: false,
                          })
                        )
                      }
                    >
                      {!data.delivery_address?.first_name ? (
                        <Rts.Icons.LuPlus />
                      ) : (
                        <Rts.Icons.LuPenLine />
                      )}
                    </Rts.Button>
                  </Rts.Flex>
                }
                isPrice
                price={0.0}
                curr="CZK"
                value={"5"}
              />
            </Rts.Flex>
          </Rts.RadioCardRoot>
        </Rts.Field>
      </Rts.Flex>
    </Rts.Flex>
  );
}
