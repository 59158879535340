import { StateReducers, fetchState } from "./state.slice";
import { FetchTypes } from "./state.enums";

export interface IState {
  status: string | null;
  totalDuration: string | null;
  entries: IEntries[];
}

interface IEntry {
  data: any;
  duration: string | null;
  status: string | null;
  tags: string[] | null;
  description?: string | null;
}

interface IEntries {
  [key: string]: IEntry;
}

export const IStateReducers = StateReducers;
export const IEStatefetchType = FetchTypes;
export const IFetchState = fetchState;
