import { Shared } from '../features/shared/shared.types';
import {
  ICoreReducers,
  ISignalR as CoreISignalR,
  ISignalRTypes,
  ISignalR
} from "../features/core/core.interface";
import {
  IEStatefetchType,
  IFetchState,
  IStateReducers,
} from "../features/state/state.interface";
import {
  IUsersReducers,
  IEUsersfetchType,
  IFetchUser,
} from "../features/users/users.interface";
import {
  IServiceRequestFetch,
  IEServiceRequestfetchType,
  IServiceRequestReducers,
  SRSignalR,
  SRSignalRType,
} from "../features/createservicerequest/servicerequest.interface";
import {
  ILogisticsPackagesFetch,
  IELogisticsPackagesfetchType,
  ILogisticsPackagesReducers,
} from "../features/logistics_packages/logistics_packages.interface";
import {
  IConfigReducers,
  IConfigFetch,
  IEFetchType as IConfigFetchType,
} from "../features/config/config.interface";
import {
  IESharedfetchType,
  IFetchShared,
  ISharedReducers,
  ISignalR as SharedISignalR,
  ISignalRType
} from "../features/shared/shared.interface";

export const IReducers = {
  ICore: {
    IData: ICoreReducers,
    ISignalR: CoreISignalR,
    ISignalRType: ISignalRTypes
  },
  IShared: {
    IData: ISharedReducers,
    IFetch: IFetchShared,
    IFetchTypes: IESharedfetchType,
    ISignalR: SharedISignalR,
    ISignalRType: ISignalRType,
  },
  IUser: {
    IData: IUsersReducers,
    IFetch: IFetchUser,
    IFetchTypes: IEUsersfetchType,
  },
  IState: {
    IData: IStateReducers,
    IFetch: IFetchState,
    IFetchTypes: IEStatefetchType,
  },
  IServiceRequest: {
    IData: IServiceRequestReducers,
    IFetch: IServiceRequestFetch,
    IFetchTypes: IEServiceRequestfetchType,
    ISignalR: SRSignalR,
    ISignalRType: SRSignalRType
  },
  ILogisticsPackages: {
    IData: ILogisticsPackagesReducers,
    IFetch: ILogisticsPackagesFetch,
    IFetchTypes: IELogisticsPackagesfetchType,
  },
  IConfig: {
    IData: IConfigReducers,
    IFetch: IConfigFetch,
    IFetchTypes: IConfigFetchType,
  },
};

type IReduxType = typeof IReducers;

export interface IRedux extends IReduxType {}
