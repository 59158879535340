import { useRedux } from "../../../redux/shared/redux.provider";

export default function SoTimeLine() {
  const { Rts } = useRedux();
  return (
    <Rts.TimelineRoot size="lg" variant="subtle" maxW="md">
      <Rts.TimelineItem>
        <Rts.TimelineConnector>
          <Rts.Icons.LuPen />
        </Rts.TimelineConnector>
        <Rts.TimelineContent>
          <Rts.TimelineTitle>
            <Rts.Avatar size="2xs" src="https://i.pravatar.cc/150?u=a" />
            Lucas Moras <span color="fg.muted">has changed</span>
            <span>3 labels</span> on
            <span color="fg.muted">Jan 1, 2024</span>
          </Rts.TimelineTitle>
        </Rts.TimelineContent>
      </Rts.TimelineItem>
    </Rts.TimelineRoot>
  );
}
