import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ILogisticsPackages } from "./logistics_packages.interface";
import { FetchLogisticsPackages } from "./logistics_packages.slice";
import {  } from "./logistics_packages.enums";

export const IntialReducer: ILogisticsPackages = {

};


// export const addWFile = (
//   state: IServiceRequest,
//   action: { payload: fileDropZone[] }
// ) => {
//   state.devices[state.device_edit.idx].warranty_files = action.payload;
// };

export const ExtraReducer = (
  builder: ActionReducerMapBuilder<ILogisticsPackages>
) => {
  builder.addCase(FetchLogisticsPackages.rejected, (state, action: any) => {
    // if (action.meta.arg.type === FetchTypes.VALIDATE_RESULT) {
    //   state.count_invalid_pin++;
    //   if (state.count_invalid_pin === 3) {
    //     window.location.reload();
    //   }
    // }
  });
  builder.addCase(FetchLogisticsPackages.fulfilled, (state, action: any) => {
    // if (action.meta.arg.type === FetchTypes.VALIDATE) {
    //   state.token_for_verify = action.payload.verification_code;
    // }
  });
};
