import React from "react";
import { useRedux } from "../../redux/shared/redux.provider";

export default function Footer() {
    const { Rts } = useRedux();
    return (
        <Rts.Flex
            flexDirection={{
                base: "column",
                xl: "row",
            }}
            alignItems={{
                base: "center",
                xl: "start",
            }}
            justifyContent="end"
            px="30px"
            pb="20px"
        >
            <Rts.Text
                color="gray.400"
                textAlign={{
                    base: "center",
                    xl: "start",
                }}
                mb={{ base: "20px", xl: "0px" }}
            >
                &copy; 2023 - {new Date().getFullYear()},{" "}
                <Rts.Text as="span">
                    BRITEX-CZ, Rts.r.o.
                </Rts.Text>
            </Rts.Text>
        </Rts.Flex>
    );
}
