import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRedux } from "../redux.provider";
import { fileDropZone } from "../redux.types";

function XDropzone({
  filesData,
  editFiles,
  onUpload,
  title,
  isInvalid,
  showDownloadBtn,
}: {
  filesData: fileDropZone[];
  editFiles: (x: fileDropZone[]) => void;
  onUpload: (x: fileDropZone) => void;
  title: string;
  isInvalid?: boolean;
  showDownloadBtn?: boolean;
}) {
  const { Rts, new_guid } = useRedux();
  const [showFile, setShowFile] = useState<fileDropZone[] | null>([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (data: any) => editingFiles(data),
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
      "application/rtf": [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  const editingFiles = async (files: fileDropZone[]) => {
    const filePromises = files.map((file: any) => {
      if (file instanceof File) {
        return new Promise((resolve, reject) => {
          //TODO: Kontrola zda soubor již existuje
          // if (filesData.name.includes(file.name))
          //   reject("Soubor s tímto názvem již existuje");
          const reader = new FileReader();
          reader.onload = () => {
            const guid = new_guid;
            onUpload({
              name: file.name,
              size: file.size,
              type: file.type,
              data: reader.result,
              guid: guid,
            });
            resolve({
              record_id: -1,
              name: file.name,
              size: file.size,
              type: file.type,
              data: reader.result,
              guid: guid,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      } else {
        return file; // Pokud soubor již obsahuje Base64 data, neprovádíme další čtení
      }
    });

    Promise.all(filePromises)
      .then((filesWithBase64) => {
        var x = filesData.slice();
        x.push(...filesWithBase64);
        editFiles(x);
      })
      .catch((error) => {
        console.error("Chyba při čtení souborů:", error);
      });
  };

  const downloadFileItem = (idx: number) => {};

  const removeFileItem = (idx: number) => {
    var x = filesData.slice();
    x.splice(idx, 1);
    editFiles(x);
  };

  useEffect(() => {
    setShowFile(filesData);
  }, [filesData]);

  function IconFile(props: any) {
    const { type, data } = props;
    function FlexItem(IconSet: any, color: string) {
      return (
        <Rts.Flex
          width={"50px"}
          height={"50px"}
          borderRadius="16px"
          border={"1px solid gray"}
          justify={"center"}
          align={"center"}
        >
          <IconSet fontSize={"30px"} color={color} />
        </Rts.Flex>
      );
    }

    switch (type) {
      case "image/jpeg":
      case "image/png":
        return FlexItem(Rts.Icons.FaFileImage, "var(--chakra-colors-red-500)");
      case "application/pdf":
        return FlexItem(Rts.Icons.FaFilePdf, "var(--chakra-colors-red-500)");
      case "application/rtf":
        return FlexItem(Rts.Icons.FaFileWord, "var(--chakra-colors-blue-500)");
      case "application/msword":
        return FlexItem(Rts.Icons.FaFileWord, "var(--chakra-colors-blue-500)");
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return FlexItem(Rts.Icons.FaFileWord, "var(--chakra-colors-blue-500)");
      case "application/vnd.ms-excel":
        return FlexItem(
          Rts.Icons.FaFileExcel,
          "var(--chakra-colors-green-500)"
        );
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return FlexItem(
          Rts.Icons.FaFileExcel,
          "var(--chakra-colors-green-500)"
        );
      default:
        return (
          <Rts.Avatar
            src={data}
            w="50px"
            h="50px"
            borderRadius="15px"
            me="10px"
          />
        );
    }
  }

  const renderFiles = showFile?.map((f, idx) => (
    <Rts.Flex
      direction={"row"}
      align={"center"}
      justify={"space-between"}
      key={f.path}
      mt={"12px"}
      w={"100%"}
    >
      <Rts.Flex w={"100%"} align={"center"}>
        <Rts.Button
          cursor={"default"}
          variant="ghost"
          loading={(f.record_id ?? 0) === -1}
          fontSize={"25px"}
          color={(f.record_id ?? 0) === 0 ? "red" : "green"}
        >
          {(f.record_id ?? 0) === 0 && <Rts.Icons.FaTimes />}
          {(f.record_id ?? 0) > 0 && <Rts.Icons.FaCheck />}
        </Rts.Button>
      </Rts.Flex>

      <Rts.Flex w={"100%"}>
        <>
          <IconFile type={f.type} data={f.data} />
        </>
      </Rts.Flex>
      <Rts.Text
        fontSize={"xs"}
        w={"100%"}
        maxWidth={{ sm: "80px", md: "100%" }}
        overflow={"hidden"}
      >
        {f.name} -{" "}
        {Math.round(f.size / 1024) > 1000
          ? Math.round(f.size / 1024 / 1024)
          : Math.round(f.size / 1024)}{" "}
        {Math.round(f.size / 1024) > 1000 ? "MB" : "KB"}
      </Rts.Text>
      <Rts.Flex justify={"end"} w={"100%"} gap={"15px"}>
        {(showDownloadBtn ?? false) && (
          <Rts.Tooltip content="Stáhnout dokument">
            <Rts.Button
              disabled={(f.record_id ?? 0) < 1}
              size="sm"
              onClick={() => downloadFileItem(idx)}
            >
              <Rts.Icons.FaFileDownload
                style={{
                  fontSize: "15px",
                }}
              />
            </Rts.Button>
          </Rts.Tooltip>
        )}
        <Rts.Tooltip content="Smazat dokument">
          <Rts.Button
            disabled={(f.record_id ?? 0) < 1}
            size="sm"
            onClick={() => removeFileItem(idx)}
          >
            <Rts.Icons.FaTrash
              style={{
                fontSize: "15px",
              }}
            />
          </Rts.Button>
        </Rts.Tooltip>
      </Rts.Flex>
    </Rts.Flex>
  ));

  return (
    <Rts.Card.Root>
      <Rts.Card.Body>
        <Rts.Flex direction={"column"} w="100%">
          <Rts.Flex
            direction={"row"}
            justify={"end"}
            cursor={"pointer"}
            {...getRootProps({ className: "dropzone" })}
          >
            <div style={{ width: "100%" }}>
              <Rts.Text fontSize={"xs"}>{title}</Rts.Text>
            </div>
            <input {...getInputProps()} />
            <Rts.Icons.FaFolderOpen fontSize={"36px"} color="gray" />
          </Rts.Flex>

          <Rts.Flex direction={"column"} w="100%" p={"6px"}>
            <>{renderFiles}</>
          </Rts.Flex>
        </Rts.Flex>
      </Rts.Card.Body>
    </Rts.Card.Root>
  );
}

export default XDropzone;
