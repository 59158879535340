import { EndpointMethod } from "../../shared/redux.enums";
import { endpoint, fileDropZone } from "../../shared/redux.types";

const Endpoints = {
  POST_VALIDATE: (data: { email: string }) => {
    return {
      method: EndpointMethod.POST,
      ep: `/api/ServiceRequest/Validate`,
      data: data,
    } as endpoint;
  },
  GET_VENDORS: {
    method: EndpointMethod.GET,
    ep: `/api/ServiceRequest/VendorsLookup`,
  } as endpoint,
  POST_VALIDATE_RESULT: (data: { verification_code: string; pin: number }) => {
    return {
      method: EndpointMethod.POST,
      ep: `/api/ServiceRequest/ValidateResult`,
      data: data,
    } as endpoint;
  },
  POST_FILE: (data: fileDropZone) => {
    return {
      method: EndpointMethod.POST,
      ep: `/api/ServiceRequest/FileUpload`,
      data: data,
    } as endpoint;
  },
  POST_SERVICE_REQUEST: (data: any) => {
    return {
      method: EndpointMethod.POST,
      ep: `/api/ServiceRequest`,
      data: data,
    } as endpoint;
  }
};

export default Endpoints;
