import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import { useRedux } from "../../../redux/shared/redux.provider";
import Address from "./address-form";
import { CustomerAddress } from "../../../redux/features/createservicerequest/servicerequest.types";
import { Card_type } from "./form-controll";
import { useEffect } from "react";

export default function Card() {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer, isLoading } = redux;
  const data = store.service_request;
  const new_address = {
    type: AddressTypes.INVOICE,
    first_name: "",
    last_name: "",
    phone: "",
    street: "",
    city: "",
    postal_code: "",
    country: "",
    company_id: "",
  } as CustomerAddress;

  useEffect(() => {
  },[
    data.invoice_address,
  ])

  return (
    <Rts.Flex direction={"column"} gap={4}>
      <Address data={data.customer} />
      <Rts.Flex direction={{base: "column", md: "row"}} gap={4}>
        <Rts.RadioCardRoot
          w={"100%"}
          value={data.invoice_type}
          onChangeCapture={(e: any) => {
            e.stopPropagation();
            dispatch(
              IReducer.IServiceRequest.IData.setInvoiceType(e.target.value)
            );
          }}
        >
          <Rts.Flex direction={{base: "column", md: "row"}} w={"100%"} gap={4}>
            <Card_type
              label={"Fakturovat běžně"}
              descriptionTop={"Fakturační údaje zákazníka"}
              value={"1"}
              isPrice={false}
            />
            <Card_type
              label={"Fakturovat podle IČO"}
              descriptionTop={"Automaticky vyplníme podle IČO"}
              descriptionBottom={
                <>
                  <Rts.Text ms={2} mt={2} fontSize={"xs"}>
                    <Rts.Flex direction={"column"}>
                      <Rts.Text fontSize={"xs"}>
                        {store.shared.api_ico?.subject}
                      </Rts.Text>
                      <Rts.Text fontSize={"xs"}>
                        {store.shared.api_ico?.street}
                      </Rts.Text>
                      <Rts.Text fontSize={"xs"}>
                        {store.shared.api_ico?.city},{" "}
                        {store.shared.api_ico?.postal_code},{" "}
                        {store.shared.api_ico?.country}
                      </Rts.Text>
                    </Rts.Flex>
                  </Rts.Text>
                  <Rts.Flex
                    position={"absolute"}
                    right={3}
                    bottom={3}
                    fontSize={"xx-large"}
                  >
                    <Rts.Button
                      variant={"ghost"}
                      disabled={data.invoice_type !== "2"}
                      onClick={() => dispatch(IReducer.IServiceRequest.IData.useInvoiceCompany(true))}
                    >
                      {!data.invoice_address?.company_id ? (
                        <Rts.Icons.LuPlus />
                      ) : (
                        <Rts.Icons.LuPenLine />
                      )}
                    </Rts.Button>
                  </Rts.Flex>
                </>
              }
              value={"2"}
              isPrice={false}
            />
            <Card_type
              label={"Fakturovat na jinou adresu"}
              descriptionTop={
                data.invoice_address?.first_name ? (
                  `${data.invoice_address?.street}, ${data.invoice_address?.city}, ${data.invoice_address?.postal_code}, ${data.invoice_address?.country}`
                ) : (
                  <Rts.Flex>
                    <Rts.Alert
                      title={"Adresa není doplněna"}
                      status="warning"
                      icon={<Rts.Icons.LuAlertTriangle />}
                    >
                      Očekáváme doplnění další adresy, kterou v případě nezáruční opravy použijeme pro následnou fakturaci
                    </Rts.Alert>
                  </Rts.Flex>
                )
              }
              descriptionBottom={
                <Rts.Flex
                  position={"absolute"}
                  right={3}
                  bottom={3}
                  fontSize={"xx-large"}
                >
                  <Rts.Button
                    variant={"ghost"}
                    disabled={data.invoice_type !== "3"}
                    onClick={() =>
                      dispatch(
                        IReducer.IServiceRequest.IData.setAddressDialog({
                          open: true,
                          isPickup: false,
                          isInvoice: true,
                        })
                      )
                    }
                  >
                    {!data.invoice_address?.first_name ? (
                      <Rts.Icons.LuPlus />
                    ) : (
                      <Rts.Icons.LuPenLine />
                    )}
                  </Rts.Button>
                </Rts.Flex>
              }
              value={"3"}
              isFill={true}
            />
          </Rts.Flex>
        </Rts.RadioCardRoot>
      </Rts.Flex>
    </Rts.Flex>
  );
}
