import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IServiceRequest } from "./servicerequest.interface";
import { FetchServiceRequest, ISignalR } from "./servicerequest.slice";
import { AddressTypes, FetchTypes, signalRTypes } from "./servicerequest.enums";
import { CustomerAddress, Device, IFile } from "./servicerequest.types";

export const IntialReducer: IServiceRequest = {
  id: 0,
  activeStep: -1,
  signature: null,
  is_signature: false,
  is_veryfied: false,
  count_invalid_pin: 0,
  pin_no: 0,
  is_send: false,
  token_for_verify: "",
  token_verified: "",
  vendors: [],
  all_is_ok: false,
  device_edit: {
    is_open: false,
    idx: -1,
  },
  invoice_address: null,
  delivery_address: null,
  pickup_address: null,
  customer: {
    type: AddressTypes.CUSTOMER,
    street: "",
    city: "",
    postal_code: "",
    country: "CZ",
    company_id: "",
    contact_person: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  },
  devices: [],
  pickup_date: "",
  pickup_type: null,
  delivery_type: null,
  invoice_type: "1",
  invoice_company_open: false,
  selectable_address: [],
  address_dialog: {
    open: false,
    isPickup: false,
  },
};

export const useInvoiceCompany = (
  state: IServiceRequest,
  action: { payload: boolean }
) => {
  state.invoice_company_open = action.payload;
};

export const addDevice = (state: IServiceRequest) => {
  state.devices.push({
    vendor: "",
    model: "",
    imei: "",
    num_at_card: "",
    type: "",
    serial_number: "",
    damage: "",
    defect: "",
    purchase_date: null,
    is_warranty: true,
    estimated_repair_cost: 550,
    warranty_files: [],
    receipt_files: [],
    damage_files: [],
    is_backup: false,
    is_failed_backup_return: false,
    is_saved: false,
  });
  state.device_edit.idx = state.devices.length - 1;
  state.device_edit.is_open = true;
};

const new_address = {
  type: AddressTypes.PICKUP,
  first_name: "",
  last_name: "",
  phone: "",
  street: "",
  city: "",
  postal_code: "",
  country: "",
  company_id: "",
} as CustomerAddress;

const FileToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const addWFile = (
  state: IServiceRequest,
  action: { payload: IFile[] }
) => {
  action.payload.forEach(async (e) => {
    e.data = await FileToBase64(e);
  });
  state.devices[state.device_edit.idx].warranty_files = action.payload;
};

export const addReceiptFile = (
  state: IServiceRequest,
  action: { payload: IFile[] }
) => {
  action.payload.forEach(async (e) => {
    e.data = await FileToBase64(e);
  });
  state.devices[state.device_edit.idx].receipt_files = action.payload;
};

export const addDamageFile = (
  state: IServiceRequest,
  action: { payload: IFile[] }
) => {
  action.payload.forEach(async (e) => {
    e.data = await FileToBase64(e);
  });
  state.devices[state.device_edit.idx].damage_files = action.payload;
};

export const closeEditor = (state: IServiceRequest) => {
  state.device_edit.idx = -1;
  state.device_edit.is_open = false;
};

export const setAllOk = (
  state: IServiceRequest,
  action: { payload: boolean }
) => {
  state.all_is_ok = action.payload;
};

export const saveDevice = (
  state: IServiceRequest,
  action: { payload: any }
) => {
  state.devices[state.device_edit.idx] = {
    ...state.devices[state.device_edit.idx],
    ...action.payload,
  };
};

export const setInvoiceType = (
  state: IServiceRequest,
  action: { payload: string }
) => {
  state.invoice_type = action.payload;
};

export const setPickupDate = (
  state: IServiceRequest,
  action: { payload: string }
) => {
  state.pickup_date = action.payload;
};

export const setPickupType = (
  state: IServiceRequest,
  action: { payload: string }
) => {
  state.pickup_type = action.payload;
  switch (action.payload) {
    case "1":
      state.pickup_address = null;
      break;
    case "2":
      state.pickup_address = {
        ...state.customer,
        type: AddressTypes.PICKUP,
      };
      break;
    case "3":
      state.pickup_address = {
        ...(state.invoice_address ?? new_address),
        type: AddressTypes.PICKUP,
      };
      break;
    case "4":
      state.pickup_address = {
        ...new_address,
        type: AddressTypes.PICKUP,
      };
  }
};

export const setDeliveryType = (
  state: IServiceRequest,
  action: { payload: string }
) => {
  state.delivery_type = action.payload;
  switch (action.payload) {
    case "1":
      state.delivery_address = null;
      break;
    case "2":
      state.delivery_address = { ...state.customer, type: AddressTypes.DELIVERY };
      break;
    case "3":
      state.delivery_address = { ...(state.invoice_address ?? new_address), type: AddressTypes.DELIVERY };
      break;
    case "4":
      state.delivery_address = { ...(state.pickup_address ?? new_address), type: AddressTypes.DELIVERY };
      break;
    case "5":
      state.delivery_address = { ...new_address, type: AddressTypes.DELIVERY };
      break;
  }
};

export const saveCustomer = (
  state: IServiceRequest,
  action: { payload: CustomerAddress }
) => {
  state.customer = action.payload;
};

export const setEmail = (
  state: IServiceRequest,
  action: { payload: string }
) => {
  state.customer.email = action.payload;
};

export const saveAddress = (
  state: IServiceRequest,
  action: { payload: CustomerAddress }
) => {
  if (action.payload.type === AddressTypes.CUSTOMER)
    state.customer = action.payload;
  if (action.payload.type === AddressTypes.INVOICE)
    state.invoice_address = action.payload;
  if (action.payload.type === AddressTypes.DELIVERY)
    state.delivery_address = action.payload;
  if (action.payload.type === AddressTypes.PICKUP)
    state.pickup_address = action.payload;
};

export const openEditor = (
  state: IServiceRequest,
  action: { payload: number }
) => {
  state.device_edit.idx = action.payload;
  state.device_edit.is_open = true;
};

export const setAddressDialog = (
  state: IServiceRequest,
  action: { payload: { open: boolean; isPickup: boolean; isInvoice?: boolean } }
) => {
  state.address_dialog = action.payload;
};

export const removeDevice = (
  state: IServiceRequest,
  action: { payload: number }
) => {
  state.devices.splice(action.payload, 1);
};

export const beforeStep = (state: IServiceRequest) => {
  state.activeStep--;
};

export const nextStep = (state: IServiceRequest) => {
  state.activeStep++;
};

export const setState = (
  state: IServiceRequest,
  action: { payload: IServiceRequest }
) => {
  state = action.payload;
};

export const ExtraReducer = (
  builder: ActionReducerMapBuilder<IServiceRequest>
) => {
  builder.addCase(FetchServiceRequest.rejected, (state, action: any) => {
    if (action.meta.arg.type === FetchTypes.VALIDATE_RESULT) {
      state.count_invalid_pin++;
      if (state.count_invalid_pin === 3) {
        window.location.reload();
      }
    }
  });

  builder.addCase(ISignalR.fulfilled, (state, action: any) => {
    if (action.meta.arg.type === signalRTypes.ServiceRequestGetMyAddress) {
      state.selectable_address = action.payload;
    }
  });

  builder.addCase(FetchServiceRequest.fulfilled, (state, action: any) => {
    if (action.meta.arg.type === FetchTypes.VALIDATE) {
      state.token_for_verify = action.payload.verification_code;
    }
    if (action.meta.arg.type === FetchTypes.GET_VENDORS) {
      state.vendors = action.payload;
    }
    if (action.meta.arg.type === FetchTypes.VALIDATE_RESULT) {
      state.token_verified = action.payload.verification_code;
      nextStep(state);
    }
    if (action.meta.arg.type === FetchTypes.POST_CREATE_SERVICE_ORDER) {
      state.is_send = action.payload;
      if (state.is_send) nextStep(state);
    }
  });
};
