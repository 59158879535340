import axios from "axios";
import {API} from "./endpoints/redux.endpoints";
import {endpoint} from "./redux.types";
import {EndpointMethod} from "./redux.enums";


export default async function Fetch(
    endpoint: endpoint,
    accessToken?: string,
    extra?: any,
    timeout: number = 30000
) {
    var server = "";
    try {
        accessToken = localStorage.getItem("accessToken") ?? "";
    } catch {
        accessToken = "";
    }

    try {
        server =
            window.location.host === "localhost:3000" ? "https://localhost:7082" : "";
    } catch {
    }
    try {
        return await axios({
            method: endpoint.method,
            timeout: timeout,
            url: `${server}${endpoint.ep}`,
            data: endpoint.method === EndpointMethod.GET ? null : endpoint.data,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((result: any) => {
                return Promise.resolve(result.data);
            })
            .catch((err: any) => {
                return Promise.reject({
                    ...err,
                    ...extra,
                });
            });
    } catch (err: any) {
        return Promise.reject({...err, ...extra});
    }
};
export const Endpoints = API;
