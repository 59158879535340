// Chakra Imports
import { useState } from "react";
import NavbarLinks from "./navbarLinks";
import { useRedux } from "../../redux/shared/redux.provider";

export default function Navbar(props: any) {
  const { redux, Rts } = useRedux();
  const { useColorModeValue } = Rts;
  const [scrolled, setScrolled] = useState(false);
  const {
    variant,
    children,
    logoText,
    fixed,
    secondary,
    brandText,
    onOpen,
    isAuth,
    getUser,
    LogOff,
    ...rest
  } = props;

  //fixed && scrolled
  let fs_navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let fs_navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let fs_navbarBorder = useColorModeValue(
    "#FFFFFF",
    "rgba(255, 255, 255, 0.31)"
  );
  let fs_navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );

  let mainText = useColorModeValue("gray.700", "gray.200");
  let secondaryText = useColorModeValue("gray.400", "gray.200");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(21px)";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = fs_navbarShadow;
      navbarBg = fs_navbarBg;
      navbarBorder = fs_navbarBorder;
      navbarFilter = fs_navbarFilter;
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <Rts.Flex
      position={"fixed"}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={"30px"}
      px={{
        sm: paddingX,
        md: "30px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top="18px"
      w={{ sm: "calc(100vw - 30px)", xl: "calc(100vw - 75px - 260px)" }}
    >
      <Rts.Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
      >
        <Rts.Box mb={{ sm: "8px", md: "0px" }}>
          <Rts.BreadcrumbRoot>
            <Rts.BreadcrumbLink href="/">{"RTS"}</Rts.BreadcrumbLink>
            <Rts.BreadcrumbLink href="/"> {brandText}</Rts.BreadcrumbLink>
          </Rts.BreadcrumbRoot>
          <Rts.Link
            color={mainText}
            href="ThisPage"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            //!TODO - opravit _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            {brandText}
          </Rts.Link>
        </Rts.Box>
        <Rts.Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <NavbarLinks
            onOpen={onOpen}
            logoText={logoText}
            secondary={secondary}
            fixed={fixed}
            getUser={getUser}
            isAuth={isAuth}
            LogOff={LogOff}
          />
        </Rts.Box>
      </Rts.Flex>
    </Rts.Flex>
  );
}
