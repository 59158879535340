import { useState } from "react";
import { useRedux } from "../../redux/shared/redux.provider";
import LpCreate from "./lp.create";
import LpItem from "./lp.item";

export default function LPPreview() {
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const { Rts } = useRedux();
  return (
    <Rts.Flex w={"100%"} direction={"column"}>
      <Rts.Card.Root w={"100%"} mb={2}>
        <Rts.Card.Body>
          <Rts.Flex
            gap={2}
            mb={2}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Rts.Flex gap={4} direction={"row"} align={"center"}>
              <Rts.Checkbox />
              <Rts.Button
                disabled={true}
                variant={"outline"}
                size={"sm"}
                alignContent={"center"}
              >
                <Rts.Text mt={0}>Další akce</Rts.Text>
              </Rts.Button>
            </Rts.Flex>
            <Rts.Flex gap={2} direction={"row"} mt={2}>
              <LpCreate />
              <Rts.Button
                size={"sm"}
                variant={"subtle"}
                aria-label="Přijmout zásilky"
              ><Rts.Icons.LuArrowDownToDot /></Rts.Button>
            </Rts.Flex>
            <Rts.Flex>
              <Rts.Flex me={2}>
                {/* <Rts.IRangedatepicker
                  selectedDates={selectedDates}
                  onDateChange={setSelectedDates} */}
                {/* /> */}
              </Rts.Flex>
              <Rts.Flex>
                <Rts.InputGroup startElement={<Rts.Icons.FaSearch />}>
                  <Rts.Input placeholder="Hledat..." />
                </Rts.InputGroup>
              </Rts.Flex>
            </Rts.Flex>
            {/* <SoFilter /> */}
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <Rts.Navigations
        mb={{ mb: 2 }}
        type=""
        data={{
          is_prev: true,
          is_next: true,
          pageNumber: 1,
          pageSize: 20,
          data: [
            {
              id: 4000111,
              is_return: false,
              is_cod: false,
              cod_price: 0,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Přijato",
            },
            {
              id: 4000111,
              is_return: false,
              is_cod: false,
              cod_price: 0,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Rozbaleno",
            },
            {
              id: 4000111,
              is_return: false,
              is_cod: false,
              cod_price: 0,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Předáno",
            },
            {
              id: 4000111,
              is_return: false,
              is_cod: false,
              cod_price: 0,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Neshoda",
            },
            {
              id: 4000111,
              is_return: true,
              is_cod: true,
              cod_price: 2541.22,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Čeká na vyzvednutí",
            },
            {
              id: 4000111,
              is_return: true,
              is_cod: false,
              cod_price: 0,
              customer: "Luboš Fridrich",
              customer_address: "Nová 182, 123 45 Praha 2",
              carrier: "DPD CZ",
              pack_code: "13654142154874",
              date: "2024-10-24",
              status: "Odesláno",
            },
          ],
          total: 6,
          count: 0,
          onNext: () => {},
          onPrev: () => {},
          onPageSize: (size: number) => {},
        }}
        renderComponent={LpItem}
      />
    </Rts.Flex>
  );
}
