import { defineSlotRecipe, RecipeVariantProps } from "@chakra-ui/react";
import { numberInputAnatomy } from "@chakra-ui/react/anatomy";

export const numberInputSlotRecipe = defineSlotRecipe({
  className: "chakra-number-input",
  slots: numberInputAnatomy.keys(),
  base: {
    control: {
      borderRadius: "15px",
    },
    root: {
      borderRadius: "15px",
    },
    input: {
      borderRadius: "15px",
    }
  },
});

type NumberInputVariantProps = RecipeVariantProps<typeof numberInputSlotRecipe>;
export interface CardProps
  extends React.PropsWithChildren<NumberInputVariantProps> {}
