import { EndpointMethod } from "../../redux.enums";
import { endpoint } from "../../redux.types";

export const HELPDESK = {
  DASHBOARD: (time_at:string) => {
    return {
      method: EndpointMethod.GET,
      ep: `/api/admin/helpdesk?time_at=${time_at}`,
    } as endpoint;
  },
};
