import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ICore, ISignalR } from "./core.interface";
import { CoreMessage } from "./core.types";
import { signalRTypes } from "./core.enums";
import { HubConnectionState } from "@microsoft/signalr";
import { Vendors } from '../shared/shared.types';

export const IntialReducer: ICore = {
  isAlive: false,
  isLoading: true,
  isError: false,
  message: "",
  stateId: -1,
  isIframe: false,
  loadingState: 1,
  loadingText: "Načítám Helios prostředí",
  loadingTextReporter: "Načítám Helios prostředí.",
  signalr: { state: false, connection_id: "" },
  crossui_config: {
    title: "",
    logo: "",
    favicon: "",
    description: "",
    copyright: "",
    images_uri: {
      bg_dark: "",
      bg_light: "",
      sub_logo_dark: "",
      sub_logo_light: "",
      web_app_logo_dark: "",
      web_app_logo_light: "",
      login_button_image_light_uri: "",
      login_button_image_dark_uri: "",
    },
    texts: {
      login_button: "",
      await_app_server: "",
      app_server_error: "",
      app_server_error_description: "",
      dark_mode: "",
      light_mode: "",
      logout: "",
      unique_id: "",
      settings: "",
      profile: "",
    },
    theme: {
      primary_light: "",
      primary_dark: "",
      primary_light_hover: "",
      primary_dark_hover: "",
      secondary_light: "",
      secondary_dark: "",
      info_light: "",
      info_dark: "",
      login_button_bg_light: "",
      login_button_bg_dark: "",
      login_button_text_light: "",
      login_button_text_dark: "",
      login_button_bg_dark_hover: "",
      login_button_bg_light_hover: "",
    },
  },
  messages: [],
};

export const setState = (state: any, action: { payload: boolean }) => {
  state.isLoading = action.payload;
  state.loadingText = "";
  state.stateId += 1;
};

const GetDots = (state: ICore) => {
  switch (state.loadingState) {
    case 1:
      return ".";
    case 2:
      return "..";
    case 3:
      return "...";
    default:
      return ".";
  }
};

export const LoadingState = (state: ICore, action: { payload: {} }) => {
  if (state.isLoading) {
    var dots = GetDots(state);
    state.loadingState++;

    if (state.loadingState > 3) state.loadingState = 1;

    state.loadingTextReporter = state.loadingText + dots;
  }
};

export const setConnection = (
  state: ICore,
  action: {
    payload: { state: HubConnectionState; connectionId: string | null };
  }
) => {
  state.signalr.state = action.payload.state === HubConnectionState.Connected;
  state.signalr.connection_id = action.payload.connectionId;
  if (state.signalr.state) {
    state.isAlive = true;
  } else {
    state.isAlive = false;
  }
};

export const newMessage = (
  state: ICore,
  action: {
    payload: {
      title?: string;
      message: string;
      serverity:
        | "info"
        | "warning"
        | "success"
        | "error"
        | "loading"
        | undefined;
      duration: number;
    };
  }
) => {
  state.messages.push({
    title: action.payload.title ?? "RTS Notifikace",
    message: action.payload.message,
    serverity: action.payload.serverity,
    duration: action.payload.duration,
  });
};

export const consumeMessage = (
  state: ICore,
  action: { payload: CoreMessage }
) => {
  state.messages.splice(
    state.messages.findIndex((x) => x === action.payload),
    1
  );
};

export const SetStateWithText = (
  state: any,
  action: { payload: { value: boolean; text: string } }
) => {
  state.isLoading = action.payload.value;
  state.loadingText = action.payload.value ? action.payload.text : "";
  state.stateId += state.stateId;
};

export const SetAlive = (state: ICore, action: { payload: boolean }) => {
  state.isAlive = action.payload;
  state.stateId += state.stateId;
};

export const SetError = (state: ICore, action: { payload: string }) => {
  state.message = action.payload;
  if (state.message !== "") {
    state.isError = true;
    state.stateId += state.stateId;
  } else {
    state.isError = false;
    state.stateId += state.stateId;
  }
};

export const SetIframe = (state: ICore, action: { payload: boolean }) => {
  state.isIframe = action.payload;
  state.stateId += state.stateId;
};

export const ExtraReducer = (builder: ActionReducerMapBuilder<ICore>) => {
  builder.addCase(ISignalR.fulfilled, (state, action) => {
    if (action.meta.arg.type === signalRTypes.GetCountries) {
      var currState: any = action.payload;
      state.crossui_config = currState;
    }

    if (action.meta.arg.type === signalRTypes.GetVendors) {
      var currState: any = action.payload;
      state.crossui_config = currState;
    }
  });
};
