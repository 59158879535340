import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraReducer,
  InitReducer,
  IntialReducer,
} from "./state.reducers";
import { FetchTypes } from "./state.enums";
import { FetchData } from "./state.fetches";

const initialState = IntialReducer();

const StateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    init: InitReducer,
  },
  extraReducers: ExtraReducer,
});

export default StateSlice.reducer;

export const StateReducers = StateSlice.actions;

export const fetchTypes = FetchTypes;

export const fetchState = FetchData;
