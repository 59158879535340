import Core from "./rts.core";
import { createRoot } from "react-dom/client";
import { Routes, Route, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/shared/redux.store";
import theme from "./layouts/theme/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ColorModeProvider } from "./components/ui/color-mode";
import { ReduxProvider } from "./redux/shared/redux.provider";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ChakraProvider value={theme}>
    <ColorModeProvider enableSystem defaultTheme="light">
        <Provider store={store}>
          <ReduxProvider>
            <HashRouter>
              <Routes>
                <Route path="*" element={<Core />} />
              </Routes>
            </HashRouter>
          </ReduxProvider>
        </Provider>
    </ColorModeProvider>
  </ChakraProvider>
);
