import { useRedux } from "../../redux/shared/redux.provider";
import SoDetailApi from "./components/so.detail.api";
import SoDetailCustomer from "./components/so.detail.customer";
import SoDetailItems from "./components/so.detail.items";
import SoDetailDocuments from "./components/so.detail.documents";
import * as z from "zod";
import React from "react";
import SoTimeLine from "./components/so.detail.timeline";

export default function SoDetail(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { Rts } = useRedux();

  const [state, setState] = React.useState("customer");

  const StateViewer = () => {
    switch (state) {
      case "customer":
        return <SoDetailCustomer />;
      case "items":
        return <SoDetailItems />;
      case "documents":
        return <SoDetailDocuments />;
      case "api":
        return <SoDetailApi />;
      case "timeline":
        return <SoTimeLine />;
      default:
        return <SoDetailCustomer />;
    }
  };

  return (
    <>
      <Rts.DrawerRoot open={props.isOpen} size="xl">
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent>
          <Rts.DrawerHeader>
            <Rts.DrawerTitle>Zakázka č. 4001111</Rts.DrawerTitle>
            <Rts.Badge p={2} colorPalette="green">
              Stav zakázky
            </Rts.Badge>
          </Rts.DrawerHeader>
          <Rts.DrawerBody gap={2}>
            <Rts.Grid gap={2} templateColumns={"repeat(4, 1fr)"}>
              <Rts.GridItem
                gap={2}
                p={4}
                h={"100vh"}
                borderRight={"1px solid var(--rts-colors-gray-700)"}
              >
                <Rts.Flex
                  direction={"column"}
                  gap={2}
                  justify={"center"}
                  align={"center"}
                >
                  <Rts.Avatar
                    size={"2xl"}
                    borderRadius={"12px"}
                    src={"https://bit.ly/broken-link"}
                  />
                  <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                    SAMSUNG
                  </Rts.Text>
                  <Rts.Text fontSize={"sm"}>SM-G988SMTETL</Rts.Text>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    mt={4}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("customer")}
                  >
                    <Rts.Icons.LuUser2 />
                    Zákazník
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("device")}
                  >
                    <Rts.Icons.MdOutlineDevices />
                    Přístroj
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("items")}
                  >
                    <Rts.Icons.LuLayers />
                    Materiál
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("work")}
                  >
                    <Rts.Icons.MdWorkOutline />
                    Práce
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("documents")}
                  >
                    <Rts.Icons.AiOutlineFileZip />
                    Dokumenty
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("documents")}
                  >
                    <Rts.Icons.AiFillTruck />
                    Doprava
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("timeline")}
                  >
                    <Rts.Icons.MdTimeline />
                    Události
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("history")}
                  >
                    <Rts.Icons.LuHistory />
                    Stavová osa
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("comments")}
                  >
                    <Rts.Icons.AiOutlineComment />
                    Komentáře
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("notifications")}
                  >
                    <Rts.Icons.IoMdPaperPlane />
                    Notifikace
                  </Rts.Button>
                  <Rts.Button
                    w={"100%"}
                    size={"md"}
                    variant={"outline"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() => setState("api")}
                  >
                    <Rts.Icons.FaNetworkWired />
                    API komunikace
                  </Rts.Button>
                </Rts.Flex>
              </Rts.GridItem>
              <Rts.GridItem colSpan={3} p={4}>
                <StateViewer />
              </Rts.GridItem>
            </Rts.Grid>
          </Rts.DrawerBody>
          <Rts.DrawerCloseTrigger onClick={props.onClose} />
          <Rts.DrawerFooter>
            <Rts.DialogRoot
              size="sm"
              placement="center"
              motionPreset="slide-in-bottom"
            >
              <Rts.DialogTrigger asChild>
                <Rts.Button variant={"outline"} size={"sm"} gap={2}>
                  <Rts.Icons.LuMoreVertical />
                  Další akce
                </Rts.Button>
              </Rts.DialogTrigger>
              <Rts.DialogContent>
                <Rts.DialogHeader>
                  Další akce
                  <Rts.DialogCloseTrigger />
                </Rts.DialogHeader>
                <Rts.DialogBody>
                  rem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Rts.DialogBody>
              </Rts.DialogContent>
            </Rts.DialogRoot>

            <Rts.Button variant={"outline"} size={"sm"} gap={2}>
              <Rts.Icons.LuCamera />
              Kamera
            </Rts.Button>
            <Rts.Button variant={"outline"} size={"sm"} gap={2}>
              <Rts.Icons.LuSave />
              Uložit
            </Rts.Button>
          </Rts.DrawerFooter>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
    </>
  );
}
