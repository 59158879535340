import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./redux.store";
import type { DispatchContextType } from "./redux.types";
import { createContext, useContext, useEffect, useState } from "react";
import { ICoreReducers } from "../features/core/core.interface";
import { IReducers, IRedux } from "./redux.iterface";
import signalR, { onChangeConnection, onConnectState } from "./redux.signalR";
import SComp, {
  Box,
  Link,
  Text,
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
  DrawerTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  Tooltip,
  InfoTip,
  ToggleTip,
  Button,
  Flex,
  Avatar,
  AvatarGroup,
  BreadcrumbCurrentLink,
  BreadcrumbLink,
  BreadcrumbRoot,
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
  useColorMode,
  useColorModeValue,
  SegmentedControl,
  RadioCardItem,
  RadioCardLabel,
  RadioCardRoot,
  ActionBarCloseTrigger,
  ActionBarContent,
  ActionBarRoot,
  ActionBarSelectionTrigger,
  ActionBarSeparator,
  TimelineItem,
  TimelineRoot,
  Status,
  Circle,
  Float,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Badge,
  Card,
  Input,
  InputGroup,
  Image,
  StepsCompletedContent,
  StepsContent,
  StepsItem,
  StepsList,
  StepsNextTrigger,
  StepsPrevTrigger,
  StepsRoot,
  Alert,
  Spinner,
  Textarea,
  Container,
  Center,
  AbsoluteCenter,
  Tabs,
  Field,
  Fieldset,
  createListCollection,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SInput,
  Checkbox,
  Tag,
  Navigations,
  ColorModeButton,
  CssInterfaces,
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
  DrawerSideContent,
  Grid,
  GridItem,
  FileUploadDropzone,
  FileUploadList,
  FileUploadRoot,
  DialogActionTrigger,
  TimelineConnector,
  TimelineContent,
  TimelineTitle,
  Switch,
  NumberInputField,
  NumberInputRoot,
  PinInput,
  DatePicker,
  SignaturePad,
  StatLabel,
  StatRoot,
  StatValueText,
  SimpleGrid,
} from "./redux.components";
import { on } from "events";
import { HubConnectionState } from "@microsoft/signalr";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const DispatchContext = createContext<DispatchContextType | undefined>(
  undefined
);

export const useRedux = () => {
  const context = useContext(DispatchContext);
  if (!context) {
    throw new Error("useRedux must be used within an DispatchContext");
  }
  return context;
};

export const ReduxProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoading, isAlive, message, isError, stateId, isIframe } =
    useAppSelector((state: RootState) => state.core);
  const { setError, setState } = ICoreReducers;

  const [isConnected, setIsConnected] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {

    onChangeConnection((state, stateMsg) => {
      setIsConnected(state);
      setMsg(stateMsg);
    });

    onConnectState((state) => {
      setIsConnected(state.state === HubConnectionState.Connected);
      dispatch(
        IReducer.ICore.IData.setConnection({
          state: state.state,
          connectionId: state.connectionId,
        })
      );
    });
  }, []);

  const IReducer: IRedux = IReducers;

  const dispatcher = useDispatch<AppDispatch>();

  const store = useAppSelector((state: RootState) => state);

  const new_guid =
    Math.floor((1 + Math.random()) * 0x10000).toString(16) +
    Math.floor((1 + Math.random()) * 0x10000).toString(16) +
    Math.floor((1 + Math.random()) * 0x10000).toString(16) +
    Math.floor((1 + Math.random()) * 0x10000).toString(16);

  const readResult = async (result: any) => {
    try {
      if (result) {
        if (result.payload) {
          if (result.payload.statusCode > 399) {
            dispatcher(setError(result.payload.message));
          }
        }
        if (result.error) {
          dispatcher(setError(result.error.message));
        }
      }
    } catch {}
  };

  const dispatch = async (props: any, isLoadingState: boolean = false) => {
    try {
      if (isLoadingState) await dispatcher(setState(true));
      await readResult(await dispatcher(props));
    } catch (err: any) {
      await dispatcher(setError(err?.message));
    } finally {
      if (isLoadingState) await dispatcher(setState(false));
    }
  };

  const dispatchNoLoadingState = async (props: any) => {
    try {
      await readResult(await dispatcher(props));
    } catch (err: any) {
      await dispatcher(setError(err?.message));
    }
  };

  return (
    <DispatchContext.Provider
      value={{
        isConnected,
        stateMsg: msg,
        redux: {
          appDispatch: useDispatch<AppDispatch>(),
          appStore: useSelector,
          isLoading,
          dispatch,
          isAlive,
          isError,
          message,
          stateId,
          store,
          IReducer,
          isIframe,
          dispatchNoLoadingState,
          signalR,
          onChangeConnection,
          onConnectState,
        },
        new_guid,
        Rts: {
          ...SComp,
          useColorMode,
          useColorModeValue,
          DrawerBackdrop,
          DrawerBody,
          DrawerCloseTrigger,
          DrawerContent,
          DrawerFooter,
          DrawerHeader,
          DrawerRoot,
          DrawerTitle,
          DrawerTrigger,
          DialogBody,
          DialogCloseTrigger,
          DialogContent,
          DialogFooter,
          DialogHeader,
          DialogRoot,
          DialogTitle,
          DialogTrigger,
          Tooltip,
          InfoTip,
          ToggleTip,
          Button,
          Flex,
          Icons: SComp.Icon,
          Avatar,
          AvatarGroup,
          BreadcrumbCurrentLink,
          BreadcrumbLink,
          BreadcrumbRoot,
          Box,
          Link,
          Text,
          MenuContent,
          MenuItem,
          MenuRoot,
          MenuTrigger,
          SegmentedControl,
          RadioCardItem,
          RadioCardLabel,
          RadioCardRoot,
          ActionBarCloseTrigger,
          ActionBarContent,
          ActionBarRoot,
          ActionBarSelectionTrigger,
          ActionBarSeparator,
          TimelineItem,
          TimelineRoot,
          Status,
          Circle,
          Float,
          Skeleton,
          SkeletonCircle,
          SkeletonText,
          Badge,
          Card,
          Input,
          InputGroup,
          Image,
          StepsCompletedContent,
          StepsContent,
          StepsItem,
          StepsList,
          StepsNextTrigger,
          StepsPrevTrigger,
          StepsRoot,
          Alert,
          Spinner,
          Textarea,
          Container,
          Center,
          AbsoluteCenter,
          Tabs,
          Field,
          Fieldset,
          createListCollection,
          SelectContent,
          SelectItem,
          SelectLabel,
          SelectRoot,
          SelectTrigger,
          SelectValueText,
          InputMask: SInput,
          Checkbox,
          Tag,
          Navigations,
          ColorModeButton,
          CssInterfaces,
          HoverCardArrow,
          HoverCardContent,
          HoverCardRoot,
          HoverCardTrigger,
          DrawerSideContent,
          Grid,
          GridItem,
          FileUploadDropzone,
          FileUploadList,
          FileUploadRoot,
          DialogActionTrigger,
          TimelineConnector,
          TimelineContent,
          TimelineTitle,
          Switch,
          NumberInputField,
          NumberInputRoot,
          PinInput,
          DatePicker,
          SignaturePad,
          StatLabel,
          StatRoot,
          StatValueText,
          SimpleGrid,
        },
      }}
    >
      {children}
    </DispatchContext.Provider>
  );
};
