import { createAsyncThunk } from "@reduxjs/toolkit";
import { signalRTypes } from "./servicerequest.enums";
import { sendMessageWithTimeout } from "../../shared/redux.signalR";

type inputData = {
  type: signalRTypes;
  data?: {
    token?: string;
    type_name?: string;
  };
};

export const SignalR = createAsyncThunk<
  any,
  inputData,
  { rejectValue: string }
>("shared/fetchData", async (_, thunkAPI) => {
  try {
    return await sendMessageWithTimeout(
      _.type.toString(),
      _.data
    );
  } catch (error) {
    return thunkAPI.rejectWithValue("Nepodařilo");
  }
});
