import { createSlice } from "@reduxjs/toolkit";
import {
  InitReducer,
  IntialReducer,
  ExtraReducer,
  LogOut,
  SelectProfile,
  fakeLogin,
} from "./users.reducers";
import { FetchData } from "./users.fetches";
import { FetchTypes } from "./users.enums";

const initialState = IntialReducer();

const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    init: InitReducer,
    log_off: LogOut,
    set_profile: SelectProfile,
    fakeLogin
  },
  extraReducers: ExtraReducer,
});

export default UsersSlice.reducer;

export const UsersReducers = UsersSlice.actions;

export const fetchTypes = FetchTypes;

export const fetchUser = FetchData;
