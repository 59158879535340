import { createSlice } from "@reduxjs/toolkit";
import {
  addDamageFile,
  addDevice,
  addReceiptFile,
  addWFile,
  beforeStep,
  closeEditor,
  ExtraReducer,
  IntialReducer,
  nextStep,
  openEditor,
  removeDevice,
  saveAddress,
  saveCustomer,
  saveDevice,
  setAllOk,
  setEmail,
  setPickupDate,
  setDeliveryType,
  setPickupType,
  setInvoiceType,
  setState,
  useInvoiceCompany,
  setAddressDialog,
} from "./servicerequest.reducers";
import { FetchData } from "./servicerequest.fetcher";
import { SignalR } from "./servicerequest.signalr";
import { signalRTypes } from "./servicerequest.enums";

const initialState = IntialReducer;

const ServiceRequestSlice = createSlice({
  name: "serviceRequest",
  initialState,
  reducers: {
    setState,
    addDevice,
    removeDevice,
    closeEditor,
    saveCustomer,
    saveAddress,
    saveDevice,
    openEditor,
    nextStep,
    beforeStep,
    setDeliveryType,
    setPickupType,
    setInvoiceType,
    setPickupDate,
    setAddressDialog,
    setEmail,
    setAllOk,
    addWFile,
    addReceiptFile,
    addDamageFile,
    useInvoiceCompany
  },
  extraReducers: ExtraReducer,
});

export default ServiceRequestSlice.reducer;

export const reducers = ServiceRequestSlice.actions;

export const FetchServiceRequest = FetchData;

export const ISignalR = SignalR;

export const ISignalRType = signalRTypes;
