import { createAsyncThunk } from "@reduxjs/toolkit";
import Fetch, { Endpoints } from "../../shared/redux.fetch";
import { FetchTypes } from "./config.enums";
import { endpoint } from "../../shared/redux.types";

export const FetchData = createAsyncThunk<
  string[],
  { type: FetchTypes },
  { rejectValue: string }
>("config/fetchData", async (_, thunkAPI) => {
  try {
    const result = await Fetch(DispatchFetch(_));
    return result;
  } catch (error) {
    return thunkAPI.rejectWithValue("Nepodařilo");
  }
});

const DispatchFetch = (_: { type: FetchTypes }): endpoint => {
  switch (_.type) {
    case FetchTypes.FULLCONFIG:
      return Endpoints.FULLCONFIG.STATE();
  }
};
