import { useRedux } from "../../../redux/shared/redux.provider";

export default function Finish() {
  const { Rts, redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;

  return (
    <Rts.Flex direction={"column"} gap={4}>
      <Rts.Alert status="success">
        Děkujeme. Veškeré informace jsme Vám odeslali na email.
      </Rts.Alert>
      <Rts.Button variant={"subtle"} onClick={() => window.location.reload()}>
        Zpět na začátek
      </Rts.Button>
    </Rts.Flex>
  );
}
