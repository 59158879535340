import { useDisclosure } from "@chakra-ui/react";
import { useRedux } from "../../redux/shared/redux.provider";

export default function SoFilter() {
  const { Rts } = useRedux();
  const { open, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Rts.Flex gap={2}>
        <Rts.InputGroup startElement={<Rts.Icons.FaSearch />}>
          <Rts.Input placeholder="Hledat..." />
        </Rts.InputGroup>
        <Rts.Button size={"sm"} onClick={onOpen}>
          <Rts.Icons.MdFilterList />
        </Rts.Button>
      </Rts.Flex>

      <Rts.DrawerRoot open={open} size="md">
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent>
          <Rts.DrawerCloseTrigger onClick={onClose} />
          <Rts.DrawerHeader>Vyhledávání</Rts.DrawerHeader>
          <Rts.DrawerBody gap={2}>
            <Rts.Flex gap={2} direction={"column"} fontSize={"xs"}>
              <Rts.Field label="Číslo zakázky">
                <Rts.Input placeholder="Číslo zakázky" />
              </Rts.Field>
              <Rts.Field label="Uživatel">
                <Rts.Input placeholder="Uživatel" />
              </Rts.Field>
              <Rts.Field label="Stav">
                <Rts.Input placeholder="Stav" />
              </Rts.Field>
              <Rts.Field label="Druh opravy">
                <Rts.Input placeholder="Druh opravy" />
              </Rts.Field>
              <Rts.Field label="Výrobce">
                <Rts.Input placeholder="Výrobce" />
              </Rts.Field>
              <Rts.Field label="Typ přístroje">
                <Rts.Input placeholder="Typ přístroje" />
              </Rts.Field>
            </Rts.Flex>
          </Rts.DrawerBody>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
    </>
  );
}
