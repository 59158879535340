import { useEffect } from "react";
import { useRedux } from "../../../redux/shared/redux.provider";

export const ControllerForm = () => {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer, isLoading } = redux;
  const activeStep = store.service_request.activeStep;
  const all_ok = store.service_request.all_is_ok;


  useEffect(() => {
    validate_steps();
  },[
    store.service_request
  ])

  const validate_steps = () => {
    let valid = true;
    const data = store.service_request;

    if (activeStep === 0) {
      
      var phone = data.customer.phone
        .replaceAll(" ", "")
        .replaceAll("_", "")
        .replace("+420", "")
        .replace("+421", "");
      if (data.customer.email.length === 0) valid = false;
      if (data.customer.first_name.length === 0) valid = false;
      if (data.customer.last_name.length === 0) valid = false;
      if (data.customer.street.length === 0) valid = false;
      if (data.customer.city.length === 0) valid = false;
      if (data.customer.postal_code.replaceAll("_", "").replace(" ", "").length !== 5)
        valid = false;
      if (phone.length > 0) {
        //Pokud je vyplněný telefon tak ho kontrolujeme
        if (phone.length !== 9) valid = false;
      }
      
      if (data.invoice_type === "2") {
        if (
          data.invoice_address?.company_id.replace(" ", "").replace("_", "")
            .length !== 8 ||
          data.invoice_address?.city.length === 0
        )
          valid = false;
      }

      if (data.invoice_type === "3") {
        var phone_inv = (data.invoice_address?.phone ?? "")
          .replaceAll(" ", "")
          .replaceAll("_", "")
          .replace("+420", "")
          .replace("+421", "");
        if (data.invoice_address?.first_name.length === 0) valid = false;
        if (data.invoice_address?.last_name.length === 0) valid = false;
        if (data.invoice_address?.street.length === 0) valid = false;
        if (data.invoice_address?.city.length === 0) valid = false;
        if (
          data.invoice_address?.postal_code.replaceAll("_", "").replace(" ", "")
            .length !== 5
        )
          valid = false;
        if (data.invoice_address?.country.length === 0) valid = false;
        if (phone_inv.length > 0) {
          //Pokud je vyplněný telefon tak ho kontrolujeme
          if (phone_inv.length !== 9) valid = false;
        }
      }
    }

    if (activeStep === 1) {
      const data = store.service_request.devices;
      if (data.length === 0) valid = false;
    }

    if (activeStep === 2) {
      const data = store.service_request;

      if (data.pickup_type === null) valid = false;
      if (data.delivery_type === null) valid = false;
      if ((data.pickup_type??"1") !== "1")
        if (!store.shared.is_valid_pickup_date) valid = false;

      if (data.pickup_type === "4") {
        var phone_pickup = (data.pickup_address?.phone ?? "")
          .replaceAll(" ", "")
          .replaceAll("_", "")
          .replace("+420", "")
          .replace("+421", "");
        if (data.pickup_address?.first_name.length === 0) valid = false;
        if (data.pickup_address?.street.length === 0) valid = false;
        if (data.pickup_address?.city.length === 0) valid = false;
        if (
          data.pickup_address?.postal_code.replaceAll("_", "").replace(" ", "")
            .length !== 5
        )
          valid = false;
        if (data.pickup_address?.country.length === 0) valid = false;
        if (phone_pickup.length > 0 && phone_pickup.length !== 9) valid = false;
      }
      if (data.delivery_type === "5") {
        var phone_delivery = (data.delivery_address?.phone ?? "")
          .replaceAll(" ", "")
          .replaceAll("_", "")
          .replace("+420", "")
          .replace("+421", "");
        if (data.delivery_address?.first_name.length === 0) valid = false;
        if (data.delivery_address?.street.length === 0) valid = false;
        if (data.delivery_address?.city.length === 0) valid = false;
        if (
          data.delivery_address?.postal_code
            .replaceAll("_", "")
            .replace(" ", "").length !== 5
        )
          valid = false;
        if (data.delivery_address?.country.length === 0) valid = false;
        if (phone_delivery.length > 0 && phone_delivery.length !== 9)
          valid = false;
      }
    }

    return valid;
  };

  const full_validate = () => {
    return false;
  };

  return (
    <Rts.Flex mt={4} direction={"row"} justify={"space-between"}>
      {activeStep < 4 && (
        <Rts.Button
          variant={"subtle"}
          onClick={() => dispatch(IReducer.IServiceRequest.IData.beforeStep())}
          disabled={activeStep < 1}
        >
          <Rts.Icons.AiOutlineArrowLeft />
          <Rts.Text ms={2}>Zpět</Rts.Text>
        </Rts.Button>
      )}
      {activeStep < 3 && (
        <Rts.Button
          variant={"subtle"}
          onClick={() => dispatch(IReducer.IServiceRequest.IData.nextStep())}
          disabled={activeStep === 3 || !validate_steps()}
        >
          <Rts.Text me={2}>Dále</Rts.Text>
          <Rts.Icons.AiOutlineArrowRight />
        </Rts.Button>
      )}
      {activeStep === 3 && (
        <Rts.Button
          variant={"subtle"}
          onClick={() =>
            dispatch(
              IReducer.IServiceRequest.IFetch({
                type: IReducer.IServiceRequest.IFetchTypes
                  .POST_CREATE_SERVICE_ORDER,
                data: { data: store.service_request },
              }),
              true
            )
          }
          disabled={activeStep !== 3 || !full_validate || !all_ok}
          loading={isLoading}
        >
          <Rts.Text me={2}>Odeslat</Rts.Text>
          <Rts.Icons.AiOutlineSend />
        </Rts.Button>
      )}
    </Rts.Flex>
  );
};

export const Card_type = (props: {
  label: string;
  value: string;
  descriptionTop?: React.ReactNode;
  descriptionBottom?: React.ReactNode;
  curr?: string;
  isPrice?: boolean;
  isFill?: boolean;
  price?: number;
  display?: string;
}) => {
  const { Rts, new_guid } = useRedux();
  return (
    <Rts.RadioCardItem
      w={"100%"}
      label={props.label}
      display={props.display}
      description={
        <Rts.Flex direction={"column"} w="100%" gap={2}>
          {props.descriptionTop}
          {(props.isFill??false) && (
            <Rts.Flex mt={6}>
              
            </Rts.Flex>
          )}
          {(props.isPrice??false) && (
            <Rts.StatRoot>
              <Rts.StatLabel>Cena za dopravné</Rts.StatLabel>
              <Rts.StatValueText
                fontSize={"sm"}
                value={props.price}
                formatOptions={{
                  style: "currency",
                  currency: props.curr ?? "CZK",
                }}
              />
            </Rts.StatRoot>
          )}
          {props.descriptionBottom}
        </Rts.Flex>
      }
      key={new_guid}
      value={props.value}
    />
  );
};

export const InvoiceAddress = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.invoice_type !== "1") {
    return (
      data.invoice_address?.street +
      ", " +
      data.invoice_address?.city +
      ", " +
      data.invoice_address?.postal_code +
      ", " +
      data.invoice_address?.country
    );
  } else {
    return (
      data.customer.street +
      ", " +
      data.customer.city +
      ", " +
      data.customer.postal_code +
      ", " +
      data.customer.country
    );
  }
};

export const InvoiceName = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.invoice_type !== "1") {
    return data.invoice_address?.first_name;
  } else {
    return data.customer.first_name + " " + data.customer.last_name;
  }
};

export const InvoiceICO = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.invoice_type !== "1") {
    return data.invoice_address?.company_id;
  } else {
    return "";
  }
};

export const PickupContact = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.pickup_type !== "1") {
    if (data.pickup_type === "2")
      return data.customer.first_name + " " + data.customer.last_name;
    else if (data.pickup_type === "3") return data.invoice_address?.first_name;
    else return data.pickup_address?.first_name;
  }
};

export const DeliveryContact = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.delivery_type !== "1") {
    if (data.delivery_type === "2")
      return data.customer.first_name + " " + data.customer.last_name;
    else if (data.delivery_type === "4") return PickupContact();
    else if (data.delivery_type === "3")
      return data.invoice_address?.first_name;
    else return data.delivery_address?.first_name;
  }
};

export const PickupCountry = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.pickup_type === null) return "CZ";
  if (data.pickup_type === "1") return "CZ";
  if (data.pickup_type === "2") return data.customer?.country;
  if (data.pickup_type === "3") return data.invoice_address?.country;
  if (data.pickup_type === "4") return data.pickup_address?.country;
  return "CZ";
};

export const PickupAddress = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  
  if (data.pickup_type) {
    if (data.pickup_type === "3") {
      return (
        data.invoice_address?.street +
        ", " +
        data.invoice_address?.city +
        ", " +
        data.invoice_address?.postal_code +
        ", " +
        data.invoice_address?.country
      );
    }
    if (data.pickup_type === "4") {
      return (
        data.pickup_address?.street +
        ", " +
        data.pickup_address?.city +
        ", " +
        data.pickup_address?.postal_code +
        ", " +
        data.pickup_address?.country
      );
    }
    if (data.pickup_type === "1" || data.pickup_type === "2") {
      return (
        data.customer.street +
        ", " +
        data.customer.city +
        ", " +
        data.customer.postal_code +
        ", " +
        data.customer.country
      );
    }
  }
  return "";
};

export const DeliveryAddress = () => {
  const { redux } = useRedux();
  const { store } = redux;
  const data = store.service_request;
  if (data.delivery_type !== "1") {
    if (data.delivery_type === "3") {
      return InvoiceAddress();
    }
    if (data.delivery_type === "5") {
      return (
        data.delivery_address?.street +
        ", " +
        data.delivery_address?.city +
        ", " +
        data.delivery_address?.postal_code +
        ", " +
        data.delivery_address?.country
      );
    }
    if (data.delivery_type === "4") {
      return PickupAddress();
    }
    if (data.delivery_type === "2") {
      return (
        data.customer.street +
        ", " +
        data.customer.city +
        ", " +
        data.customer.postal_code +
        ", " +
        data.customer.country
      );
    }
  }
  return "";
};

export const Beggin = () => {
  const { redux, Rts } = useRedux();
  const { store, IReducer, dispatch } = redux;
  const activeStep = store.service_request.activeStep;
  if (activeStep === -1) {
    return (
      <Rts.AbsoluteCenter mt={"100px"} height={"100vh"} alignContent={"center"}>
        <Rts.Button
          onClick={() => dispatch(IReducer.IServiceRequest.IData.nextStep())}
        >
          Zadat nový požadavek
        </Rts.Button>
      </Rts.AbsoluteCenter>
    );
  }
  return null;
};

export const Stepper = () => {
  const { redux, Rts } = useRedux();
  const { store } = redux;
  const activeStep = store.service_request.activeStep;
  const stepperType = window.innerWidth < 600 ? "vertical" : "horizontal";
  const steps = [
    { title: "Zákazník", description: "" },
    { title: "Zařízení", description: "" },
    { title: "Doprava", description: "" },
    { title: "Souhrn", description: "" },
  ];
  return (
    <Rts.StepsRoot
      orientation={stepperType}
      variant={"subtle"}
      count={steps.length}
      size={"sm"}
      defaultStep={activeStep}
      step={activeStep}
    >
      <Rts.StepsList>
        {steps.map((step, index) => (
          <Rts.StepsItem index={index} title={step.title} />
        ))}
      </Rts.StepsList>
      {steps.map((step, index) => (
        <Rts.StepsContent index={index}>{step.description}</Rts.StepsContent>
      ))}
    </Rts.StepsRoot>
  );
};
