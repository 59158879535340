import { useRedux } from "../redux/shared/redux.provider";
import logo from "../assets/img/logo.png";
import { useDisclosure } from "@chakra-ui/react";
import MarkDown from "react-markdown";
import MarkdownEditor, { selectWord } from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import { useEffect, useState } from "react";
import { sendMessageWithTimeout } from "../redux/shared/redux.signalR";
import { useColorModeValue } from "src/components/ui/color-mode";

export default function HeadIframePage({
  title,
  return_link,
  children,
}: {
  title: string;
  return_link: string;
  children: React.ReactNode;
}) {
  const { Rts } = useRedux();
  const { open, onOpen, onClose } = useDisclosure();
  const [info, setInfo] = useState(true);
  const mode = useColorModeValue("light", "dark");
  const [help, setHelp] = useState("");

  useEffect(() => {
    sendMessageWithTimeout(
      "GetHelp",
      "/webhelp/webhelp/-/raw/main/service_request.md"
    ).then((res) => {
      setHelp(res);
    });
  }, []);

  return (
    <>
      <Rts.DialogRoot open={open} size={"cover"}>
        <Rts.DialogContent>
          <Rts.DialogHeader>
            <Rts.DialogCloseTrigger onClick={onClose} />
          </Rts.DialogHeader>
          <Rts.DialogBody data-color-mode={mode}>
            <MarkdownEditor.Markdown
              style={{ height: "auto", maxHeight: "720px", overflow: "auto" }}
              source={help}
            />
          </Rts.DialogBody>
        </Rts.DialogContent>
      </Rts.DialogRoot>
      <Rts.Container maxW="container.xl">
        <Rts.Center mb={6} mt={6} justifyContent={"space-between"}>
          <Rts.Flex>
            <Rts.Image src={logo} alt="Logo" w="200px" />
          </Rts.Flex>
          <Rts.Flex>
            <Rts.ColorModeButton />
          </Rts.Flex>
        </Rts.Center>
        <Rts.Flex
          w="100%"
          direction={"column"}
          align={"center"}
          mb={{ sm: 6, md: 0 }}
        >
          <Rts.Text fontSize="3xl" fontWeight="bold">
            {title}
          </Rts.Text>
          {info && (
            <Rts.Alert
              status="info"
              fontSize="sm"
              mb={2}
              closable
              onClose={() => setInfo(false)}
            >
              <Rts.Flex w={"100%"} direction={"row"} justify={"space-between"}>
                <Rts.Text fontSize="sm">
                  Spuštěn pilotní provoz. V případě problémů nás kontaktujte na
                  emailu <a href="mailto:it@britex.cz">it@britex.cz</a>. Pokud
                  si nevíte rady co a jak tak klikněte na tlačítko nápověda.
                </Rts.Text>
                <Rts.Button variant={"outline"} onClick={onOpen}>
                  Nápověda
                </Rts.Button>
              </Rts.Flex>
            </Rts.Alert>
          )}
        </Rts.Flex>
        <Rts.Flex mb={4}>
          <Rts.Button
            gap={6}
            variant={"ghost"}
            onClick={() => window.location.replace(return_link)}
          >
            <Rts.Icons.FaArrowLeft />
            Zpět na BRITEX.CZ
          </Rts.Button>
        </Rts.Flex>
        <Rts.Flex direction={"column"}>{children}</Rts.Flex>
      </Rts.Container>
    </>
  );
}
