import { useDisclosure } from "@chakra-ui/react";
import { useRedux } from "../../../redux/shared/redux.provider";
import { useEffect } from "react";
import { AddressTypes } from "src/redux/features/createservicerequest/servicerequest.enums";
import { CustomerAddress } from "src/redux/features/createservicerequest/servicerequest.types";
export default function AddressSelect(props: { type: AddressTypes }) {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer, isLoading } = redux;
  const { open, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (open)
      dispatch(
        IReducer.IServiceRequest.ISignalR({
          type: IReducer.IServiceRequest.ISignalRType
            .ServiceRequestGetMyAddress,
          data: {
            token: store.shared.token_verified,
            type_name:
              props.type === AddressTypes.CUSTOMER
                ? "Zákazník"
                : props.type === AddressTypes.INVOICE
                ? "Fakturační"
                : props.type === AddressTypes.PICKUP
                ? "Svoz"
                : "Doručení",
          },
        })
      );
  }, [open]);

  const __handleSelect = (e: CustomerAddress) => {
    switch (props.type) {
      case AddressTypes.CUSTOMER:
        dispatch(
          IReducer.IServiceRequest.IData.saveAddress({
            ...store.service_request.customer,
            ...e,
          })
        );
        onClose();
        break;
      case AddressTypes.INVOICE:
        dispatch(
          IReducer.IServiceRequest.IData.saveAddress({
            ...store.service_request.invoice_address,
            ...e,
          })
        );
        onClose();
        break;
      case AddressTypes.DELIVERY:
        dispatch(
          IReducer.IServiceRequest.IData.saveAddress({
            ...store.service_request.delivery_address,
            ...e,
            first_name: e.first_name + ' ' + e.last_name
          })
        );
        onClose();
        break;
      case AddressTypes.PICKUP:
        dispatch(
          IReducer.IServiceRequest.IData.saveAddress({
            ...store.service_request.pickup_address,
            ...e,
            first_name: e.first_name + ' ' + e.last_name
          })
        );
        onClose();
        break;
    }
  };

  return (
    <>
      <Rts.DrawerRoot open={open} size={"md"}>
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent>
          <Rts.DrawerHeader justifyContent={"center"}>
            <Rts.Text>Použité adresy</Rts.Text>
            <Rts.DrawerCloseTrigger onClick={onClose} />
          </Rts.DrawerHeader>
          <Rts.DrawerBody>
            <Rts.Flex direction={"column"} gap={2}>
              {isLoading && <Rts.Spinner />}
              {!isLoading && (
                <>
                  {store.service_request.selectable_address.map(
                    (address, idx) => (
                      <Rts.Card.Root
                        key={"sr:addr:" + idx}
                        onClick={() => __handleSelect(address)}
                        title="Vybrat"
                        cursor={"pointer"}
                        _hover={{
                          _dark: { bg: "gray.800" },
                          _light: { bg: "gray.200" },
                        }}
                      >
                        <Rts.Card.Body fontSize={"sm"}>
                          <Rts.Flex direction={"column"}>
                            <Rts.Text fontWeight={"bold"}>
                              {address.first_name} {address.last_name}
                            </Rts.Text>
                            <Rts.Text>
                              {address.street}, {address.city},{" "}
                              {address.postal_code}, {address.country}
                            </Rts.Text>
                            <Rts.Text>{address.phone}</Rts.Text>
                          </Rts.Flex>
                        </Rts.Card.Body>
                      </Rts.Card.Root>
                    )
                  )}
                </>
              )}
            </Rts.Flex>
          </Rts.DrawerBody>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
      <Rts.Flex direction={"row"} justifyContent={"end"} gap={4}>
        <Rts.Button variant={"outline"} onClick={() => onOpen()}>
          <Rts.Flex direction={"row"} align={"center"} gap={4}>
            Vybrat z již použitých adres <Rts.Icons.FaArrowRight />
          </Rts.Flex>
        </Rts.Button>
      </Rts.Flex>
    </>
  );
}
