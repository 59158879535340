export enum FetchTypes {
    GET_CZ_ARES2,
    GET_SK_RUZ,
    GET_IS_PICKUP_DATE,
    GET_FIRST_PICKUP_DATE,
}

export enum signalRTypes {
    SharedGetCountries = "SharedGetCountries",
    SharedGetVendors = "SharedGetVendors",
    SharedGetFirstPickupDate = "SharedGetFirstPickupDate",
    SharedServiceRequestValidateEmail = "SharedServiceRequestValidateEmail",
    SharedServiceRequestValidateVerification = "SharedServiceRequestValidateVerification",
    SharedIsAuthorized = "SharedIsAuthorized",
}