import { useRedux } from "../../../redux/shared/redux.provider";
import { CustomerAddress } from "../../../redux/features/createservicerequest/servicerequest.types";
import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import { Card_type } from "./form-controll";
import React from "react";

export default function AddressDialogIco() {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer, isLoading } = redux;

  const data = store.service_request;
  const open = store.service_request.invoice_company_open;
  const onClose = () =>
    dispatch(IReducer.IServiceRequest.IData.useInvoiceCompany(false));

  const new_address = {
    type: AddressTypes.INVOICE,
    first_name: "",
    last_name: "",
    phone: "",
    street: "",
    city: "",
    postal_code: "",
    country: "CZ",
    company_id: "",
  } as CustomerAddress;

  const __handleSave = () => {
    const api_data = store.shared.api_ico;
    if (api_data) {
      dispatch(
        IReducer.IServiceRequest.IData.saveAddress({
          ...(data.invoice_address ?? new_address),
          country: api_data?.country ?? data.invoice_address?.country ?? "CZ",
          company_id: api_data?.ico ?? "",
          street: api_data?.street ?? "",
          city: api_data?.city ?? "",
          postal_code: api_data?.postal_code ?? "",
          first_name: api_data?.subject ?? "",
          contact_person: api_data?.subject ?? "",
          type: AddressTypes.INVOICE,
        })
      );
    }
    if (data.customer.first_name === "") {
      dispatch(
        IReducer.IServiceRequest.IData.saveAddress({
          ...data.customer,
          last_name: ".",
          street: store.shared.api_ico?.street ?? "",
          city: store.shared.api_ico?.city ?? "",
          postal_code: store.shared.api_ico?.postal_code ?? "",
          first_name: store.shared.api_ico?.subject ?? "",
          type: AddressTypes.CUSTOMER,
        })
      );
    }

    onClose();
  };

  const __handleChangeCountry = (e: any) => {
    e.stopPropagation();
    dispatch(
      IReducer.IServiceRequest.IData.saveAddress({
        ...(data.invoice_address ?? new_address),
        country: e.target.value,
        type: AddressTypes.INVOICE,
      })
    );
  };

  const __handleFetch = (e: any) => {
    dispatch(
      IReducer.IServiceRequest.IData.saveAddress({
        ...(data.invoice_address ?? new_address),
        company_id: e.target.value,
        first_name: "",
        type: AddressTypes.INVOICE,
      })
    );
    if (e.target.value.indexOf("_") === -1) {
      dispatch(
        IReducer.IShared.IFetch({
          type:
            data.invoice_address?.country === "CZ"
              ? IReducer.IShared.IFetchTypes.GET_CZ_ARES2
              : IReducer.IShared.IFetchTypes.GET_SK_RUZ,
          data: { ico: e.target.value },
        }),
        true
      );
    }
  };

  return (
    <Rts.DialogRoot size={"sm"} open={open}>
      <Rts.DialogContent>
        <Rts.DialogHeader>
          <Rts.DialogTitle>Vyhledat podle IČO</Rts.DialogTitle>
          <Rts.DialogCloseTrigger onClick={onClose} />
        </Rts.DialogHeader>
        <Rts.DialogBody>
          <Rts.RadioCardRoot
            w={"100%"}
            value={data.invoice_address?.country}
            onChangeCapture={__handleChangeCountry}
          >
            <Rts.Flex direction={"column"} w={"100%"} gap={4}>
              <Card_type
                label={"Česká republika"}
                descriptionTop={"Vyhledávání v systému ARES"}
                value={"CZ"}
                isPrice={false}
              />
              <Card_type
                label={"Slovenská republika"}
                descriptionTop={"Vyhledávání v systému RUZ"}
                value={"SK"}
                isPrice={false}
              />
            </Rts.Flex>
          </Rts.RadioCardRoot>
          <Rts.Field mt={4} label="IČO">
            {" "}
            <Rts.InputMask
              mask={"99999999"}
              placeholder="IČO"
              value={data.invoice_address?.company_id}
              onChange={(e: any) => __handleFetch(e)}
            />
          </Rts.Field>
          {isLoading && (
            <Rts.Center mt={4}>
              <Rts.Spinner />
            </Rts.Center>
          )}
          {!isLoading && (
            <Rts.RadioCardRoot
              mt={4}
              value={data.invoice_address?.first_name}
              onChangeCapture={__handleSave}
            >
              <Rts.Flex direction={"column"} w={"100%"} gap={4}>
                {store.shared.api_ico?.subject &&
                  data.invoice_address?.company_id.indexOf("_") === -1 && (
                    <Card_type
                      label={store.shared.api_ico?.subject ?? ""}
                      descriptionTop={
                        <>
                          <Rts.Alert status={"info"}>
                            Fakturační adresu vložíte do požadavku kliknutím na
                            tento záznam.
                          </Rts.Alert>
                          <Rts.Text fontSize={"xs"}>
                            {store.shared.api_ico?.street}
                          </Rts.Text>
                          <Rts.Text fontSize={"xs"}>
                            {store.shared.api_ico?.city},{" "}
                            {store.shared.api_ico?.postal_code},{" "}
                            {store.shared.api_ico?.country}
                          </Rts.Text>
                        </>
                      }
                      value={store.shared.api_ico?.subject ?? "-1"}
                      isPrice={false}
                    />
                  )}
              </Rts.Flex>
            </Rts.RadioCardRoot>
          )}
        </Rts.DialogBody>
        <Rts.DialogFooter></Rts.DialogFooter>
      </Rts.DialogContent>
    </Rts.DialogRoot>
  );
}
