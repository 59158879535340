import { FaCogs, FaFileContract, FaHome, FaBoxOpen } from "react-icons/fa";
import interfaceObjectRoute from "./interfaces/layouts/interfaceObjectRoute";

import StatePage from "./views/state";
import Config from "./views/config/Config";
import ServiceRequest from "./views/serviceRequest";
import SoPreview from "./views/serviceOrder/so.preview";
import SoPriceProposal from "./views/serviceOrder/components/so.detail.priceproposal";
import LPPreview from "./views/logisticsPackages/lp.preview";

const dashRoutes: Array<interfaceObjectRoute> = [
  {
    path: "/",
    name: "Servisní zakázky",
    icon: <FaHome color="inherit" />,
    component: <SoPreview />,
    layout: "",
    sidebar: false,
  },
  {
    path: "/priceproposal/:id",
    name: "Vytvořit cenovou nabídku",
    icon: <FaHome color="inherit" />,
    component: <SoPriceProposal />,
    layout: "",
    sidebar: false,
  },
  {
    path: "/serviceorders",
    name: "Servisní zakázky",
    icon: <FaFileContract color="inherit" />,
    component: <SoPreview />,
    layout: "",
    sidebar: true,
  },
  {
    path: "/create/service/request",
    name: "Zásilky",
    icon: <FaFileContract color="inherit" />,
    component: <ServiceRequest />,
    layout: "",
    sidebar: true,
  },
  {
    path: "/packages",
    name: "Balíky",
    icon: <FaBoxOpen color="inherit" />,
    component: <LPPreview />,
    layout: "",
    sidebar: true,
  },
  {
    path: "/settings",
    name: "Nastavení",
    icon: <FaCogs color="inherit" />,
    component: <Config />,
    layout: "",
    sidebar: true,
  },
];

export default dashRoutes;
