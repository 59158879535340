import { useRedux } from "../../../redux/shared/redux.provider";

export default function SoDetailItems() {
  const { Rts } = useRedux();
  return (
    <Rts.Flex w={"100%"} direction={"column"} gap={2}>
      <Rts.Button variant={"subtle"} aria-label="Přidat položku" gap={2} alignContent={"center"}>
        <Rts.Icons.LuPlus /> Přidat položku
      </Rts.Button>
      <Rts.Card.Root w={"100%"}>
        <Rts.Card.Body>
          <Rts.Flex gap={2} direction={"row"} justifyContent={"space-between"}>
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 123456
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>ASSY COVER BACK MIDNIGHT BLACK</Rts.Text>
            </Rts.Flex>

            {/* <Rts.INumberInput
              value={0}
              onSave={() => {}}
              isLoading={false}
              idx={0}
              isDisabled={false}
            /> */}
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <Rts.Card.Root w={"100%"}>
        <Rts.Card.Body>
          <Rts.Flex
            gap={2}
            direction={"row"}
            justifyContent={"space-between"}
            align={"center"}
          >
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 7ad7as7
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>
                ASSY 7ad74sa 8d4as8 COVER BACK MIDNIGHT BLACK
              </Rts.Text>
              <Rts.Flex gap={2} direction={"row"} align={"center"}>
                <Rts.Text fontSize={"sm"} mt={1}>Sklad:</Rts.Text>
                {/* <Rts.Editable
                  mt={1}
                  defaultValue="3001"
                  size={"sm"}
                  fontSize={"sm"}
                >
                  <Rts.EditablePreview />
                  <Rts.EditableInput />
                </Rts.Editable> */}
                <Rts.Text ms={6} fontSize={"sm"} mt={1}>Počet kusů:</Rts.Text>
                {/* <Rts.Editable
                  mt={1}
                  defaultValue="1"
                  size={"sm"}
                  fontSize={"sm"}
                >
                  <Rts.EditablePreview />
                  <Rts.EditableInput />
                </Rts.Editable> */}
              </Rts.Flex>
            </Rts.Flex>
            <Rts.Flex gap={2} direction={"row"} align={"center"}>
              <Rts.Button
                size={"sm"}
                aria-label="Historie"
              />
              <Rts.Button
                size={"sm"}
                aria-label="Odebrat"
                bg={"red.500"}
              />
            </Rts.Flex>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <Rts.Card.Root w={"100%"}>
        <Rts.Card.Body>
          <Rts.Flex gap={2} direction={"row"} justifyContent={"space-between"}>
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 484d8s48
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>
                ASSY COVER 94sa84d 484as8 BACK MIDNIGHT BLACK
              </Rts.Text>
            </Rts.Flex>

            {/* <Rts.INumberInput
              value={0}
              onSave={() => {}}
              isLoading={false}
              idx={0}
              isDisabled={false}
            /> */}
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </Rts.Flex>
  );
}
