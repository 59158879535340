
export enum FetchTypes {
    GET_TRANSPORT,
    POST_CREATE_SERVICE_ORDER,
    VALIDATE,
    VALIDATE_RESULT,
    GET_VENDORS,
    POST_FILE
}

export enum AddressTypes {
    DELIVERY,
    INVOICE,
    PICKUP,
    CUSTOMER
}