import {
  Box,
  Link,
  Text,
  Circle,
  Float,
  Badge,
  Card,
  Input,
  Image,
  Spinner,
  Textarea,
  Container,
  Center,
  AbsoluteCenter,
  Tabs,
  Fieldset,
  createListCollection,
  Grid, 
  GridItem,
  chakra,
  HStack,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Code,
  Accordion,
  AccordionItem,
  CheckboxGroup,
  Drawer,
  NumberInput,
  SimpleGrid,
  TagLabel,
  Select,
  Stat,
  StatHelpText,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import * as fa from "react-icons/fa";
import * as io from "react-icons/io";
import * as ai from "react-icons/ai";
import * as md from "react-icons/md";
import * as lu from "react-icons/lu";
import SCard from "./components/card";
import SButton from "./components/button";
import SButtonColorMode from "./components/button.colormode";
import SInput from "./components/input";
import { Separator } from "./components/separator";
import { ReactSortable } from "react-sortablejs";
import XDropzone from "./components/dropzone";
import ITextarea from "./components/textarea";
import MarkDownEditor from "./components/markdown.editor";
import { ReactSVG } from "react-svg";
import Navigations from "./components/navigations";
import CssInterfaces from "../../layouts/theme/theme.iterfaces";
import {
  useColorMode,
  useColorModeValue,
  ColorModeButton,
} from "../../components/ui/color-mode";
import {
  RadioCardItem,
  RadioCardLabel,
  RadioCardRoot,
} from "../../components/ui/radio-card";
import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
  DrawerTrigger,
  DrawerSideContent,
} from "../../components/ui/drawer";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "../../components/ui/menu";
import { SegmentedControl } from "../../components/ui/segmented-control";
import { Button } from "../../components/ui/button";
import { Tooltip } from "../../components/ui/tooltip";
import { InfoTip, ToggleTip } from "../../components/ui/toggle-tip";
import { Avatar, AvatarGroup } from "../../components/ui/avatar";
import { Field } from "../../components/ui/field";
import {
  BreadcrumbCurrentLink,
  BreadcrumbLink,
  BreadcrumbRoot,
} from "../../components/ui/breadcrumb";
import {
  ActionBarCloseTrigger,
  ActionBarContent,
  ActionBarRoot,
  ActionBarSelectionTrigger,
  ActionBarSeparator,
} from "../../components/ui/action-bar";
import { Status } from "../../components/ui/status";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "../../components/ui/skeleton";
import { InputGroup } from "../../components/ui/input-group";
import {
  StepsCompletedContent,
  StepsContent,
  StepsItem,
  StepsList,
  StepsNextTrigger,
  StepsPrevTrigger,
  StepsRoot,
} from "../../components/ui/steps";
import { Alert } from "../../components/ui/alert";
import {
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "../../components/ui/select";
import { Checkbox } from "../../components/ui/checkbox";
import { Tag } from "../../components/ui/tag";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "../../components/ui/hover-card";
import {
  FileUploadDropzone,
  FileUploadList,
  FileUploadRoot,
} from "../../components/ui/file-button";
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineRoot,
  TimelineTitle,
} from "../../components/ui/timeline";
import { Switch } from "../../components/ui/switch";
import { NumberInputField, NumberInputRoot } from "../../components/ui/number-input";
import { PinInput } from "../../components/ui/pin-input";
import { RootProvider as DatePicker } from "../../components/ui/date-picker";
import { RootProvider as SignaturePad } from "../../components/ui/signature-pad";
import { StatLabel, StatRoot, StatValueText } from "../../components/ui/stat"
const S = {
  Stack,
  RadioGroup,
  Navigations,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  PinInput,
  HStack,
  ReactSVG,
  MarkDownEditor,
  AbsoluteCenter,
  Center,
  Avatar,
  Tooltip,
  Container,
  Flex,
  Code,
  Stat,
  Image,
  Textarea: ITextarea,
  StatHelpText,
  Tag,
  TagLabel,
  Card: SCard,
  CardBody,
  CardFooter,
  CardHeader,
  Drawer,
  Separator,
  Accordion,
  AccordionItem,
  ReactSortable,
  SimpleGrid,
  Checkbox,
  CheckboxGroup,
  Grid,
  XDropzone,
  Input,
  SInput,
  NumberInput,
  Tabs,
  Switch,
  Button: SButton,
  SButtonColorMode,
  Spinner,
  Select,
  Icon: {
    ...fa,
    ...io,
    ...ai,
    ...md,
    ...lu,
  },
};
export {
  Alert,
  Box,
  Badge,
  Card,
  Avatar,
  AvatarGroup,
  Flex,
  Button,
  DrawerRoot,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseTrigger,
  DrawerBackdrop,
  DrawerTrigger,
  DrawerTitle,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  Tooltip,
  InfoTip,
  ToggleTip,
  BreadcrumbCurrentLink,
  BreadcrumbLink,
  BreadcrumbRoot,
  Link,
  Text,
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
  useColorMode,
  useColorModeValue,
  ColorModeButton,
  SegmentedControl,
  RadioCardItem,
  RadioCardLabel,
  RadioCardRoot,
  ActionBarCloseTrigger,
  ActionBarContent,
  ActionBarRoot,
  ActionBarSelectionTrigger,
  ActionBarSeparator,
  TimelineItem,
  TimelineRoot,
  Status,
  Circle,
  Float,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Input,
  InputGroup,
  Image,
  StepsCompletedContent,
  StepsContent,
  StepsItem,
  StepsList,
  StepsNextTrigger,
  StepsPrevTrigger,
  StepsRoot,
  Spinner,
  Textarea,
  Container,
  Center,
  AbsoluteCenter,
  Tabs,
  Field,
  Fieldset,
  createListCollection,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SInput,
  Checkbox,
  Tag,
  Navigations,
  CssInterfaces,
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
  DrawerSideContent,
  Grid, 
  GridItem,
  FileUploadDropzone,
  FileUploadList,
  FileUploadRoot,
  DialogActionTrigger,
  TimelineConnector,
  TimelineContent,
  TimelineTitle,
  Switch,
  NumberInputField, 
  NumberInputRoot,
  PinInput,
  DatePicker,
  SignaturePad,
  StatLabel, 
  StatRoot, 
  StatValueText,
  SimpleGrid,
};
export default S;
