import { FetchLogisticsPackages, reducers } from "./logistics_packages.slice";
import { FetchTypes } from "./logistics_packages.enums";

export interface ILogisticsPackages {
 
}

export const ILogisticsPackagesReducers = reducers;
export const IELogisticsPackagesfetchType = FetchTypes;
export const ILogisticsPackagesFetch = FetchLogisticsPackages;
