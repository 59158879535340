import { createAsyncThunk } from "@reduxjs/toolkit";
import Fetch from "../../shared/redux.fetch";
import { FetchTypes } from "./logistics_packages.enums";
import Endpoints from "./logistics_packages.endpoints";
import { endpoint, fileDropZone } from "../../shared/redux.types";

type FetchData = {
  type: FetchTypes;
  data?: { email?: string; verification_code?: string; pin?: number, file?: fileDropZone, data?: any };
};

export const FetchData = createAsyncThunk<
  string[],
  FetchData,
  { rejectValue: string }
>("servicerequest/fetchData", async (_, thunkAPI) => {
  try {
    const result = await Fetch(DispatchFetch(_));
    return result;
  } catch (error) {
    return thunkAPI.rejectWithValue("Nepodařilo");
  }
});

const DispatchFetch = (_: FetchData): endpoint => {
  switch (_.type) {
    case FetchTypes.VALIDATE:
      return Endpoints.POST_VALIDATE({ email: _.data?.email! });
    case FetchTypes.GET_VENDORS:
      return Endpoints.GET_VENDORS;
    case FetchTypes.VALIDATE_RESULT:
      return Endpoints.POST_VALIDATE_RESULT({
        verification_code: _.data?.verification_code!,
        pin: _.data?.pin!,
      });
    case FetchTypes.POST_FILE:
      return Endpoints.POST_FILE(_.data!.file!);
    case FetchTypes.POST_CREATE_SERVICE_ORDER:
      return Endpoints.POST_SERVICE_REQUEST(_.data?.data!);
    default:
      return Endpoints.POST_VALIDATE({ email: _.data?.email! });
  }
};
