import { EndpointMethod } from "../../shared/redux.enums";
import { endpoint } from "../../shared/redux.types";

const Endpoints = {
  GET_CZ_ARES2: (ico: string) => {
    return {
      method: EndpointMethod.GET,
      ep: `/ExternalServices/api/Ares/GetCompamnyInfo/${ico}`,
    } as endpoint;
  },
  GET_SK_RUZ: (ico: string) => {
    return {
      method: EndpointMethod.GET,
      ep: `/ExternalServices/api/Ruz/GetCompamnyInfo/${ico}`,
    } as endpoint;
  },
  GET_IS_PICKUP_DATE: (countryCode: string, date: string) => {
    return {
      method: EndpointMethod.GET,
      ep: `/ExternalServices/api/Nager/isPickupDate/${countryCode}/${date}`,
    } as endpoint;
  },
  GET_FIRST_PICKUP_DATE: (countryCode: string) => {
    return {
      method: EndpointMethod.GET,
      ep: `/ExternalServices/api/Nager/firstPickupDate/${countryCode}`,
    } as endpoint;
  },
};

export default Endpoints;
