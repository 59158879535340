import { User } from "./users.types";
import { responseModel } from "../../shared/redux.types";
import { UsersReducers, fetchUser } from "./users.slice";
import { FetchTypes } from "./users.enums";

export interface IUser extends responseModel<User[]> {
  logged: User | null;
  isAuth: boolean;
}

export const IUsersReducers = UsersReducers;
export const IEUsersfetchType = FetchTypes;
export const IFetchUser = fetchUser;
