import { useRedux } from "../../redux/shared/redux.provider";

export default function IconBox(props: any) {
  const { children } = props;
  const { Rts } = useRedux();
  return (
    <Rts.Flex
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={"12px"}
    >
      {children}
    </Rts.Flex>
  );
}
