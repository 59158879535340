import { useDisclosure } from "@chakra-ui/react";
import { useRedux } from "../../redux/shared/redux.provider";
import SoDetail from "./so.detail";

export default function SoItem(props: { item: any }) {
  const { Rts } = useRedux();
  const { onOpen, onClose, open } = useDisclosure();
  const { item } = props;
  return (
    <>
      <SoDetail isOpen={open} onClose={onClose} />
      <Rts.Card.Root variant={"subtle"} css={Rts.CssInterfaces.card.hover} size={"sm"} onClick={onOpen}>
        <Rts.Card.Body>
          <Rts.Flex
            direction={"row"}
            gap={4}
            align={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Rts.Checkbox />
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                {item.id}
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>{item.manager}</Rts.Text>
            </Rts.Flex>
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
                {item.vendor}
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>{item.dev_type}</Rts.Text>
            </Rts.Flex>
            <Rts.Flex direction={"column"}>
              <Rts.Text fontSize={"sm"}>{item.dev_repair_type}</Rts.Text>
              <Rts.Text fontSize={"xs"}>
                {new Date().toLocaleDateString()}
              </Rts.Text>
            </Rts.Flex>
            <Rts.Icons.FaArrowRight />
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </>
  );
}
