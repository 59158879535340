import { FetchServiceRequest, reducers } from "./servicerequest.slice";
import { ServiceRequest } from "./servicerequest.types";
import { FetchTypes, signalRTypes } from "./servicerequest.enums";
import { SignalR } from './servicerequest.signalr';

export interface IServiceRequest extends ServiceRequest {
  is_signature: boolean;
  is_veryfied: boolean;
  pin_no: number;
  token_for_verify: string;
  token_verified: string;
  count_invalid_pin: number;
  all_is_ok: boolean;
  is_send: boolean;
  vendors: {
    value: string;
    label: string;
  }[];
  device_edit: {
    is_open: boolean;
    idx: number;
  };
}

export const IServiceRequestReducers = reducers;
export const IEServiceRequestfetchType = FetchTypes;
export const IServiceRequestFetch = FetchServiceRequest;
export const SRSignalR = SignalR;
export const SRSignalRType = signalRTypes;
