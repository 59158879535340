import InterfaceSidebarprops from "../../interfaces/layouts/sidebar/interfaceSidebarprops";
import {
  Button,
  Flex,
  Stack,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";
import IconBox from "../Icons/IconBox";
import { NavLink, useLocation } from "react-router-dom";
import interfaceObjectRoute from "../../interfaces/layouts/interfaceObjectRoute";
import rts_v from "../../assets/img/rts_v.png";
import { Separator } from "../../redux/shared/components/separator";
import { useRedux } from "../../redux/shared/redux.provider";

const SidebarContent: React.FC<InterfaceSidebarprops> = ({ routes }) => {
  let location = useLocation();
  const { Rts, redux } = useRedux();
  const { store } = redux;
  const core = store.core;
  const app_logo = Rts.useColorModeValue(rts_v, rts_v);
  const activeBg = Rts.useColorModeValue("bg.panel", "bg.panel");
  const inactiveBg = Rts.useColorModeValue("white", "gray.700");
  const activeColor = Rts.useColorModeValue("gray.700", "white");
  const inactiveColor = Rts.useColorModeValue("gray.400", "gray.400");
  const iconColor = Rts.useColorModeValue("gray.50", "gray.50");
  const iconColorHover = Rts.useColorModeValue("bg.panel", "bg.panel");
  const iconColorActive = Rts.useColorModeValue("gray.50", "gray.50");

  const activeRoute = (routeName: string) => {
    return location.pathname === routeName ? "active" : "";
  };
  const createLinks = (routes?: interfaceObjectRoute[]) => {
    return routes?.map((prop, key) => {
      if (!prop.sidebar) return;
      if (prop.category) {
        return (
          <div key={prop?.name ?? ""}>
            <Rts.Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "12px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {prop?.name ?? ""}
            </Rts.Text>
            {createLinks(prop?.views ?? [])}
          </div>
        );
      }
      return (
        <NavLink to={prop.path ?? ""} key={prop.name}>
          {activeRoute(prop.path ?? "") === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover={{}}
              color={activeColor}
              w="100%"
              _active={{
                bg: iconColorActive,
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex gap={2}>
                {typeof prop.icon === "string" ? (
                  <div>{prop.icon}</div>
                ) : (
                  <IconBox
                    bg={iconColor}
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px"
                    _hover={{
                      bg: iconColorHover,
                    }}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.name
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover={{
                backgroundColor: iconColorHover,
              }}
              color={inactiveColor}
              w="100%"
              _active={{
                bg: iconColorActive,
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex gap={2}>
                {typeof prop.icon === "string" ? (
                  <div>{prop.icon}</div>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color={iconColor}
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my="auto" fontSize="sm">
                  {prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

  const links = <>{createLinks(routes ?? [])}</>;

  const skeletonLink = (
    <Skeleton
      boxSize="initial"
      justifyContent="flex-start"
      alignItems="center"
      mb={{
        xl: "12px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      py="12px"
      borderRadius="15px"
      w="100%"
      h={"55px"}
    ></Skeleton>
  );

  function SideBarShower() {
    if (routes.length > 0) {
      return links;
    } else {
      return (
        <>
          {skeletonLink}
          {skeletonLink}
          {skeletonLink}
        </>
      );
    }
  }

  return (
    <>
      <Rts.Box pt={"25px"} mb="12px">
        <Rts.Link
          href={`${process.env.PUBLIC_URL}`}
          target="_blank"
          display="flex"
          lineHeight="100%"
          mb="30px"
          fontWeight="bold"
          justifyContent="center"
          alignItems="center"
          fontSize="11px"
        >
          <Rts.Image src={app_logo} />
        </Rts.Link>
        <Separator></Separator>
      </Rts.Box>
      <Stack direction="column" mb="40px">
        <Rts.Box>
          <SideBarShower />
        </Rts.Box>
      </Stack>
    </>
  );
};

export default SidebarContent;
