import { useRedux } from "../../../redux/shared/redux.provider";
import Address from "./address-form";
import { CustomerAddress } from "../../../redux/features/createservicerequest/servicerequest.types";
import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import React from "react";

export default function AddressDialog() {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer } = redux;
  const data = store.service_request;
  const props = store.service_request.address_dialog;
  const onClose = () =>
    dispatch(
      IReducer.IServiceRequest.IData.setAddressDialog({
        open: false,
        isPickup: false,
      })
    );
  const new_address = {
    type: props.isPickup
      ? AddressTypes.PICKUP
      : props.isInvoice ?? false
      ? AddressTypes.INVOICE
      : AddressTypes.DELIVERY,
    first_name: "",
    last_name: "",
    phone: "",
    street: "",
    city: "",
    postal_code: "",
    country: "",
    company_id: "",
  } as CustomerAddress;
  const __handleSave = () => onClose();
  return (
    <Rts.DialogRoot size={"xl"} open={props.open} onEscapeKeyDown={onClose}>
      <Rts.DialogContent>
        <Rts.DialogHeader>
          <Rts.DialogTitle>Nová Adresa</Rts.DialogTitle>
          <Rts.DialogCloseTrigger onClick={onClose} />
        </Rts.DialogHeader>
        <Rts.DialogBody>
          <Address
            data={
              props.isPickup
                ? data.pickup_address ?? new_address
                : props.isInvoice ?? false
                ? data.invoice_address ?? new_address
                : data.delivery_address ?? new_address
            }
          />
        </Rts.DialogBody>
        <Rts.DialogFooter>
          <Rts.Button onClick={onClose} variant={"ghost"}>
            Zavřít
          </Rts.Button>
          <Rts.Button variant={"subtle"} onClick={__handleSave}>
            Uložit
          </Rts.Button>
        </Rts.DialogFooter>
      </Rts.DialogContent>
    </Rts.DialogRoot>
  );
}
