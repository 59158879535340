import { createAsyncThunk } from "@reduxjs/toolkit";
import Fetch, { Endpoints } from "../../shared/redux.fetch";
import { FetchTypes } from "./users.enums";

export const FetchData = createAsyncThunk<
  string[],
  {
    type: FetchTypes;
    data: {
      code?: string;
      userName?: string;
      password?: string;
      accessToken?: string;
    };
  },
  { rejectValue: string }
>("users/fetchData", async (_, thunkAPI) => {
  try {
    var result = await Fetch(DispatchFetch(_), _.data.accessToken, _.data);
    return Promise.resolve(result);
  } catch (error: any) {
    thunkAPI.rejectWithValue(error);
    return Promise.reject(error);
  }
});

const DispatchFetch = (_: {
  type: FetchTypes;
  data: { code?: string; userName?: string; password?: string };
}) => {
  switch (_.type) {
    case FetchTypes.POST_LOGIN_IFRAME:
      return Endpoints.USER.LOGIN_BY_IFRAME;
    case FetchTypes.GET_LOGIN_OAUTH:
      return Endpoints.USER.LOGIN_BY_CODE(_.data?.code ?? "");
    case FetchTypes.GET_USER_DETAIL:
      return Endpoints.USER.USER_DETAIL();
  }
};
 