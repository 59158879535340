import { Shared } from "./shared.types";
import { SharedReducers, fetchShared, SISignalR, SISignalRType } from "./shared.slice";
import { FetchTypes, signalRTypes } from "./shared.enums";

export interface IShared extends Shared {}

export const ISharedReducers = SharedReducers;
export const IESharedfetchType = FetchTypes;
export const ISignalRType = SISignalRType;
export const ISignalR = SISignalR;
export const IFetchShared = fetchShared;
