import { EndpointMethod } from "../../redux.enums";
import { endpoint } from "../../redux.types";

export const STATE = {
  GET_STATE: () => {
    return {
      method: EndpointMethod.GET,
      ep: `/health`,
    } as endpoint;
  },
  REBOOT: () => {
    return {
      method: EndpointMethod.GET,
      ep: `/api/admin/reboot`,
    } as endpoint;
  },
};
