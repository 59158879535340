import { defineSlotRecipe, RecipeVariantProps } from "@chakra-ui/react";
import { radioCardAnatomy } from "@chakra-ui/react/anatomy";

export const radioCardSlotRecipe = defineSlotRecipe({
  className: "chakra-radio-card",
  slots: radioCardAnatomy.keys(),
  base: {
    item: {
      borderRadius: "15px",
    }
  },
});

type RadioCardVariantProps = RecipeVariantProps<typeof radioCardSlotRecipe>;
export interface CardProps
  extends React.PropsWithChildren<RadioCardVariantProps> {}
