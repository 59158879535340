import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import { CustomerAddress } from "../../../redux/features/createservicerequest/servicerequest.types";
import { useRedux } from "../../../redux/shared/redux.provider";
import AddressSelect from "./address-select";
import { Card_type } from "./form-controll";

function Address({ data }: { data: CustomerAddress }) {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer } = redux;

  const handleAddress = (e: any) =>
    dispatch(IReducer.IServiceRequest.IData.saveAddress(e));

  return (
    <>
      <Rts.Flex direction={"column"} gap={6} mb={2}>
        <AddressSelect type={data.type}/>
        <Rts.Field label="Stát">
          <Rts.RadioCardRoot
            w={"100%"}
            value={data.country}
            onChangeCapture={(e: any) =>
              handleAddress({ ...data, country: e.target.value })
            }
          >
            <Rts.Flex direction={"row"} w={"100%"} gap={4}>
              <Card_type
                label={"Česká republika"}
                value={"CZ"}
                isPrice={false}
              />
              <Card_type
                label={"Slovenská republika"}
                value={"SK"}
                isPrice={false}
              />
            </Rts.Flex>
          </Rts.RadioCardRoot>
        </Rts.Field>
        <Rts.Flex direction={{ base: "column", md: "row" }} gap={4}>
          {(data.type === AddressTypes.CUSTOMER ||
            data.type === AddressTypes.INVOICE) && (
            <Rts.Field label="Jméno" required>
              <Rts.Input
                placeholder="Jméno"
                autoFocus
                value={data.first_name}
                onChange={(e) =>
                  handleAddress({ ...data, first_name: e.target.value })
                }
              />
            </Rts.Field>
          )}

          {(data.type === AddressTypes.CUSTOMER ||
            data.type === AddressTypes.INVOICE) && (
            <Rts.Field label="Příjmení" required>
              <Rts.Input
                placeholder="Příjmení"
                value={data.last_name}
                onChange={(e) =>
                  handleAddress({ ...data, last_name: e.target.value })
                }
              />
            </Rts.Field>
          )}
          {(data.type === AddressTypes.PICKUP ||
            data.type === AddressTypes.DELIVERY) && (
            <Rts.Field label="Kontaktní osoba" required>
              <Rts.Input
                placeholder="Kontaktní osoba"
                value={data.first_name}
                onChange={(e) =>
                  handleAddress({ ...data, first_name: e.target.value })
                }
              />
            </Rts.Field>
          )}
          <Rts.Field label="Mobil" required>
            <Rts.InputMask
              mask={
                data.country
                  ? data.country === "CZ"
                    ? "+420 999 999 999"
                    : "+421 999 999 999"
                  : "+429 999 999 999"
              }
              placeholder="Mobil"
              value={data.phone}
              onChange={(e: any) =>
                handleAddress({ ...data, phone: e.target.value })
              }
            />
          </Rts.Field>
        </Rts.Flex>
        <Rts.Flex direction={{ base: "column", md: "row" }} gap={4}>
          <Rts.Field label="Ulice a ČP" required>
            <Rts.Input
              placeholder="Ulice a ČP"
              value={data.street}
              onChange={(e) =>
                handleAddress({ ...data, street: e.target.value })
              }
            />
          </Rts.Field>
          <Rts.Field label="Město" required>
            <Rts.Input
              placeholder="Město"
              value={data.city}
              onChange={(e) => handleAddress({ ...data, city: e.target.value })}
            />
          </Rts.Field>
          <Rts.Field label="PSČ" w={"40%"} required>
            <Rts.InputMask
              w="100"
              mask="999 99"
              value={data.postal_code}
              onChange={(e: any) =>
                handleAddress({ ...data, postal_code: e.target.value })
              }
            />
          </Rts.Field>
        </Rts.Flex>
      </Rts.Flex>
    </>
  );
}

export default Address;
