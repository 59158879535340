import { useEffect } from "react";
import { useRedux } from "../../redux/shared/redux.provider";
import HeadIframePage from "../head.iframe.page";
import Interface from "./interfaces/interface.components";
import undraw from "../../assets/img/undraw_devices_re_dxae.svg";
import PosamSk from "./extenders/posam.sk";

export default function CreateServiceRequest() {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer } = redux;
  const activeStep = store.service_request.activeStep;
  const verify = localStorage.getItem("token_verified") ?? "";
  useEffect(() => {
    dispatch(
      IReducer.IShared.ISignalR({
        type: IReducer.IShared.ISignalRType.SharedGetFirstPickupDate,
        data: { countryCode: "CZ" },
      })
    );

    if (verify != "" && store.service_request.activeStep === -1) {
      dispatch(
        IReducer.IShared.ISignalR({
          type: IReducer.IShared.ISignalRType.SharedIsAuthorized,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (store.shared.is_authorized && store.service_request.activeStep === -1) {
      dispatch(IReducer.IServiceRequest.IData.nextStep());
      dispatch(IReducer.IServiceRequest.IData.setEmail(store.shared.e_mail));
    }
  }, [store.shared.is_authorized]);

  useEffect(() => {}, [store.service_request.invoice_address]);

  return (
    <HeadIframePage
      title="Vytvořit nový požadavek na opravu a svoz zařízení"
      return_link="https://www.britexcz.cz/"
    >
      <Rts.Image
        src={undraw}
        bg={"transparent"}
        position={"fixed"}
        bottom={5}
        right={0}
        width={250}
      />
      {/* Zobrazujeme stepper a ovládací tlačítka až když se zadává nový požadavek */}
      {activeStep > -1 && !store.service_request.is_send && (
        <>
          <Interface.Stepper />
          <Interface.ControllerForm />
        </>
      )}
      {store.service_request.is_send && <Interface.Finish />}
      {!store.service_request.is_send && (
        <>
          <Interface.AddressDialogIco />
          <Interface.AddressDialog />
          <Interface.DeviceEditor />
          <Rts.Flex direction={"column"} mt={6} gap={4}>
            {activeStep === -1 && <Interface.Verification />}
            {activeStep === 0 && <Interface.Card />}
            {activeStep === 0 && <PosamSk />}
            {activeStep === 1 && <Interface.Device />}
            {activeStep === 2 && <Interface.Transport />}
            {activeStep === 3 && <Interface.Summary />}
            {activeStep > -1 && <Interface.ControllerForm />}
          </Rts.Flex>
        </>
      )}
    </HeadIframePage>
  );
}
