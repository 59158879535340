import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IConfig } from "./config.interface";
import { FetchAlive } from "./config.slice";
import { CoreMessage } from "./config.types";
import { FetchTypes } from "./config.enums";

export const IntialReducer: IConfig = {};

export const ExtraReducer = (builder: ActionReducerMapBuilder<IConfig>) => {
  builder.addCase(FetchAlive.rejected, (state, action) => {});
  builder.addCase(FetchAlive.fulfilled, (state, action) => {
    if (action.meta.arg.type === FetchTypes.FULLCONFIG) {
      state.config = action.payload;
    }
  });
};
