import { endpoint } from "../redux.types";

export const ALIVE = {
    method: "GET",
    ep: "/health"
} as endpoint

export const CONFIG = {
    method: "GET",
    ep: "/crossui.config"
} as endpoint