import { createSlice } from "@reduxjs/toolkit";
import {  ExtraReducer, IntialReducer } from "./config.reducers";
import { FetchData } from "./config.fetcher";

const initialState = IntialReducer;

const CoreSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    
  },
  extraReducers: ExtraReducer 
});

export default CoreSlice.reducer;

export const reducers = CoreSlice.actions;

export const FetchAlive = FetchData;