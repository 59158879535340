import { ALIVE, CONFIG } from "./redux.core";
import { STATE } from "./state/redux.state";
import { USER } from "./users/redux.users";
import { HELPDESK } from "./helpdesk/redux.helpdesk";
import { CONFIG as CFG } from "./config/redux.config";

export const API = {
  USER: USER,
  ALIVE: ALIVE,
  CONFIG: CONFIG,
  STATE: STATE,
  HELPDESK: HELPDESK,
  FULLCONFIG: CFG,
  //TODO: Další akce...
};
