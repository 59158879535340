import { Button, Flex, Menu, MenuItem, Badge } from "@chakra-ui/react";
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import NavbarAlerts from "./navbarAlerts";
import routes from "../../rts.routes";
import { FaCog, FaLock, FaPowerOff, FaUser } from "react-icons/fa";
import { useRedux } from "../../redux/shared/redux.provider";

export default function HeaderLinks(props: any) {
  const { variant, children, logoText, fixed, secondary, onOpen, ...rest } =
    props;
  const { redux, Rts } = useRedux();
  const { store, dispatch, IReducer, isLoading } = redux;
  const { useColorModeValue } = Rts;

  //const { SznIButtonColorMode } = useTheme();
  const user = store.users;
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  if (secondary) {
    navbarIcon = "white";
  }

  function UserInfoAvatar() {
    if (user.isAuth) {
      return (
        <Rts.Avatar
          size="sm"
          src={`data:image/png;base64,${user.logged?.id}`}
        />
      );
    } else {
      return (
        <Rts.Flex direction={"row"}>
          <Rts.SkeletonCircle size="10" />
        </Rts.Flex>
      );
    }
  }

  function UserInfoName() {
    if (user.isAuth) {
      return <>{user.logged?.unique_fullname?.split(" ")[1]}</>;
    } else {
      return (
        <Rts.Flex direction={"row"} w={"100%"}>
          <Rts.Skeleton height="20px" width={"100px"} />
        </Rts.Flex>
      );
    }
  }

  return (
    <Rts.Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      mt={{ sm: "15px", md: "0px" }}
      alignItems="center"
      justifyItems={"end"}
      flexDirection="row"
      maxHeight={"18px"}
    >
      <Rts.Badge
        display={{ sm: "none", md: "block" }}
        borderRadius={"15px"}
        alignContent={"center"}
        px={"10px"}
      >
        <Rts.Flex
          align={"center"}
          alignItems={"center"}
          alignContent={"center"}
          alignSelf={"center"}
          gap={2}
        >
          <Rts.Status value={store.core.signalr.state ? "success" : "error"} />
          SignalR
        </Rts.Flex>
      </Rts.Badge>
      <Rts.MenuRoot>
        <Rts.MenuTrigger>
          <Button
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="ghost"
            cursor={"pointer"}
          >
            <UserInfoAvatar />
            <UserInfoName />
          </Button>
        </Rts.MenuTrigger>
        <Rts.MenuContent p="16px 8px">
          <Rts.Flex flexDirection="column" fontSize={"sm"}>
            <MenuItem disabled={isLoading} value="NbLinkProfile">
              <FaUser style={{ marginRight: "15px" }} /> Profil
            </MenuItem>
            <MenuItem disabled={isLoading} value="NbLinkSettings">
              <FaCog style={{ marginRight: "15px" }} /> Nastavení
            </MenuItem>
            <MenuItem
              disabled={isLoading}
              value="NbLinkLogOff"
              onClick={() => {
                dispatch(IReducer.IUser.IData.log_off(1));
              }}
            >
              <FaLock style={{ marginRight: "15px" }} /> Odhlásit se
            </MenuItem>
          </Rts.Flex>
        </Rts.MenuContent>
      </Rts.MenuRoot>
      <NavbarAlerts getUser={user} {...rest} />
      <Rts.ColorModeButton ms={2} />
      <SidebarResponsive
        logoText={logoText}
        secondary={secondary}
        routes={routes}
        {...rest}
      />
      <Rts.Flex
        justifyContent="space-between"
        alignItems="center"
        ms={4}
        mr={-4}
      >
        {/* <SznIButtonColorMode aria-label="" bg={"transparent"} /> */}
      </Rts.Flex>
    </Rts.Flex>
  );
}
