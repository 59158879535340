import {
  useDisclosure
} from "@chakra-ui/react";
import SidebarContent from "./SidebarContent";
import { useRedux } from "../../redux/shared/redux.provider";

function SidebarResponsive(props: any) {
  const { routes, logoText, display, sidebarVariant } = props;
  const { open, onOpen, onClose } = useDisclosure();
  const { Rts } = useRedux();
  return (
    <Rts.Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Rts.Icons.FaBars onClick={onOpen} />
      <Rts.DrawerRoot open={open} placement={"start"}>
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
        >
          <Rts.DrawerCloseTrigger
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <Rts.DrawerBody maxW="250px" px="1rem">
            <Rts.Box maxW="100%" h="100vh">
              <SidebarContent
                routes={routes}
                logoText={logoText}
                display={display}
                sidebarVariant={sidebarVariant}
              />
            </Rts.Box>
          </Rts.DrawerBody>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
    </Rts.Flex>
  );
}

export default SidebarResponsive;
