import { Text, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import logo_h from "../assets/img/rts_h.png";
import logo_v from "../assets/img/rts_v.png";
import favicon from "../assets/img/facicon.32.png";
import { useLocation } from "react-router-dom";
import { useRedux } from "../redux/shared/redux.provider";
import { User } from "../redux/features/users/users.types";

function Unauthorized() {
  const { redux, Rts } = useRedux();
  const { store, dispatch, isAlive, isLoading, isError, message, IReducer } =
    redux;
  const { isAuth, logged, response } = store.users;
  const sub_logo = Rts.useColorModeValue(logo_h, logo_h);
  const web_app_logo = Rts.useColorModeValue(logo_v, logo_v);
  const textColor = Rts.useColorModeValue("gray.400", "white");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const photo =
    response.length > 0 ? `data:image/png;base64,${response[0].id}` : "";

  useEffect(() => {
    if (code) {
      if (code.length > 0) {
        dispatch(
          IReducer.IUser.IFetch({
            type: IReducer.IUser.IFetchTypes.GET_LOGIN_OAUTH,
            data: { code: code },
          })
        );
      }
    }
  }, [code]);

  const SelectProfile = (idx: number) => {
    dispatch(IReducer.IUser.IData.set_profile({ idx }));
  };

  const LogIn = () => {
    let redirectPattern = "/api/page/login/oauth2";

    if (location.pathname.indexOf("/auth2") === -1)
      localStorage.setItem("lastPath", location.pathname);

    if (window.location.host === "localhost:3000")
      redirectPattern = "https://localhost:7082/api/page/login/oauth2/3000";

    window.location.replace(redirectPattern);
  };

  return (
    <>
      <Rts.Flex
        w={"100%"}
        h={"100vh"}
      >
        <Rts.Image
          position={"absolute"}
          left={0}
          top={0}
          maxW={"480px"}
          maxH={"92px"}
          src={sub_logo}
          alignSelf={"center"}
          mt={2}
          ms={2}
        />
        <Rts.AbsoluteCenter bg={"transparent"}>
          <Rts.Center bg={"transparent"}>
            <Rts.Card.Root
              maxWidth={"450px"}
              minWidth={"450px"}
              px={4}
              backdropFilter={"blur(21px)"}
            >
              <Rts.Card.Header justifyContent={"right"} w={"100%"}>
                <Rts.Flex position={"absolute"} right={2} top={2}>
                </Rts.Flex>
              </Rts.Card.Header>
              <Rts.Card.Body
                w={"100%"}
                textAlign={"center"}
                alignContent={"center"}
                justifyContent={"center"}
                alignItems={"center"}
                justifyItems={"center"}
              >
                <Rts.Flex
                  direction={"row"}
                  justifySelf={"center"}
                  align={"center"}
                  justify={"center"}
                  mb={22}
                >
                  <Rts.Image
                    src={web_app_logo}
                    alignSelf={"center"}
                    maxH={"300px"}
                    maxW={"480px"}
                  />
                </Rts.Flex>

                <Rts.Text
                  alignSelf={"center"}
                  mb="36px"
                  ms="4px"
                  color={textColor}
                  fontWeight="bold"
                  fontSize="14px"
                >
                  {response.length <= 1 && (
                    <>
                      Aby jsi mohl pokračovat ve svém záměru tak se musíš
                      přihlásit. Přihlášení tě přesměruje na autorizační server.
                    </>
                  )}
                  {response.length > 1 && (
                    <>Choose the profile with which you will continue...</>
                  )}
                </Rts.Text>
                {isError && (
                  <Rts.Alert title="Chyba" status="error">
                    {message}
                  </Rts.Alert>
                )}
                
                {isAlive && response.length <= 1 && (
                  <Rts.Card.Root p={2} py={-2} w={"100%"}>
                    <Rts.Flex direction={"row"} align={"center"} w={"100%"}>
                      <Rts.Avatar
                        borderRadius={"15px"}
                        ms={2}
                        mr={6}
                        src={photo}
                      />
                      <Rts.Button
                        fontSize="14px"
                        type="submit"
                        variant={"subtle"}
                        h="45"
                        w={"75%"}
                        mr={2}
                        mb="20px"
                        mt="20px"
                        onClick={LogIn}
                        loading={code ? true : false}
                        disabled={isLoading}
                      >
                        Přihlásit se
                      </Rts.Button>
                    </Rts.Flex>
                  </Rts.Card.Root>
                )}
              </Rts.Card.Body>
              <Rts.Card.Footer>
                <Rts.Flex
                  direction={"row"}
                  w={"100%"}
                  align={"center"}
                  justify={"center"}
                >
                  &copy; 2024 - {new Date().getFullYear()},{" "}
                  <Text as="span">
                    BRITEX-CZ, s.r.o. by <a href="crossui#">Crossui.eu</a>
                  </Text>
                </Rts.Flex>
              </Rts.Card.Footer>
            </Rts.Card.Root>
          </Rts.Center>
        </Rts.AbsoluteCenter>
      </Rts.Flex>
    </>
  );
}

export default Unauthorized;
