import { useEffect, useState } from "react";
import { useRedux } from "../../../redux/shared/redux.provider";
import { device_w, device_oow } from "../interfaces/interface.validation";
import { ZodError } from "../../../redux/shared/redux.types";
import React from "react";
import { useDisclosure } from "@chakra-ui/react";

function Device() {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer } = redux;
  const data = store.service_request.devices;

  const dispatcher = IReducer.IServiceRequest.IData;
  return (
    <>
      <Rts.Button
        variant={"subtle"}
        onClick={() => dispatch(dispatcher.addDevice())}
      >
        Přidat zařízení
      </Rts.Button>
      <Rts.Alert mt={1} mb={2} status="warning" variant="subtle" fontSize="sm">
        Přidejte zařízení pro které žádáte o servis. V případě, že máte více
        zařízení, můžete zadat více zařízení. Každé zařízení bude mít své číslo
        reklamace. Pokud žádáte o svoz, tak je nutné zadat jen tolik zařízení,
        aby hodnota součtu hodnot zadaných zařízení nepřekročila 50 000 Kč s
        DPH. Pokud součet hodnot jednotlivých zařízení přesáhne hodnotu 50 000
        Kč s DPH, bude, v případě poškození / ztráty přepravního balíku
        přepravní společností, nárok na náhradu škody uplatněn v maximální výši
        50 000 Kč s DPH.
      </Rts.Alert>
      {data.map((x, idx) => (
        <DeviceCard idx={idx} />
      ))}
    </>
  );
}

export const DeviceEditor = () => {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer } = redux;
  const drawer = store.service_request.device_edit;
  const data = store.service_request.devices[drawer.idx];
  const [error_validate, setValidateError] = useState<ZodError[] | null>(null);
  const { open, onOpen, onClose } = useDisclosure();
  const allowed_vendors = [1, 2, 4, 6, 8, 15, 53, 55, 88, 90];
  const warranty_dates = () => {
    let date = new Date();
    let datemax = new Date();
    let min = date.setDate(date.getDate() - 820);
    let max = datemax.setDate(datemax.getDate());
    return { min, max };
  };

  const is_invalid = (type?: string) => {
    if (type === undefined) {
      if (error_validate === null) return false;
      return true;
    }

    let error = error_validate?.filter((x) => x.path[0] === type);
    if (error === undefined || error?.length === 0) return false;
    return true;
  };

  const handleChange = (e: any) => {
    dispatch(IReducer.IServiceRequest.IData.saveDevice(e));
  };

  const invalid_message = (type: string) => {
    let error = error_validate?.filter((x) => x.path[0] === type);
    return error?.map((x) => x.message).join(", ");
  };

  const validate = () => {
    try {
      if (data.is_warranty) device_w.parse(data);
      else device_oow.parse(data);
      setValidateError(null);
      return true;
    } catch (e: any) {
      setValidateError(JSON.parse(e));
      return false;
    }
  };

  useEffect(() => {
    if (drawer.is_open) validate();
  }, [data]);

  const validate_save = () => {
    var validate_result = validate();
    if (validate_result) {
      dispatch(IReducer.IServiceRequest.IData.saveDevice({ is_saved: true }));
      dispatch(IReducer.IServiceRequest.IData.closeEditor());
    }
  };

  return drawer.is_open ? (
    <>
      <Rts.DialogRoot
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        open={open}
        closeOnEscape
        closeOnInteractOutside
      >
        <Rts.DialogContent>
          <Rts.DialogHeader>
            Vyberte výrobce <Rts.DialogCloseTrigger onClick={onClose} />
          </Rts.DialogHeader>
          <Rts.DialogBody>
            <Rts.Input placeholder="Vyhledat výrobce" />
            <Rts.RadioCardRoot
              mt={4}
              gap={2}
              onChangeCapture={(e: any) => {
                handleChange({ vendor: e.target.value });
                onClose();
              }}
            >
              {store.shared.vendors
                .filter((x) => allowed_vendors.includes(Number(x.value)))
                .map((x) => (
                  <Rts.RadioCardItem
                    label={x.label}
                    key={x.value}
                    value={x.value}
                  />
                ))}
            </Rts.RadioCardRoot>
          </Rts.DialogBody>
        </Rts.DialogContent>
      </Rts.DialogRoot>
      <Rts.DrawerRoot
        open={drawer.is_open}
        size={"lg"}
        closeOnEscape={false}
        closeOnInteractOutside={false}
      >
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent>
          <Rts.DrawerHeader>
            <Rts.Field
              label="Výrobce"
              required
              invalid={is_invalid("vendor")}
              errorText={invalid_message("vendor")}
            >
              <Rts.Button w={"100%"} variant={"subtle"} onClick={onOpen}>
                {data.vendor === ""
                  ? "Vybrat výrobce"
                  : `${
                      store.shared.vendors.filter(
                        (x) => x.value === data.vendor
                      )[0]?.label
                    }`}
              </Rts.Button>
            </Rts.Field>
          </Rts.DrawerHeader>
          <Rts.DrawerCloseTrigger
            onClick={() => {
              dispatch(IReducer.IServiceRequest.IData.closeEditor());
              if (!data.is_saved)
                dispatch(
                  IReducer.IServiceRequest.IData.removeDevice(drawer.idx)
                );
            }}
          />
          <Rts.DrawerBody>
            <Rts.Flex direction={"column"} gap={4}>
              <Rts.Flex direction={{ base: "column", md: "row" }} gap={4}>
                <Rts.Field label="Vaše číslo reklamace">
                  <Rts.Input
                    placeholder="Vaše číslo reklamace"
                    value={data.num_at_card}
                    onChange={(e) =>
                      handleChange({ num_at_card: e.target.value })
                    }
                  />
                </Rts.Field>
                <Rts.Field
                  label="IMEI / SN"
                  required
                  invalid={is_invalid("imei")}
                  errorText={invalid_message("imei")}
                >
                  <Rts.Input
                    placeholder="IMEI / SN"
                    value={data.imei ?? data.serial_number}
                    onChange={(e) =>
                      handleChange({
                        imei: e.target.value,
                        serial_number: e.target.value,
                      })
                    }
                  />
                </Rts.Field>
                <Rts.Field
                  label="Typ"
                  required
                  invalid={is_invalid("type")}
                  errorText={invalid_message("type")}
                >
                  <Rts.Input
                    placeholder="Typ"
                    value={data.type}
                    onChange={(e) => handleChange({ type: e.target.value })}
                  />
                </Rts.Field>
              </Rts.Flex>
              <Rts.Flex>
                <Rts.Field label="Typ opravy" required>
                  <Rts.RadioCardRoot
                    mt={4}
                    gap={2}
                    defaultValue={data.is_warranty ? "1" : "2"}
                    onChangeCapture={(e: any) => {
                      handleChange({
                        is_warranty: e.target.value === "1" ? true : false,
                      });
                      onClose();
                    }}
                  >
                    <Rts.Flex direction={{ base: "column", md: "row" }} gap={2}>
                      <Rts.RadioCardItem
                        w={"100%"}
                        label={"Pozáruční"}
                        description="Oprava bude zpoplatněna na základě cenové nabídky nebo předpokládané ceny opravy"
                        key={"OOW"}
                        value={"2"}
                      />
                      <Rts.RadioCardItem
                        w={"100%"}
                        label={"Záruční"}
                        description="Oprava bude zdarma na základě záručního listu nebo dokladu o prodeji"
                        key={"IW"}
                        value={"1"}
                      />
                    </Rts.Flex>
                  </Rts.RadioCardRoot>
                </Rts.Field>
              </Rts.Flex>
              <Rts.Field label="Reklamační protokol">
                <Rts.FileUploadRoot
                  maxFiles={2}
                  onFileAccept={(e) =>
                    dispatch(
                      IReducer.IServiceRequest.IData.addReceiptFile(e.files)
                    )
                  }
                  title=""
                >
                  <Rts.FileUploadDropzone
                    w={"100%"}
                    label="Pokud máte reklamační protokol, přiložte jej prosím....."
                    description=".png, .jpg, .pdf do velikosti 5MB"
                  />
                  <Rts.FileUploadList showSize clearable />
                </Rts.FileUploadRoot>
              </Rts.Field>
              {!data.is_warranty && (
                <Rts.Field
                  label="Předpokládaná cena opravy"
                  required
                  invalid={is_invalid("estimated_repair_cost")}
                  errorText={invalid_message("estimated_repair_cost")}
                >
                  <Rts.Alert
                    mt={1}
                    mb={2}
                    status="warning"
                    variant="subtle"
                    fontSize="sm"
                  >
                    Minimální předpokládaná cena opravy je 500 Kč včetně DPH.
                    Dojde-li k navýšení udané předpokládané ceny více než o 10%,
                    bude vystavena cenová nabídka. V opačném případě bude
                    zařízení automaticky opraveno bez vystavení cenové nabídky.
                    V případě, že se s cenovou nabídkou nebudete souhlasit, bude
                    automaticky účtována částka za vypracování cenové nabídky a
                    také dopravné v celkové výši maximálně 550 Kč s DPH.
                  </Rts.Alert>
                  <Rts.NumberInputRoot
                    defaultValue={(
                      data.estimated_repair_cost ?? 550.0
                    ).toString()}
                    min={550.0}
                    step={20.0}
                    formatOptions={{
                      style: "currency",
                      currency: "CZK",
                      minimumFractionDigits: 2,
                    }}
                    value={(data.estimated_repair_cost ?? 550.0).toString()}
                    onValueChange={(e) =>
                      handleChange({ estimated_repair_cost: e.valueAsNumber })
                    }
                  >
                    <Rts.NumberInputField />
                  </Rts.NumberInputRoot>
                </Rts.Field>
              )}
              {data.is_warranty && (
                <Rts.Flex direction={"column"} gap={4}>
                  <Rts.Field
                    label="Datum prodeje"
                    required
                    errorText={invalid_message("purchase_date")}
                    invalid={is_invalid("purchase_date")}
                  >
                    <Rts.Input
                      type="date"
                      min={
                        new Date(warranty_dates().min)
                          .toISOString()
                          .split("T")[0]
                      }
                      max={
                        new Date(warranty_dates().max)
                          .toISOString()
                          .split("T")[0]
                      }
                      value={data.purchase_date ?? ""}
                      onChange={(e) =>
                        handleChange({ purchase_date: e.target.value })
                      }
                    />
                  </Rts.Field>
                  <Rts.Field
                    label="Doklad o prodeji"
                    required
                    invalid={is_invalid("warranty_files")}
                    errorText={invalid_message("warranty_files")}
                  >
                    <Rts.FileUploadRoot
                      maxFiles={2}
                      onFileAccept={(e) =>
                        dispatch(
                          IReducer.IServiceRequest.IData.addWFile(e.files)
                        )
                      }
                      title=""
                    >
                      <Rts.FileUploadDropzone
                        w={"100%"}
                        label="Přiložte prosím doklad o prodeji....."
                        description=".png, .jpg, .pdf do velikosti 5MB"
                      />
                      <Rts.FileUploadList showSize clearable />
                    </Rts.FileUploadRoot>
                  </Rts.Field>
                </Rts.Flex>
              )}
              <Rts.Field
                label="Popis stavu zařízení"
                required
                invalid={is_invalid("damage")}
                errorText={invalid_message("damage")}
              >
                <Rts.Textarea
                  rows={4}
                  value={data.damage}
                  onChange={(e) => handleChange({ damage: e.target.value })}
                  tabIndex={22}
                  placeholder="Textový popis stavu zařízení... (Vizuální stav Vašeho zařízení popište co nejpřesněji. Vyhneme se pak případným neshodám a s tím spojenou prodlouženou dobu opravu.)"
                />
              </Rts.Field>
              <Rts.Field label="Fotodokumentace zařízení">
                <Rts.FileUploadRoot
                  maxFiles={2}
                  onFileAccept={(e) =>
                    dispatch(
                      IReducer.IServiceRequest.IData.addDamageFile(e.files)
                    )
                  }
                  title=""
                >
                  <Rts.FileUploadDropzone
                    w={"100%"}
                    label="Je-li to možné, přiložte prosím fotodokumentaci zařízení....."
                    description=".png, .jpg, .pdf do velikosti 5MB"
                  />
                  <Rts.FileUploadList showSize clearable />
                </Rts.FileUploadRoot>
              </Rts.Field>
              <Rts.Field
                label="Popis závady"
                required
                invalid={is_invalid("defect")}
                errorText={invalid_message("defect")}
              >
                <Rts.Textarea
                  rows={4}
                  value={data.defect}
                  onChange={(e) => handleChange({ defect: e.target.value })}
                  tabIndex={22}
                  placeholder="Textový popis závady na zařízení... (Pokuste se co nejpřesněji popsat závadu. Ve většině případech se tím urychlý oprava zařízení.)"
                />
              </Rts.Field>
              <Rts.Flex direction={"row"} align="center" gap={4}>
                <Rts.Checkbox
                  checked={data.is_backup}
                  onCheckedChange={(e) =>
                    handleChange({ is_backup: !!e.checked })
                  }
                />
                <Rts.Text mt={1} fontSize={"xs"}>
                  Požaduji zálohovat data v zařízení{" "}
                  <Rts.Text color="gray.500">
                    Doplňková služba zpoplatněna částkou 500 Kč s DPH
                  </Rts.Text>
                </Rts.Text>
              </Rts.Flex>
              {data.is_backup && (
                <>
                  <Rts.Alert
                    mt={1}
                    mb={2}
                    status="warning"
                    variant="subtle"
                    fontSize="sm"
                  >
                    Doplňková služba záloha dat je zpoplatněna částkou 500 Kč s
                    DPH. Záloha dat je možná pouze u zařízení, která jsou
                    funkční a je možné je zapnout. U zařízení, která nelze
                    zapnout, je záloha dat možná pouze po schválení cenové
                    nabídky (I když se snažíme zachránit Vaše data, tak nemůžeme
                    na 100% zaručit, že se to povede). Záloha probíhá ze
                    zařízení do zařízení. Zálohovaná data neukládáme na externí
                    disky, které si zákazník sám donese! Zálohované data držíme
                    pouze 14 dní od doby zálohy dat. Po 14ti dnech se zálohy
                    nenávratně smažou z našich serverů.
                  </Rts.Alert>
                  <Rts.Flex direction={"row"} align="center" gap={4} px={6}>
                    <Rts.Checkbox
                      checked={data.is_failed_backup_return}
                      onCheckedChange={(e) =>
                        handleChange({
                          is_failed_backup_return: !!e.checked,
                        })
                      }
                    />
                    <Rts.Text mt={1} fontSize={"xs"}>
                      Pokud záloha nepůjde provést, požaduji zařízení vrátit
                      zpět bez opravy a zaplatím částku za dopravné a
                      manipulační poplatek ve výši maximálně 500kč s DPH.
                    </Rts.Text>
                  </Rts.Flex>
                </>
              )}
            </Rts.Flex>
          </Rts.DrawerBody>

          <Rts.DrawerFooter justifyContent={"space-between"}>
            <Rts.Text fontSize="sm" color="red.500">
              {is_invalid() ? "Nebyly doplněny všechny požadované pole" : ""}
            </Rts.Text>
            <Rts.Button
              variant={"subtle"}
              disabled={is_invalid()}
              onClick={() => validate_save()}
            >
              Zavřít a uložit
            </Rts.Button>
          </Rts.DrawerFooter>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
    </>
  ) : (
    <></>
  );
};

export const DeviceCard = ({ idx }: { idx: number }) => {
  const { Rts, redux } = useRedux();
  const { dispatch, store, IReducer } = redux;
  const data = store.service_request.devices[idx];
  const vendors = store.service_request.vendors;
  const containerRef = React.useRef<HTMLDivElement>(null);
  return (
    <>
      <Rts.Card.Root>
        <Rts.Card.Header>
          <Rts.Flex direction={"row"} justify={"space-between"}>
            <Rts.Flex direction={"row"} gap={2} align={"center"}>
              <Rts.Icons.FaMobileAlt />
              <Rts.Text fontSize="md" fontWeight="bold">
                {vendors.filter((x) => x.value === data.vendor)[0]?.label}
              </Rts.Text>
            </Rts.Flex>
            <Rts.Flex direction={"row"} gap={2} align={"center"}>
              <Rts.Button
                size="xs"
                variant={"subtle"}
                onClick={() =>
                  dispatch(IReducer.IServiceRequest.IData.removeDevice(idx))
                }
              >
                <Rts.Icons.FaTrash />
              </Rts.Button>
              <Rts.Button
                size="xs"
                variant={"subtle"}
                onClick={() =>
                  dispatch(IReducer.IServiceRequest.IData.openEditor(idx))
                }
              >
                <Rts.Icons.FaPen />
              </Rts.Button>
            </Rts.Flex>
          </Rts.Flex>
        </Rts.Card.Header>
        <Rts.Card.Body>
          <Rts.Flex
            direction={{ base: "column", md: "row" }}
            gap={2}
            align={{ base: "start", md: "center" }}
            justify={"space-between"}
            mt={{ sm: "0", md: "-28px" }}
            color={"gray.500"}
          >
            <Rts.Text fontSize="sm">
              <strong>Typ:</strong> {data.type}
            </Rts.Text>
            <Rts.Text fontSize="sm">
              <strong>IMEI / SN:</strong> {data.imei}
            </Rts.Text>
            <Rts.Text fontSize="sm">
              <strong>Druh:</strong>{" "}
              {data.is_warranty ? "Záruční" : "Pozáruční"}
            </Rts.Text>
            <Rts.Text fontSize="sm">
              <strong>Reklamace č.:</strong> {data.num_at_card}
            </Rts.Text>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </>
  );
};
export default Device;
