import ReactMaskInput from "react-input-mask";
import { useRedux } from "../redux.provider";
import React, {
  LegacyRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { InputProps } from "@chakra-ui/react/input";

interface InputMaskProps extends InputProps {
  mask: string;
}

export default function InputMask(
  props: InputMaskProps & React.RefAttributes<HTMLInputElement>
) {
  const { Rts, redux } = useRedux();
  const [inputClassName, setInputClassName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const __class = Rts.useColorModeValue(
    "react-mask-input",
    "react-mask-input-dark"
  );

  const { w } = props;

  const __full_class = () => {
    return __class + " width-" + w;
  };

  const __inputMask = () => {
    return <Rts.Input ref={inputRef} />;
  };

  useEffect(() => {
    if (inputRef.current) {
      // Získejte dynamicky vygenerovanou třídu
      const className = inputRef.current.className;
      setInputClassName(className);
    }
  }, []);

  return (
    <>
      <Rts.Input {...props} ref={inputRef} style={{ display: "none" }} disabled={redux.isLoading} />
      <ReactMaskInput className={inputClassName} value={props.value} mask={props.mask} onChange={props.onChange} disabled={redux.isLoading} />
    </>
  );
}
