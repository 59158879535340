import PanelContainer from "../components/MainPanel/panelContainer";
import { Route, Routes } from "react-router-dom";
import CreateServiceRequest from "../views/serviceRequest";
import theme from "./theme/theme";
import { ChakraProvider } from "@chakra-ui/react";

const IframeLayout = () => {
  return (
    <ChakraProvider value={theme}>
        <PanelContainer>
          <Routes>
            <Route path="serviceRequest" element={<CreateServiceRequest />} />
          </Routes>
        </PanelContainer>
    </ChakraProvider>
  );
};

export default IframeLayout;
