import UsersSlice from "../features/users/users.slice";
import CoreSlice from "../features/core/core.slice";
import SharedSlice from "../features/shared/shared.slice";
import { Middleware, configureStore } from "@reduxjs/toolkit";
import StateSlice from "../features/state/state.slice";
import configSlice from "../features/config/config.slice";
import servicerequestSlice from "../features/createservicerequest/servicerequest.slice";
import logisticsPackagesSlice from "../features/logistics_packages/logistics_packages.slice";

const reduxstore = configureStore({
  reducer: {
    users: UsersSlice,
    core: CoreSlice,
    shared: SharedSlice,
    state: StateSlice,
    service_request: servicerequestSlice,
    logistics_packages: logisticsPackagesSlice,
    config: configSlice,
  },
});

export type RootState = ReturnType<typeof reduxstore.getState>;
export type AppDispatch = typeof reduxstore.dispatch;

export default reduxstore;
