"use client";

import type {IconButtonProps} from "@chakra-ui/react";
import {IconButton} from "@chakra-ui/react";
import {ThemeProvider, useTheme} from "next-themes";
import type {ThemeProviderProps} from "next-themes/dist/types";
import React, {forwardRef, ReactNode} from "react";
import {LuMoon, LuSun} from "react-icons/lu";

export function ColorModeProvider(props: ThemeProviderProps): any {
    return (
        <ThemeProvider attribute="class" disableTransitionOnChange {...props}  children={props.children}/>
    );
}

export function useColorMode() {
    const {resolvedTheme, setTheme} = useTheme();
    const toggleColorMode = () => {
        setTheme(resolvedTheme === "light" ? "dark" : "light");
    };
    return {
        colorMode: resolvedTheme,
        setColorMode: setTheme,
        toggleColorMode,
    };
}

export function useColorModeValue<T>(light: T, dark: T) {
    const {colorMode} = useColorMode();
    return colorMode === "light" ? light : dark;
}

export function ColorModeIcon() {
    const {colorMode} = useColorMode();
    return colorMode === "light" ? <LuMoon/> : <LuSun/>;
}

interface ColorModeButtonProps extends Omit<IconButtonProps, "aria-label"> {
}

export const ColorModeButton = forwardRef<
    HTMLButtonElement,
    ColorModeButtonProps
>(function ColorModeButton(props, ref) {
    const {toggleColorMode} = useColorMode();
    return (
        <IconButton
            onClick={toggleColorMode}
            variant="ghost"
            aria-label="Toggle color mode"
            size="sm"
            ref={ref}
            {...props}
            css={{
                _icon: {
                    width: "5",
                    height: "5",
                },
            }}
        >
            <ColorModeIcon/>
        </IconButton>
    );
});
