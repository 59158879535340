import { createAsyncThunk } from "@reduxjs/toolkit";
import Fetch, { Endpoints } from "../../shared/redux.fetch";
import { FetchTypes } from "./state.enums";
import { endpoint } from "../../shared/redux.types";

export const FetchData = createAsyncThunk<
  string[],
  {
    type: FetchTypes;
    data: {
      time_at?: string;
      id?: string;
    };
  },
  { rejectValue: string }
>("state/fetchData", async (_, thunkAPI) => {
  try {
    var result = await Fetch(DispatchFetch(_), "", _.data);
    return Promise.resolve(result);
  } catch (error: any) {
    thunkAPI.rejectWithValue(error);
    return Promise.reject(error);
  }
});

const DispatchFetch = (_: {
  type: FetchTypes;
  data: { };
}): endpoint => {
  switch (_.type) {
    case FetchTypes.GET_STATE:
      return Endpoints.STATE.GET_STATE();
  }
};
