import { createAsyncThunk } from "@reduxjs/toolkit";
import Fetch from "../../shared/redux.fetch";
import { FetchTypes } from "./shared.enums";
import endpoints from "./shared.endpoints";
import { endpoint } from "../../shared/redux.types";

type DataType = {
  ico?: string;
  countryCode?: string;
  date?: string;
};

export const FetchData = createAsyncThunk<
  string[],
  {
    type: FetchTypes;
    data: DataType;
  },
  { rejectValue: string }
>("logs/fetchData", async (_, thunkAPI) => {
  try {
    var result = await Fetch(DispatchFetch(_), "", _.data);
    return Promise.resolve(result);
  } catch (error: any) {
    thunkAPI.rejectWithValue(error);
    return Promise.reject(error);
  }
});

const DispatchFetch = (_: { type: FetchTypes; data: DataType }): endpoint => {
  switch (_.type) {
    case FetchTypes.GET_CZ_ARES2:
      return endpoints.GET_CZ_ARES2(_.data.ico!);
    case FetchTypes.GET_SK_RUZ:
      return endpoints.GET_SK_RUZ(_.data.ico!);
    case FetchTypes.GET_IS_PICKUP_DATE:
      return endpoints.GET_IS_PICKUP_DATE(_.data.countryCode!, _.data.date!);
    case FetchTypes.GET_FIRST_PICKUP_DATE:
      return endpoints.GET_FIRST_PICKUP_DATE(_.data.countryCode!);
  }
};
