import { useRedux } from "../../../redux/shared/redux.provider";

export default function SoDetailDocuments() {
  const { Rts } = useRedux();
  return (
    <Rts.Flex direction={"column"} gap={2}>
      <Rts.FileUploadRoot borderRadius={"15px"} maxW="xl" alignItems="stretch" maxFiles={10}>
        <Rts.FileUploadDropzone borderRadius={"15px"}
          label="Drag and drop here to upload"
          description=".png, .jpg up to 5MB"
        />
        <Rts.FileUploadList clearable showSize />
      </Rts.FileUploadRoot>
    </Rts.Flex>
  );
}
