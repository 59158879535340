import { useRedux } from "../redux.provider";
import * as CSS from "csstype";
import { navigationModel } from "../redux.types";
import { Flex, FlexProps } from "@chakra-ui/react";

interface NavigationData<T> extends navigationModel<T> {
  onNext: () => void;
  onPrev: () => void;
  onPageSize: (size: number) => void;
}

export default function Navigations<T>(props: {
  mb?: FlexProps;
  type: "" | "live";
  data: NavigationData<T>;
  renderComponent: Function;
}) {
  const { data, type, mb } = props;
  const { onPageSize, onPrev, onNext, total, pageNumber, pageSize } = data;
  const { Rts, redux } = useRedux();

  const BrItem = ({ pageSizeInner }: { pageSizeInner: number }) => (
    <Rts.BreadcrumbLink
      onClick={() => {
        onPageSize(pageSizeInner);
        localStorage.setItem("pageSize", pageSizeInner.toString());
      }}
    >
      {pageSizeInner}
    </Rts.BreadcrumbLink>
  );

  const NavigateMenu = () => {
    return (
      <Rts.Flex
        w="100%"
        px={1}
        align={"center"}
        mb={mb?.mb}
        justify={"space-between"}
        mt={2}
        display={type === "live" ? "none" : "flex"}
      >
        <Rts.Button
          size={"xs"}
          disabled={!data.is_prev}
          variant="subtle"
          onClick={onPrev}
        >
          {"< Zpět"}
        </Rts.Button>
        {redux.isLoading && <Rts.Spinner />}
        {!redux.isLoading && (
          <Rts.Text fontSize={"xs"} color={"gray.500"}>
            Nalezených záznamů:{" "}
            {(pageNumber - 1) * pageSize + (data.count ?? 0)} / {total}
          </Rts.Text>
        )}
        <Rts.BreadcrumbRoot separator="|" fontSize={"sm"}>
          <BrItem pageSizeInner={20} />
          <BrItem pageSizeInner={50} />
          <BrItem pageSizeInner={100} />
          <BrItem pageSizeInner={200} />
        </Rts.BreadcrumbRoot>
        <Rts.Button
          size={"xs"}
          disabled={!data.is_next}
          variant="subtle"
          onClick={onNext}
        >
          {"Další >"}
        </Rts.Button>
      </Rts.Flex>
    );
  };

  return (
    <>
      <NavigateMenu />
      <Rts.Flex gap={1} direction={"column"}>
        {data.data?.map((e) => props.renderComponent({ item: e }))}
      </Rts.Flex>
      {!redux.isLoading && (data.count ?? 0) > 5 && <NavigateMenu />}
    </>
  );
}
