import { useState } from "react";
import { useRedux } from "../../redux/shared/redux.provider";
import SoFilter from "./so.filter";
import SoItem from "./so.soitem";

export default function SoPreview() {
  const { Rts, redux } = useRedux();
  const [pageSize, setPageSize] = useState<number>(20);
  const data = {
    data: [
      {
        id: 4000111,
        manager: "fridrich",
        vendor: "SMSUNG",
        dev_type: "SM-TEST",
        dev_repair_type: "Záruční",
      },
    ],
    is_prev: false,
    is_next: false,
    pageNumber: 1,
    total: 1,
  };

  const BrItem = ({ pageSizeInner }: { pageSizeInner: number }) => (
    <Rts.BreadcrumbLink
      onClick={() => {
        setPageSize(pageSizeInner);
        localStorage.setItem("pageSize", pageSizeInner.toString());
      }}
    >
      {pageSizeInner}
    </Rts.BreadcrumbLink>
  );

  return (
    <Rts.Flex w={"100%"} direction={"column"}>
      <Rts.Card.Root w={"100%"} mb={2}>
        <Rts.Card.Body>
          <Rts.Flex
            gap={2}
            mb={2}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Rts.Flex gap={2} direction={"row"} justifyContent={"flex-start"}>
              <Rts.Checkbox me={6} />
              <Rts.Button aria-label="Duplikovat">
                <Rts.Icons.FaClone />
              </Rts.Button>
              <Rts.Button aria-label="Změnit stav">
                <Rts.Icons.FaExchangeAlt />
              </Rts.Button>
            </Rts.Flex>
            <SoFilter />
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <SoItem
        item={{
          id: 4000111,
          manager: "fridrich",
          vendor: "SMSUNG",
          dev_type: "SM-TEST",
          dev_repair_type: "Záruční",
        }}
      />
    </Rts.Flex>
  );
}
