import { useRedux } from "../../redux/shared/redux.provider";
import {
  SignaturePad,
  useSignaturePad
} from "@ark-ui/react/signature-pad";
import { useEffect } from "react";
import { LuUndo2 } from "react-icons/lu";

interface SignaturePadProps extends React.CSSProperties {
  onChange: (value: string) => void;
  height?: string | number | undefined;
  borderRadius?: string | number | undefined;
  border?: string | number | undefined;
}

export const RootProvider = (props: SignaturePadProps) => {
  const signaturePad = useSignaturePad();
  const { Rts } = useRedux();
  const drawer_color = Rts.useColorModeValue("black", "white");
  useEffect(() => {
    if (!signaturePad.drawing && !signaturePad.empty)
      signaturePad.getDataUrl("image/png").then((url) => props.onChange(url));
  }, [signaturePad, props]);

  return (
    <SignaturePad.RootProvider value={signaturePad}>
      <SignaturePad.Label>Podpis</SignaturePad.Label>
      <SignaturePad.Control
        style={{
          ...props,
        }}
      >
        <SignaturePad.Segment fill={drawer_color} height={props.height} />
        <SignaturePad.ClearTrigger
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            fontSize: "25px",
            cursor: "pointer",
          }}
        >
          <LuUndo2 />
        </SignaturePad.ClearTrigger>
        <SignaturePad.Guide style={{ marginTop: "10%", width: "90%", marginLeft: "5%", borderBottom: "1px dotted #242424"}} />
      </SignaturePad.Control>
    </SignaturePad.RootProvider>
  );
};
