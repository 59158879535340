import { createSlice } from "@reduxjs/toolkit";
import { consumeMessage, ExtraReducer, IntialReducer, LoadingState, newMessage, SetAlive, setConnection, SetError, SetIframe, setState, SetStateWithText } from "./core.reducers";
import { CoreSignalR } from "./core.signalr";

const initialState = IntialReducer;

const CoreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    setState,
    setConnection,
    setAlive: SetAlive,
    setError: SetError,
    setIframe: SetIframe,
    setStateWithText: SetStateWithText,
    loadingState: LoadingState,
    newMessage: newMessage,
    consumeMessage: consumeMessage,
  },
  extraReducers: ExtraReducer 
});

export default CoreSlice.reducer;

export const reducers = CoreSlice.actions;

export const SignalR = CoreSignalR;