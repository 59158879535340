import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraReducer,
  IntialReducer,
  setCountries,
} from "./shared.reducers";
import { FetchTypes, signalRTypes } from "./shared.enums";
import { FetchData } from "./shared.fetches";
import { SignalR } from "./shared.signalr";

const initialState = IntialReducer();

const SharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setCountries,
    
  },
  extraReducers: ExtraReducer,
});

export default SharedSlice.reducer;

export const SharedReducers = SharedSlice.actions;

export const fetchTypes = FetchTypes;

export const fetchShared = FetchData;

export const SISignalRType = signalRTypes;

export const SISignalR = SignalR;
