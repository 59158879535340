import { useParams } from "react-router-dom";
import { useRedux } from "../../../redux/shared/redux.provider";

export default function SoPriceProposal() {
  const { id } = useParams();
  const { redux, Rts } = useRedux();
  return (
    <Rts.Flex w={"100%"}>
      <Rts.Card.Root w={"100%"}>
        <Rts.Card.Body gap={4}>
          <Rts.Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            align={"center"}
          >
            <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
              Vytvořit Cenovou nabídku
            </Rts.Text>
            <Rts.Flex gap={2}>
              <Rts.Button loading={redux.isLoading}>Náhled</Rts.Button>
              <Rts.Button loading={redux.isLoading}>Odeslat</Rts.Button>
            </Rts.Flex>
          </Rts.Flex>
          <Rts.Flex flexDirection={"row"} align={"center"}>
            <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
              {id}
            </Rts.Text>
          </Rts.Flex>
          <Rts.Fieldset.Root mt={4}>
            <Rts.Fieldset.Legend fontSize={"xs"}>
              Důvod cenové nabídky
            </Rts.Fieldset.Legend>
            <Rts.Textarea rows={6} />
          </Rts.Fieldset.Root>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </Rts.Flex>
  );
}
