import { useRedux } from "../../redux/shared/redux.provider";
import dpd from "../../assets/img/carriers/dpd.png";
import britex from "../../assets/img/carriers/favicon.png";
import { useDisclosure } from "@chakra-ui/react";
import LpItemDetail from "./lp.itemdetail";
import { item } from "./lp.types";
import { HoverCardRoot } from "../../components/ui/hover-card";
import React from "react";

export default function LpItem(props: { item: item }) {
  const { item } = props;
  const { Rts } = useRedux();
  const { open, onOpen, onClose } = useDisclosure();
  const AvatarType = () => {
    if (!item.is_return)
      return (
        <Rts.Tooltip content={"Příchozí zásilka"}>
          <Rts.Avatar
            size={"sm"}
            bg={"orange.500"}
            icon={<Rts.Icons.LuPackagePlus />}
          >
            <Rts.Icons.LuPackagePlus />
          </Rts.Avatar>
        </Rts.Tooltip>
      );

    if (item.is_return)
      return (
        <Rts.Tooltip content={"Odchozí zásilka"}>
          <Rts.Avatar
            size={"sm"}
            bg={"green.500"}
            icon={<Rts.Icons.LuPackageMinus />}
          >
            <Rts.Icons.LuPackageMinus />
          </Rts.Avatar>
        </Rts.Tooltip>
      );

    return (
      <Rts.Avatar size={"sm"} bg={"blue.500"} icon={<Rts.Icons.FaQuestion />} />
    );
  };

  const CodIcon = () => {
    const [open, setOpen] = React.useState(false);
    if (item.is_cod)
      return (
        <Rts.HoverCardRoot
          size="sm"
          open={open}
          onOpenChange={(e) => setOpen(e.open)}
        >
          <Rts.HoverCardTrigger asChild>
            <Rts.Circle p={2} bg={"orange"}>
              <Rts.Icons.MdOutlineAttachMoney />
            </Rts.Circle>
          </Rts.HoverCardTrigger>
          <Rts.HoverCardContent maxWidth="240px">
            <Rts.HoverCardArrow />
            <Rts.Box>
              <Rts.Text fontSize={"xs"} fontWeight={"bold"}>
                Dobírka
              </Rts.Text>
              <Rts.Text fontSize={"xs"}>Částka: {item.cod_price??0} Kč</Rts.Text>
            </Rts.Box>
          </Rts.HoverCardContent>
        </Rts.HoverCardRoot>
      );
    return null;
  };

  const PrintLabel = () => {
    return (
      <Rts.Button
        size={"sm"}
        aria-label="Vytisknout štítek"
        variant={"outline"}
        disabled={!item.is_return}
      >
        <Rts.Icons.LuPrinter />
      </Rts.Button>
    );
  };

  const Carrier = () => {
    switch (item.carrier) {
      case "DPD CZ":
        return dpd;
      default:
        return britex;
    }
  };

  return (
    <>
      <LpItemDetail
        {...item}
        isOpen={open}
        onClose={onClose}
        icon={Carrier()}
      />
      <Rts.Card.Root
        variant={"subtle"}
        css={Rts.CssInterfaces.card.hover}
        fontSize={"sm"}
        onClick={onOpen}
      >
        <Rts.Card.Body>
          <Rts.Flex
            gap={2}
            direction={"row"}
            justify={"space-between"}
            align={"center"}
          >
            <Rts.Flex
              gap={6}
              direction={"row"}
              justify={"flex-start"}
              align={"center"}
              minW={"10%"}
            >
              <Rts.Checkbox />
              <AvatarType />
              <CodIcon />
            </Rts.Flex>
            <Rts.Flex direction={"row"} gap={4} align={"center"}>
              <Rts.Avatar size={"md"} src={Carrier()} />
              <Rts.Flex direction={"column"}>
                <Rts.Text fontWeight={"bold"}>Dopravce</Rts.Text>
                <Rts.Text>{item.carrier}</Rts.Text>
              </Rts.Flex>
            </Rts.Flex>

            <Rts.Flex direction={"column"}>
              <Rts.Text fontWeight={"bold"}>Datum</Rts.Text>
              <Rts.Text>{new Date(item.date).toLocaleDateString()}</Rts.Text>
            </Rts.Flex>

            <Rts.Flex direction={"column"}>
              <Rts.Text fontWeight={"bold"}>Číslo přepravy</Rts.Text>
              <Rts.Text>{item.pack_code}</Rts.Text>
            </Rts.Flex>

            <Rts.Flex direction={"column"}>
              <Rts.Text fontWeight={"bold"}>Zákazník</Rts.Text>
              <Rts.Text fontSize={"sm"}>{item.customer}</Rts.Text>
              <Rts.Text fontSize={"xs"} color={"gray.500"}>
                {item.customer_address}
              </Rts.Text>
            </Rts.Flex>

            <Rts.Flex direction={"column"} gap={2} minW={"10%"}>
              <Rts.Flex justify={"end"}>
                <Rts.Tag fontSize={"xs"} fontWeight={"bold"}>
                  {item.status}
                </Rts.Tag>
              </Rts.Flex>
              <Rts.Flex justify={"end"}>
                <PrintLabel />
              </Rts.Flex>
            </Rts.Flex>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </>
  );
}
