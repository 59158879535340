import { useEffect, useState } from "react";
import { useRedux } from "../../../redux/shared/redux.provider";
import { email } from "../interfaces/interface.validation";
import { AddressTypes } from "../../../redux/features/createservicerequest/servicerequest.enums";
import { ValueChangeDetails } from "node_modules/@chakra-ui/react/dist/types/components/pin-input/namespace";

export default function Verification() {
  const { Rts, redux } = useRedux();
  const { store, dispatch, IReducer, isLoading } = redux;
  const data = store.service_request;
  const [isValid, setIsvalid] = useState({ valid: false, message: "" });
  const [remainingTime, setRemainingTime] = useState(0);
  const [expireTime, setExpireTime] = useState<Date | null>(null);

  const handleValidateEmail = (e: string) => {
    try {
      email.parse(e);
      setIsvalid({ valid: true, message: "" });
    } catch (error: any) {
      const error_message = JSON.parse(error.message).map(
        (x: any) => x.message
      );
      setIsvalid({ valid: false, message: error_message.join(",") });
    }
    dispatch(IReducer.IServiceRequest.IData.setEmail(e));
  };

  useEffect(() => {
    if (store.shared.token_verified !== "") {
      dispatch(
        IReducer.IServiceRequest.IData.nextStep()
      );
    }
  }, [store.shared.token_verified]);


  useEffect(() => {
    if (store.shared.token_for_verify.length > 0) {
      // Spuštění intervalu pro aktualizaci zbývajícího času
      const interval = setInterval(() => {
        const now = new Date();
        const remaining = expireTime ? expireTime.getTime() - now.getTime() : 0;

        if (remaining <= 0) {
          // Čas vypršel, obnovení stránky
          clearInterval(interval);
          window.location.reload();
        } else {
          // Aktualizace zbývajícího času
          setRemainingTime(Math.floor(remaining / 1000)); // Převod na sekundy
        }
      }, 1000);

      // Vyčištění intervalu při odmontování komponenty
      return () => clearInterval(interval);
    }
  }, [store.shared.token_for_verify, expireTime]);


  const handleLogin = (e: ValueChangeDetails) => {
    dispatch(
      IReducer.IShared.ISignalR({
        type: IReducer.IShared.ISignalRType.SharedServiceRequestValidateVerification,
        data: {
          verificationCode: store.shared.token_for_verify,
          pin: Number(e.valueAsString),
        },
      })
    )
  };

  const handleVerification = () => {
    dispatch(
      IReducer.IShared.ISignalR({
        type: IReducer.IShared.ISignalRType.SharedServiceRequestValidateEmail,
        data: {
          email: data.customer.email,
        },
      }),
      true
    );

    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + 5);
    setExpireTime(expiration);
  };

  return (
    <Rts.Center mt={"20%"} alignContent={"center"}>
      <Rts.Flex direction={"column"}>
        {store.shared.token_for_verify !== "" && (
          <Rts.Flex direction={"column"}>
            <Rts.Text fontSize="md" fontWeight={"Bold"}>
              Zadejte ověřovací PIN, který Vám dorazil do emailu.
            </Rts.Text>
            <Rts.Text fontSize="sm" color="gray.500">
              Zbývající čas pro zadání PINu: {remainingTime} sekund
            </Rts.Text>
            <Rts.Card.Root mt={2}>
              <Rts.Center mt={6} mb={5}>
                <Rts.PinInput
                  otp
                  onValueComplete={handleLogin}
                />
              </Rts.Center>
            </Rts.Card.Root>
            <Rts.Button
              mt={4}
              w={"100%"}
              variant={"subtle"}
              onClick={() => handleVerification()}
              disabled={!isValid.valid}
              loading={isLoading}
            >
              Ověřit
            </Rts.Button>
          </Rts.Flex>
        )}
        {store.shared.token_for_verify === "" && (
          <Rts.Flex direction={"column"}>
            <Rts.Text fontSize="md" fontWeight={"Bold"}>
              Pro vstup do nového požadavku žádosti o opravu a svozu zařízení je nutné ověřit Váš
              email.
            </Rts.Text>
            <Rts.Field label="" required errorText={isValid.message}>
              <Rts.Input
                mt={6}
                placeholder="Email"
                autoFocus
                onChange={(e) => handleValidateEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleVerification();
                }}
              />
            </Rts.Field>

            <Rts.Button
              mt={4}
              variant={"subtle"}
              w={"100%"}
              onClick={() => handleVerification()}
              disabled={!isValid.valid}
              loading={isLoading}
            >
              Ověřit
            </Rts.Button>
          </Rts.Flex>
        )}
      </Rts.Flex>
    </Rts.Center>
  );
}
