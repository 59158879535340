import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IShared, ISignalR, ISignalRType } from "./shared.interface";
import { fetchShared } from "./shared.slice";
import { FetchTypes } from "./shared.enums";
import { Countries } from "./shared.types";

export const initState: IShared = {
  countries: [],
  vendors: [],
  api_ico: null,
  is_valid_pickup_date: false,
  first_pickup_date: "",
  token_for_verify: "",
  token_verified: "",
  e_mail: "",
  is_authorized: false,
} as IShared;

export const IntialReducer = () => initState;

export const setCountries = (
  state: IShared,
  action: { payload: Countries[] }
) => {
  state.countries = action.payload;
};

export const ExtraReducer = (builder: ActionReducerMapBuilder<IShared>) => {
  builder.addCase(fetchShared.pending, (state: IShared, action) => {});

  builder.addCase(ISignalR.fulfilled, (state: IShared, action) => {
    if (action.meta.arg.type === ISignalRType.SharedGetCountries) {
      state.countries = action.payload;
    }
    if (action.meta.arg.type === ISignalRType.SharedGetVendors) {
      state.vendors = action.payload;
    }
    if (action.meta.arg.type === ISignalRType.SharedGetFirstPickupDate) {
      state.first_pickup_date = action.payload.toString();
    }
    if (action.meta.arg.type === ISignalRType.SharedServiceRequestValidateEmail) {
      state.token_for_verify = action.payload.verification_code;
    }

    if (action.meta.arg.type === ISignalRType.SharedIsAuthorized) {
      state.e_mail = action.payload.e_mail;
      state.is_authorized = action.payload.is_authorize;
    }

    if (action.meta.arg.type === ISignalRType.SharedServiceRequestValidateVerification) {
      state.token_verified = action.payload.verification_code;
      localStorage.setItem("token_verified", action.payload.verification_code);
    }
  });

  builder.addCase(fetchShared.fulfilled, (state: IShared, action: any) => {
    if (action.meta.arg.type === FetchTypes.GET_CZ_ARES2) {
      state.api_ico = action.payload;
    }
    if (action.meta.arg.type === FetchTypes.GET_SK_RUZ) {
      state.api_ico = action.payload;
    }
    if (action.meta.arg.type === FetchTypes.GET_IS_PICKUP_DATE) {
      state.is_valid_pickup_date = action.payload;
    }
    if (action.meta.arg.type === FetchTypes.GET_FIRST_PICKUP_DATE) {
      state.first_pickup_date = action.payload;
    }
  });
  builder.addCase(fetchShared.rejected, (state: IShared, action) => {});
};
