import { Core } from "./core.types";
import { signalRTypes } from "./core.enums";
import { reducers } from "./core.slice";
import { CoreSignalR } from "./core.signalr";

export interface ICore extends Core {}

export const ICoreReducers = reducers;
export const ISignalR = CoreSignalR;
export const ISignalRTypes = signalRTypes;

