import { useRedux } from "../../../redux/shared/redux.provider";

export default function SoDetailCustomer() {
  const { Rts } = useRedux();
  return (
    <Rts.Flex w={"100%"} direction={"column"}>
      <Rts.Card.Root w={"100%"}>
        <Rts.Card.Body>
          <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
            Zákazník
          </Rts.Text>
          <Rts.Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Rts.Avatar size={"sm"} />
            <Rts.Text fontSize={"sm"}>Jméno Příjmení</Rts.Text>
            <Rts.Text fontSize={"sm"}></Rts.Text>
            <Rts.Text fontSize={"sm"}>Email</Rts.Text>
            <Rts.Text fontSize={"sm"}>Mobil</Rts.Text>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <Rts.Card.Root mt={2} w={"100%"}>
        <Rts.Card.Body>
          <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
            Fakturace
          </Rts.Text>
          <Rts.Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Rts.Avatar size={"sm"} icon={<Rts.Icons.FaFileInvoiceDollar />} />
            <Rts.Text fontSize={"sm"}>Jméno Příjmení</Rts.Text>
            <Rts.Text fontSize={"sm"}></Rts.Text>
            <Rts.Text fontSize={"sm"}>Email</Rts.Text>
            <Rts.Text fontSize={"sm"}>Mobil</Rts.Text>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
      <Rts.Card.Root mt={2} w={"100%"}>
        <Rts.Card.Body>
          <Rts.Text fontSize={"sm"} fontWeight={"bold"}>
            Doručení
          </Rts.Text>
          <Rts.Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Rts.Avatar size={"sm"} icon={<Rts.Icons.FaTruck />} />
            <Rts.Text fontSize={"sm"}>Jméno Příjmení</Rts.Text>
            <Rts.Text fontSize={"sm"}></Rts.Text>
            <Rts.Text fontSize={"sm"}>Email</Rts.Text>
            <Rts.Text fontSize={"sm"}>Mobil</Rts.Text>
          </Rts.Flex>
        </Rts.Card.Body>
      </Rts.Card.Root>
    </Rts.Flex>
  );
}
