import { EndpointMethod } from "../../redux.enums"
import { endpoint } from "../../redux.types"

export const USER = {
    LOGIN_BY_IFRAME: {
        method: EndpointMethod.POST,
        ep: `/api/Login`
    } as endpoint,
    LOGIN_BY_CODE: (id: string) => {
        return {
            method: EndpointMethod.GET,
            ep: `/api/Login/oauth2/${id}`
        } as endpoint
    },
    USER_DETAIL: () => {
        return {
            method: EndpointMethod.GET,
            ep: `/api/Login/user`
        } as endpoint
    },
}