import { ButtonProps } from "@chakra-ui/react";
import { useRedux } from "../redux.provider";

export interface IButtonProps extends ButtonProps {
  icon?: JSX.Element;
}

export default function SButton(props: IButtonProps) {
  const { redux, Rts } = useRedux();
  const label = props["aria-label"];
  const icon = props.icon;
  return (
    <Rts.Tooltip
      content={label}
    >
      <Rts.Button loading={redux.isLoading} borderRadius={"15px"} gap={2} size={"sm"} {...props}>
        {icon}
        {props.children}
      </Rts.Button>
    </Rts.Tooltip>
  );
}
