import * as signalR from "@microsoft/signalr";

const __host = window.location.host;
let __access_token = localStorage.getItem("token_verified") ?? "";
let __reconnect = false;
let __hub = "/rtsconnect";
if (__host === "localhost:3000") __hub = "https://localhost:7082/rtsconnect";

const connection = new signalR.HubConnectionBuilder()
    .withUrl(__hub, {
        withCredentials: true,
        accessTokenFactory: () => __access_token,
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

let connectStateCallback: (state: signalR.HubConnection) => void;
let changeConnectionCallback: (state: boolean, stateMsg: string) => void;

export const onConnectState = (
    callback: (state: signalR.HubConnection) => void
) => {
    connectStateCallback = callback;
};

export const onChangeConnection = (
    callback: (state: boolean, stateMsg: string) => void
) => {
    changeConnectionCallback = callback;
};

const startConnection = () => {
    connection
    .start()
    .then(() => {
        __reconnect = false;
        if (connectStateCallback) connectStateCallback(connection);
        if (changeConnectionCallback) changeConnectionCallback(true, "Připojení k aplikačnímu serveru bylo navázáno.");
    })
    .catch((err: any) => {
        __reconnect = false;
        if (connectStateCallback) connectStateCallback(connection);
        if (changeConnectionCallback) changeConnectionCallback(false, "Nepodařilo se navázat připojení k aplikačnímu serveru.");
        setTimeout(startConnection, 5000);
    });
};

startConnection();

connection.onclose(() => {
    if (changeConnectionCallback && !__reconnect) changeConnectionCallback(false, "Připojení k aplikačnímu serveru bylo ukončeno.");
    if (changeConnectionCallback && __reconnect) changeConnectionCallback(false, "Probíhá přihlašování uživatele k aplikačnímu serveru.");
    setTimeout(startConnection, 5000);
});
connection.onreconnected(() => {
    if (changeConnectionCallback) changeConnectionCallback(true, "Připojení k aplikačnímu serveru bylo obnoveno.");
});
connection.onreconnecting(() => {
    if (changeConnectionCallback) changeConnectionCallback(false, "Probíhá pokus o obnovení připojení k aplikačnímu serveru.");
});

connection.on("SharedServiceRequestValidateVerification", (message: any) => {
    __access_token = message.verification_code;
    localStorage.setItem("token_verified", __access_token);
    if (connection.state === signalR.HubConnectionState.Connected) {
        __reconnect = true;
        connection.stop();
    } else {
        startConnection();
    }
});

export const sendMessage = (methodName: string, message: string) => {
    connection.send(methodName, message).catch((err: any) => console.error(err));
};

export const sendMessageWithTimeout = (
    methodName: string,
    message: any,
    timeout: number = 30000
): Promise<any> => {
    return new Promise((resolve, reject) => {
      
        const timer = setTimeout(() => {
            connection.off(methodName);
            reject(new Error("Timeout"));
        }, timeout);

        connection.on(methodName, (response: any) => {
            clearTimeout(timer);
            connection.off(methodName);
            resolve(response);
        });

        connection.send(methodName, message).catch((err) => {
            clearTimeout(timer);
            connection.off(methodName);
            reject(err);
        });
    });
};

export const onMessageReceived = (
    methodName: string,
    callback: (message: any) => void
) => {
    connection.on(methodName, callback);
};

export default connection;
