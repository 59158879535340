import { defineSlotRecipe } from "@chakra-ui/react";
import { selectAnatomy } from "@chakra-ui/react/anatomy";

export const selectSlotRecipe = defineSlotRecipe({
  className: "chakra-select",
  slots: selectAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    },
    control: {
      borderRadius: "15px",
    },
    content: {
      p: 2,
      borderRadius: "15px",
    },
    trigger: {
      borderRadius: "15px",
    },
    item: {
      borderRadius: "15px",
    }
  },
});
