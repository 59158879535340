import { useDisclosure, chakra, Drawer } from "@chakra-ui/react";
import { useRedux } from "../../redux/shared/redux.provider";

export default function LpCreate() {
  const { redux, Rts } = useRedux();
  const { open, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Rts.Button aria-label="Objednat přepravu" onClick={onOpen} size={"sm"} variant={"subtle"}>
        <Rts.Icons.LuTruck />
      </Rts.Button>
      <Rts.DrawerRoot open={open} size={"md"}>
        <Rts.DrawerBackdrop />
        <Rts.DrawerContent>
          <Rts.DrawerHeader fontSize={"md"}>
            Objednávka přepravy
          </Rts.DrawerHeader>
          <Rts.DrawerBody>
            <Rts.Flex gap={2} direction={"column"} w={"100%"}>
              <Rts.Tabs.Root position="relative" w={"100%"}>
                <Rts.Tabs.List
                  fontSize={"sm"}
                  w="100%"
                  justifyContent={"space-around"}
                >
                  <Rts.Tabs.Trigger value="customer" fontSize={"sm"}>
                    <Rts.Tooltip content="Zákazník">
                      <Rts.Icons.FaUser />
                    </Rts.Tooltip>
                  </Rts.Tabs.Trigger>
                  <Rts.Tabs.Trigger value="entries">
                    <Rts.Tooltip content="Řádky dodacího listu">
                      <Rts.Icons.FaListUl />
                    </Rts.Tooltip>
                  </Rts.Tabs.Trigger>
                </Rts.Tabs.List>

                <Rts.Tabs.Content value="customer">
                  <Rts.Fieldset.Root>
                    <Rts.Fieldset.Content>
                      <Rts.Field label="Země">
                        <Rts.SelectRoot
                          collection={Rts.createListCollection({
                            items: [{ value: "CZ", label: "Česká Republika" }],
                          })}
                        >
                          <Rts.SelectLabel>Select framework</Rts.SelectLabel>
                        </Rts.SelectRoot>
                      </Rts.Field>
                      <Rts.Field label="Kontaktní osoba">
                        <Rts.Input />
                      </Rts.Field>
                      <Rts.Field label="Ulice a ČP">
                        <Rts.Input />
                      </Rts.Field>
                      <Rts.Field label="Město">
                        <Rts.Input />
                      </Rts.Field>
                      <Rts.Field label="PSČ">
                        <Rts.InputMask mask={"999 99"} w="100" />
                      </Rts.Field>
                      <Rts.Field label="Telefon">
                        <Rts.InputMask mask={"+499 999 999 999"} w="100" />
                      </Rts.Field>
                      <Rts.Field label="Email">
                        <Rts.Input />
                      </Rts.Field>
                      <Rts.Field label="Reference">
                        <Rts.Input />
                      </Rts.Field>
                      <Rts.Field label="">
                        <Rts.RadioCardRoot>
                          <Rts.RadioCardLabel>Typ přepravy</Rts.RadioCardLabel>
                          <Rts.RadioCardItem
                            label={"Svozová přeprava"}
                            icon={<Rts.Icons.AiOutlineUpload />}
                            indicator={false}
                            key={"pickup"}
                            value={"1"}
                          />
                          <Rts.RadioCardItem
                            label={"Rozvozová přeprava"}
                            icon={<Rts.Icons.AiOutlineUpload />}
                            indicator={false}
                            key={"pickup"}
                            value={"2"}
                          />
                        </Rts.RadioCardRoot>
                        <Rts.Flex direction={"row"} mt={4} gap={2}>
                          <Rts.Checkbox />
                          <Rts.Text fontSize={"xs"}>Dobírka</Rts.Text>
                        </Rts.Flex>
                        <Rts.Flex direction={"row"} mt={4} gap={2}>
                          <Rts.InputGroup endElement={<Rts.Icons.FaSearch />}>
                            <Rts.Input placeholder="Cena dobírky" />
                          </Rts.InputGroup>
                        </Rts.Flex>
                      </Rts.Field>
                    </Rts.Fieldset.Content>
                  </Rts.Fieldset.Root>
                </Rts.Tabs.Content>
                <Rts.Tabs.Content value="entries">
                  <Rts.Flex
                    direction={"column"}
                    gap={2}
                    align={"center"}
                    w="100%"
                  >
                    <Rts.Card.Root w={"100%"} bg={""}>
                      <Rts.Card.Body justifyContent={"center"}>
                        <Rts.Flex gap={4}>
                          <Rts.Icons.FaPlus />
                          <Rts.Text fontSize={"sm"}>
                            Přidat další řádek
                          </Rts.Text>
                        </Rts.Flex>
                      </Rts.Card.Body>
                    </Rts.Card.Root>
                    <Rts.Card.Root w={"100%"}>
                      <Rts.Card.Body>
                        <Rts.Text fontSize={"xs"}>Zakázka XXXXX</Rts.Text>
                      </Rts.Card.Body>
                    </Rts.Card.Root>
                    <Rts.Card.Root w={"100%"}>
                      <Rts.Card.Body>
                        <Rts.Text fontSize={"xs"}>Zakázka XXXXX</Rts.Text>
                      </Rts.Card.Body>
                    </Rts.Card.Root>
                  </Rts.Flex>
                </Rts.Tabs.Content>
              </Rts.Tabs.Root>
            </Rts.Flex>
          </Rts.DrawerBody>
          <Rts.DrawerFooter>
            <Rts.Flex direction={"row"} gap={4} justify={"space-between"}>
              <Rts.Button variant={"ghost"} onClick={onClose}>
                Zrušit
              </Rts.Button>
              <Rts.Button variant={"outline"} onClick={onClose}>
                Objednat
              </Rts.Button>
            </Rts.Flex>
          </Rts.DrawerFooter>
        </Rts.DrawerContent>
      </Rts.DrawerRoot>
    </>
  );
}
