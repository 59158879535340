import { createAsyncThunk } from "@reduxjs/toolkit";
import { signalRTypes } from "./core.enums";
import { sendMessageWithTimeout } from "../../shared/redux.signalR";

type inputData = {
  type: signalRTypes;
  data?: any;
};

export const CoreSignalR = createAsyncThunk<
  string[],
  inputData,
  { rejectValue: string }
>("core/fetchData", async (_, thunkAPI) => {
  try {
    return await sendMessageWithTimeout(
      _.type.toString(),
      JSON.stringify(_.data)
    );
  } catch (error) {
    return thunkAPI.rejectWithValue("Nepodařilo");
  }
});
